import { Box, Button, Grid, Paper, Typography, styled } from "@mui/material";
import { DEVICE_SIDE } from "./audiometry.constant";

import BTEDevice from "../../../assets/images/bte.png";
import { Line } from "react-chartjs-2";
import { useMemo, useState } from "react";
import { CenteredBox } from "../../../components/layouts/OneViewBox";
import { GraphicEq, PlayArrow } from "@mui/icons-material";

const BorderSquareChip = styled(Typography)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: "1px solid " + theme.palette.success.main,
  color: theme.palette.success.main,
  padding: theme.spacing(2),
  background: theme.palette.success.light,
}));
const InputButton = styled(Button)(({ theme, disabled }) => ({
  background: disabled
    ? theme.palette.grey.main
    : theme.palette.secondary.light,
  border:
    "1px solid " +
    (disabled ? theme.palette.grey.main : theme.palette.primary.main),
  height: "40px",
}));

const InputButtons = ({ value, onValueChange }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "stretch" }}>
      <InputButton
        disabled={value <= 0}
        onClick={() => onValueChange(value - 5)}
      >
        <Typography>-</Typography>
      </InputButton>
      <CenteredBox
        component={Paper}
        elevation={0}
        ml={1}
        mr={1}
        sx={{
          border: 1,
          display: "flex",
          borderColor: "primary",
          height: "40px",
        }}
        flex={3}
      >
        <Typography variant="h4">{value} dB</Typography>
      </CenteredBox>
      <InputButton
        disabled={value >= 100}
        onClick={() => onValueChange(value + 5)}
      >
        <Typography>+</Typography>
      </InputButton>
    </Box>
  );
};

const InsuiteAudioMetryMainLogic = ({
  connection,
  data,
  side,
  writeFun,
  updateData,
}) => {
  const [playLoading, setPlayLoading] = useState(false);
  const [fields, setFields] = useState({
    freqIndex: 0,
    value: 100,
  });
  const leastMaxReadIndex =
    side?.current == DEVICE_SIDE.LEFT
      ? data?.lastLeftIndex
      : data?.lastRightIndex;
  const dataList = side?.current == DEVICE_SIDE.LEFT ? data?.left : data?.right;

  const onUpdateData = () => {
    setFields({
      ...fields,
      freqIndex: (fields.freqIndex + 1) % 7,
      value: dataList?.[fields.freqIndex + 1] ?? 100,
    });
    updateData(side?.current, fields.freqIndex, fields.value);
  };
  const playSound = () => {
    const freq = data?.freq[fields.freqIndex];
    const gain = Math.abs(fields.value - 100);

    const arr_send = new Uint8Array(7);
    arr_send[0] = 0xaa;
    arr_send[1] = 0xab;
    arr_send[2] = 3;
    arr_send[3] = 0;
    arr_send[4] = freq / 256;
    arr_send[5] = freq % 256;
    arr_send[6] = gain;

    writeFun?.writeData?.(arr_send);
  };
  const stopPlayingSound = () => {
    const arr_send = new Uint8Array(7);
    arr_send[0] = 0xaa;
    arr_send[1] = 0xab;
    arr_send[2] = 3;
    arr_send[3] = 0;
    arr_send[4] = 0;
    arr_send[5] = 0;
    arr_send[6] = 0;

    writeFun?.writeData?.(arr_send);
  };
  const onStimilus = () => {
    setPlayLoading(true);
    playSound();
    setTimeout(() => {
      stopPlayingSound(false);
      setPlayLoading(false);
    }, 2000);
  };

  const changeFreq = (index) => {
    setFields({ ...fields, freqIndex: index, value: dataList?.[index] ?? 100 });
  };

  return (
    <CenteredBox p={3} sx={{ height: "100%", width: "100%" }}>
      <Box
        p={2}
        component={Paper}
        sx={{ height: "100%", maxHeight: "300px", width: "100%" }}
        display="flex"
        variant="outlined"
      >
        <Box
          sx={{
            height: "100%",
            width: "200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          {data?.freq?.map((item, index) => (
            <Button
              disableElevation
              variant={fields.freqIndex == index ? "contained" : "outlined"}
              key={item}
              onClick={() => {
                changeFreq(index);
              }}
              disabled={leastMaxReadIndex < index - 1}
            >
              {data?.freqNames?.[index]}
            </Button>
          ))}
        </Box>
        <CenteredBox sx={{ height: "100%", display: "flex", flex: 1 }}>
          <Box
            sx={{
              width: "100%",
              maxWidth: "250px",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Box>
              <Box>
                <Paper
                  component={CenteredBox}
                  p={2}
                  variant="outlined"
                  fullWidth
                >
                  <Typography variant="h3" color="dark.main">
                    {data?.freqNames?.[fields.freqIndex]}Hz
                  </Typography>
                </Paper>
              </Box>
              <Box mt={3}>
                <InputButtons
                  value={fields.value}
                  onValueChange={(newVal) =>
                    setFields({ ...fields, value: newVal })
                  }
                />
              </Box>
              <Box mt={2}>
                <Button
                  variant={"outlined"}
                  fullWidth
                  startIcon={!playLoading ? <PlayArrow /> : undefined}
                  onClick={onStimilus}
                  disabled={playLoading}
                >
                  {playLoading ? <GraphicEq /> : "Stimulus"}
                </Button>
              </Box>
            </Box>

            <Button
              variant={"contained"}
              onClick={onUpdateData}
              disabled={playLoading}
            >
              Mark Value
            </Button>
          </Box>
        </CenteredBox>
      </Box>
    </CenteredBox>
  );
};

export const AudioMetryChartComponent = ({ connection, data }) => {
  const labels = useMemo(() => {
    return data?.freqNames;
  }, [data?.freqNames]);

  console.log(data);
  const dataFields = useMemo(
    () => ({
      labels,
      datasets: [
        {
          label: "Left",
          data: (data.left ?? []).map((item) => item),

          borderColor: "blue",
        },
        {
          label: "Right",
          data: (data.right ?? []).map((item) => item),

          borderColor: "Green",
        },
      ],
    }),
    [data]
  );

  return (
    <Line
      width="100%"
      height="100%"
      // key={Math.random()}
      options={{
        interaction: {
          mode: "index",
        },
        plugins: {
          datalabels: {
            display: false,
            backgroundColor: "red",
          },

          legend: {
            enabled: false,
            color: "red",
            position: "bottom",
            labels: {
              // color:"red"
            },
          },
        },

        responsive: true,
        animation: false,

        bezierCurve: true,
        elements: {},
        spanGaps: 1,
        aspectRatio: 1.5,
        scales: {
          y: {
            title: {
              display: true,
              text: "Gain",
              fontWeight: "600",
              color: "#3C508B",
            },
            max: 120,
            min: 0,
            stack: 10,
            suggestedMax: 0,
            suggestedMin: 120,
            ticks: {
              callback: function (value, index, ticks) {
                return value;
              },
              stepSize: 10,
            },
          },
          x: {
            title: {
              display: true,
              text: "Frequency",
              fontWeight: "6001",
              color: "#3C508B",
            },
          },
        },

        onClick: (e, ele, chart) => {},
        onHover: (e, ele, chart) => {},
      }}
      data={dataFields}
    />
  );
};

const AudiometryPlayArea = ({
  connection,
  data,
  updateData,
  writeFun,
  side,
}) => {
  return (
    <Box sx={{ height: "100%", overflow: "hidden" }}>
      <Grid container sx={{ height: "100%", overflow: "hidden" }}>
        <Grid item xs={6} sx={{ height: "100%", overflow: "hidden" }}>
          <Box sx={{ height: "100%" }}>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }} p={2}>
                <img
                  src={BTEDevice}
                  style={{ height: "24px", width: "24px" }}
                />
                <Typography variant="h4" fontWeight={500} color="grey" pl={2}>
                  {connection?.deviceInfo?.name} -
                </Typography>
                &nbsp;
                <BorderSquareChip variant="subtitle1">
                  {side.current === DEVICE_SIDE.LEFT ? "Left" : "Right"} Hearing
                  Aid
                </BorderSquareChip>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  overflow: "hidden",
                }}
                p={2}
              >
                <AudioMetryChartComponent
                  connection={connection}
                  data={data}
                ></AudioMetryChartComponent>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ height: "100%", overflow: "hidden" }}>
          <InsuiteAudioMetryMainLogic
            connection={connection}
            updateData={updateData}
            data={data}
            writeFun={writeFun}
            side={side}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default AudiometryPlayArea;
