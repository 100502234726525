import { useState } from "react"
import UserSearchBar, { PatientSearchBar } from "../../components/inputs/SearchBar"
import { Box, Paper, Typography } from "@mui/material"
import ListController from "./ListController"
import NoDataComponent from "../../components/layouts/NoDataComponent"
import { People } from "@mui/icons-material"
import { CenteredBox } from "../../components/layouts/common/boxes"

const PatientProfileMain = () => {
    const [patient, setPatient] = useState(null)
    return <>
    <CenteredBox>
        <Paper component={Box} p={3} sx={{ width: "100%", maxWidth: "1100px" }} >
            
            <PatientSearchBar onChange={(val) => setPatient(val)} />
            <Box mb={2} mt={2} />

            {patient && <ListController patient_id={patient?._id} />}
            {!patient && <NoDataComponent message={"No Patient Selected"} Icon={People} />}

        </Paper>
        </CenteredBox>
    </>
}
export default PatientProfileMain