import React, { useState } from "react";
import Audiometrycreateuileft from "./Audiometrycreateuileft";
import Audiometrycreateuiright from "./Audiometrycreateuiright";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import ModuleContainer from "../../components/layouts/common/ModuleContainer";
import SubmitButton from "../../components/button/SubmitButton";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import { addAudiometryApi, getAudiometryApi } from "../../apis/audiometry.api";
import { SNACK_BAR_VARIETNS, USER_ROLES } from "../../utils/constants";
import { callApiAction } from "../../store/actions/commonAction";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { useNavigate, useParams } from "react-router-dom";
import { LISTENING_SIDE } from "../../utils/patient.constants";
import { closeModal } from "../../store/actions/modalAction";
import {
  applyFeedbackCancellation,
  applyWhisleBlock,
  applyWindBox,
  changeByteValue,
  changeCompression,
  changeDeviceArray,
  changeMasterGain,
  changeMultipleByteValue,
  changeNoiseReduction,
  changeProfileId,
  changeRemarks,
  changeSharpness,
  changeSpeechRedcution,
  changeWindNoiseReduction,
  disconnectDevice,
  readDataFromDevice,
  resetDevice,
} from "../../store/actions/fittingAction";

const Audiometrycreateui = () => {
  const { fitting } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [filters, setFilters] = useState();
  const [saveLoadin, setSaveLoading] = useState(false);
  const [completeLoading, setCompleteLoading] = useState(false);
  const onCreateBtnClick = () => {};
  const HeaderComponent = () => {};

  console.log("FITTING DATA", fitting);
  const onClose = (e) => {
    e.preventDefault()
    dispatch(closeModal('audiometry'))
}

// const onCompeteClick = () => {
//   submit(true, () => {
//     fitting.disconnectFun?.();
//     dispatch(disconnectDevice());
//   });
// };
  const submit = async () => {
    // if (complete === true) setCompleteLoading(true);
    // else {
    //   setSaveLoading(true);
    // }

    // if(device_side ==)
   

    const finalFittingData = fitting.data;
    // if(fitting.device_side == LISTENING_SIDE.LEFT || fitting.device_side == LISTENING_SIDE.RIGHT){
    //   navigate(
    //     "/patient-details/new-Patient/" + params.id + "/" + "insitu-new-fitting/"
    //   );

    // }
    // else{
    //   navigate(
    //     "/patient-details/new-Patient/" + params.id + "/" + "insitu-new-fitting/"
    //   );
    // }
    // await fitting?.writeFun?.writeData?.(finalFittingData);

    console.log("finalfitting", finalFittingData);
    console.log("insitu",fitting.inSitudB)
    const passData = {
      patient_id: params.id,
      device_id: fitting.deviceInfo?.id,
      device_name: fitting.deviceInfo?.name,
      device_type: fitting.device_type,
      device_side: fitting.device_side,
      inSitudB: fitting.inSitudB,
    };

    // console.log("popopo", passData);
    if (fitting.inSitudB) {
      dispatch(
        callApiAction(
          async () => await addAudiometryApi(passData),
          async (response) => {
            console.log("RESPONE SKOE",response)
            dispatch(changeProfileId(response._id));
            // callBack();
            dispatch(
              callSnackBar(
                "InSitu Audiometry Add",
                SNACK_BAR_VARIETNS.suceess
              )
            );
            // if (complete === true) setCompleteLoading(false);
            // else {
            //   setSaveLoading(false);
            // }
          },
          (err) => {
            // if (complete === true) setCompleteLoading(false);
            // else {
              // setSaveLoading(false);
            // }
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
          }
        )
      );
    }
    // let step = parseInt(params.step) + 1;
    // if (step == 4) {
    //   navigate("/patient-details/");
    // } else {

    // }
  };

  return (
    <>
      <CustomDialog
        id={"audiometry"}
        onSubmit={onClose}
        dialogProps={{ sx: { height: "1000px", overflow: "hidden" } }}
        title={"Audiometry"}
        closeText={"Close"}
        hideSubmit
        confirmText={`Save`}
      >
       
          <Grid container>
            <Grid xs={5}>
              <Audiometrycreateuileft fitting={fitting} />
            </Grid>
            <Grid item xs={1}>
              <Divider orientation="vertical" flexItem />
            </Grid>
            <Grid lg={5} xs={5}>
              <Audiometrycreateuiright />
            </Grid>
          </Grid>
          <Divider />

          <Box sx={{ display: "flex", justifyContent: "flex-end" }} p={3}>
            <Box mx={2} >
              <SubmitButton
                type="submit"
                sx={{ minWidth: "200px" }}
                title={"Submit"}
                onClick={submit}
              />
            </Box>
          </Box>
        
        </CustomDialog>
  
    </>
  );
};

export default Audiometrycreateui;
