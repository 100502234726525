import { SvgIcon } from "@mui/material"

const HearingAidIcon = ()=>{
    return <SvgIcon xmlns="http://www.w3.org/2000/svg" fontSize='inherit' viewBox="0 0 32 32" >
    <path fill="white" d="M5.83252 10.5301C6.48731 7.99277 10.0395 3 15.0814 3C20.1233 3 22.7752 5.34633 24.1666 7.66537C25.558 9.98442 26.7735 13.5666 25.558 17.078C24.8214 19.206 24.3303 20.1882 24.3303 20.1882" stroke="#3C508B" strokeWidth="1.5"/>
    <path fill="white"  d="M12.3767 10.1206C11.6128 10.257 10.1276 10.9396 10.085 12.5761C10.0337 14.5404 12.9547 17.487 13.6913 19.3695C14.4279 21.252 14.0187 23.4619 13.2771 24.8534C11.9246 27.3907 9.19416 27.0988 8.28929 26.7359C6.65685 26.0811 4.84664 23.0527 5.01034 17.8144C5.17404 12.5761 5.7462 10.8572 5.7462 10.8572" stroke="#3C508B" strokeWidth="2" strokeLinecap="round"/>
    <circle fill="white"  cx="22.6951" cy="23.9536" r="4.41092" stroke="#3C508B"/>
    <circle fill="white"  cx="22.6962" cy="23.9535" r="1.95546" stroke="#3C508B"/>
    </SvgIcon>
}
export default HearingAidIcon