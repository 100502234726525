import { OpenInNew, Search } from "@mui/icons-material"
import { Autocomplete, Button, ButtonGroup, CircularProgress, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"



import SubmitButton from "../../components/button/SubmitButton"
import { StyledSearchBar, UserSearchBarNormal } from "../../components/inputs/SearchBar"


import { USER_ROLES } from "../../utils/constants"
import DataTable from "../../components/tables/DataTable"
import { titleCase, toTitleCase } from "../../utils/helper"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"
import ModuleContainer from "../../components/layouts/common/ModuleContainer"
import AsynSearchBar from "../../components/inputs/AsynSearchBar"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, setFilters, list, roles, loading, onCreateBtnClick, columns }) => {

    const user = useSelector(state => state.user)

    return (
        <>
            <ModuleContainer


                HeaderComponent={memo(() => <Box sx={{ display: "flex", minWidth: "50%" }}>
                <FilterTitleBox>
                    <Box sx={{ display: "flex", flex: 1 }} mr={1}>
                        <AsynSearchBar

                            fullWidth
                            title="Search  User  "
                            size="small"
                            InputProps={{
                                startAdornment: <Search sx={{ mr: 2 }} />
                            }}
                            placeholder={"Search Name "}
                            defaultValue={filters.search}
                            onChange={(changedVal) => {

                                setFilters({ ...filters, search: changedVal })
                            }}
                        />
                    </Box>
                    
                    <Button variant="contained" onClick={onCreateBtnClick} >Add User</Button>
                </FilterTitleBox>
            </Box>)}
            >
                <Box mb={3} sx={{ display: "flex", flex: 1, overflow: "hidden", flexDirection: "column" }}>
                    <Box  mt={2} mb={2}  sx={{ display: "flex",justifyContent:"flex-end"}}>
                        <Box sx={{width:"300px",maxWidth:"100%"}}>
                        <Autocomplete
                            fullWidth
                            disableClearable
                            id="combo-box-demo"
                            onChange={(e, newVal) => {
                                setFilters({ ...filters, role: newVal ? newVal._id : null })
                            }}
                            options={[{ label: 'All', _id: null }, ...Object.keys(USER_ROLES).map((key) => ({ label: titleCase(key), _id: USER_ROLES[key] }))]}
                            sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                            renderInput={(params) => <StyledSearchBar placeholder="Select Role" {...params} size="small" />}
                        />
                        </Box>
                    </Box>
                    <Paper elevation={2} sx={{ width: "100%", padding: 0, display: "flex", flex: 1, overflow: "hidden", flexDirection: "column" }} >


                        <Box sx={{ minHeight: "300px", display: "flex", flex: 1, overflow: "hidden", flexDirection: "column" }}>
                            <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                        </Box>
                    </Paper>
                </Box>
            </ModuleContainer>
        </>
    )
}
export default ListUi