
import { Add, Remove } from "@mui/icons-material"
import { Box, Slider, SliderThumb, Typography, styled, useTheme } from "@mui/material"
import { memo, useEffect, useState } from "react"

// const { Box, ButtonBase, styled, Slider } = require("@mui/material")

// const CustomizeButton = styled(ButtonBase)(({ theme, disabled }) => ({
//     height: "36px",
//     width: "36px",
//     display: "flex",
//     alignItems: 'center',
//     justifyContent: "center",
//     background: disabled ? theme.palette.grey[200] : theme.palette.secondary.light,
//     border: disabled ? "1px solid transparent" : "1px solid " + theme.palette.primary.main,
//     cursor: disabled ? "not-allowed !important" : "pointer",
//     borderRadius: theme.shape.borderRadius,
//     boxShadow:theme.shadows[1]
// }))

const CustomSlider = styled(Slider)(({ theme }) => ({
    // color: '#3a8589',
    height: 3,
    padding: '13px 0',
    '& .MuiSlider-thumb': {
        height: 18,
        width: 18,
        background: theme.palette.light.main,
        border: ("3px solid " + theme.palette.secondary.main),
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
        },

    },
    '& .MuiSlider-track': {
        height: 4,
    },
    '& .MuiSlider-rail': {
        color: theme.palette.mode === 'dark' ? '#F5F4F4' : '#F5F4F4',
        opacity: theme.palette.mode === 'dark' ? undefined : 1,
        height: 4
    },
}));
const CustomThumb = memo((props) => {


    const { children, style, attualVal, baseVal, ...other } = props;

    const activeDataIndex = attualVal < baseVal ? 1 : 0
    return <SliderThumb {...other} style={{
        ...style, display: other['data-index'] == activeDataIndex ? "none" : "block", ":before": {
            display: "none"
        }
    }} >
        {children}
    </SliderThumb>
})


const BothSideSlider = ({ value, baseVal = 0, onChange, min = 0, max = 10, step = 1, disabled }) => {

    const calculateVal = (arr) => {

        if (arr[0] < baseVal) {
            return arr[0]
        }
        if (arr[1] > baseVal) {
            return arr[1]
        }
        return baseVal

    }

    const [val, setVal] = useState(value < baseVal ? [value, baseVal] : [baseVal, value])


    useEffect(() => {
        setVal(value < baseVal ? [value, baseVal] : [baseVal, value])
    }, [value])


    return <Box sx={{ width: "100%" }}>

        <Box pl={1} pr={1}>
            <CustomSlider
                disabled={disabled}
                slots={{
                    thumb: (props) => {
                        return <CustomThumb {...props} valueArr={val} baseVal={baseVal} attualVal={calculateVal(val)} />
                    }
                }}
                max={max}
                onChange={(e, newVal) => {
                    const temp = [...newVal]

                    if (newVal[0] > baseVal) {
                        temp[0] = baseVal
                        temp[1] = newVal[0]
                    } else if (newVal[0] < baseVal && temp[1] > baseVal) {
                        temp[1] = baseVal

                    }

                    if (newVal[1] < baseVal) {
                        temp[1] = baseVal
                        temp[0] = newVal[1]
                    } else if (newVal[1] > baseVal && temp[0] < baseVal) {
                        temp[0] = baseVal

                    }

                    onChange?.(calculateVal(temp))
                    setVal(temp)
                }}
                min={min}
                value={val}

            />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "-5px" }}>
            <Typography variant="h6" color="grey" >
                {min}
            </Typography>
            <Typography variant="h6" color="grey" >
                {baseVal}
            </Typography>
            <Typography variant="h6" color="grey" >
                {max}
            </Typography>
        </Box>
    </Box>


}
export default memo(BothSideSlider)