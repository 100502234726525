import { Box, ButtonBase, Paper, Typography, styled } from "@mui/material"
import { CenteredBox } from "../../components/layouts/common/boxes"
import { Link } from "react-router-dom"
import { HeadphonesOutlined, PsychologyAltOutlined } from "@mui/icons-material"

const Styledpaper = styled(Paper)(({ theme }) => ({
    height: "100%",
    width: "300px",
    maxWidth: "40%"
}))
const IconButtonComponenet = ({ title, Icon, ...props }) => {
    return <Styledpaper elevation={2} component={Link} {...props}>

        <Box p={4}>
            <Typography align="center"  variant="h1" >
                <Icon fontSize="inherit" color="primary" />
            </Typography>
            <Typography align="center" variant="h5">
                {title}
            </Typography>
        </Box>
    </Styledpaper>
}
const DashboardMainUi = ({ }) => {
    return <>
        <CenteredBox sx={{ display: "flex", flex: 1 }}>
            <CenteredBox sx={{ display: "flex", width: "100%" }}>
                <IconButtonComponenet title={"Patient Management"} to="/patient" Icon={PsychologyAltOutlined} />
                <IconButtonComponenet title={"Device Configure"} to="/configure-device" Icon={HeadphonesOutlined} sx={{ ml: 3 }} />
            </CenteredBox>
        </CenteredBox>
    </>
}
export default DashboardMainUi