const mode = "production";
let domain = "";

switch (mode) {
  case "local":
    domain = "http://localhost:8000/";
    break;
  case "ip":
    domain = "http://192.168.29.61:8000/";
    break;
  case "development":
    domain = "https://config-pro-development-development.up.railway.app/";
    break;
  case "production":
    domain = "https://j10-backend-production.up.railway.app/";
    break;

  default:
    domain = "/";
}

const endpoints = {
  root: domain,
  fileImage: `${domain}api/file/image`,
  fileFile: `${domain}api/file/file`,
  fileBase: `${domain}api/file`,
  notificationFetch: `${domain}api/notification`,
  signIn: `${domain}api/auth/login`,
  signUp: `${domain}api/auth/signup`,
  resetToken: `${domain}api/auth/reset-token`,
  resetPassword: `${domain}api/auth/reset-password`,

  userAndSystemFetchByToken: `${domain}api/user/fetch-by-token`,
  userBase: `${domain}api/user/`,

  userPassword: `${domain}api/user/reset-password`,

  secretsBase: `${domain}api/secrets/`,
  secretsById: `${domain}api/secrets/by-id`,

  userById: `${domain}api/user/fetch-by-id`,
  changeUserParrent: `${domain}api/user/change-parent`,

  patientBase: `${domain}api/patient`,
  patientReport: `${domain}api/patient/report`,
  patientAudiogram: `${domain}api/patient/audiogram`,
  patientById: `${domain}api/patient/fetch-by-id`,
  patientProfileBase: `${domain}api/patient-profile`,
  patientProfileById: `${domain}api/patient-profile/fetch-by-id`,

  patientAssign: `${domain}api/patient/audiologist`,


  region: `${domain}api/region`,
  audiometryBase: `${domain}api/audiometry/`,
};

export default endpoints;
