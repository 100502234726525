import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled,
  useTheme,
  Divider,
  IconButton,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import IncreasDecreaseButton from "../../components/button/IncreaseDecreaseButton";
import BothSideSlider from "../../components/button/BothSiderSlider";
import FittingChartArea from "./FittingChartArea";
import { openModal } from "../../store/actions/modalAction";
import AdvanceFittingController from "./AdvanceFittingController";
import {
  applyFeedbackCancellation,
  applyWinNoiseBlock,
  changeByteValue,
  changeCompression,
  changeDeviceArray,
  changeMasterGain,
  changeMultipleByteValue,
  changeNoiseReduction,
  changeProfileId,
  changeRemarks,
  changeSharpness,
  changeSpeechRedcution,
  changeWindNoiseReduction,
  disconnectDevice,
  readDataFromDevice,
  resetDevice,
} from "../../store/actions/fittingAction";
import { memo, useState } from "react";
import SubmitButton from "../../components/button/SubmitButton";
import { callApiAction } from "../../store/actions/commonAction";
import { callSnackBar } from "../../store/actions/snackbarAction";
import {
  addPatientProfileApi,
  updatePatientProfileField,
} from "../../apis/patientprofile.api";
import { useNavigate, useParams } from "react-router-dom";
import { SNACK_BAR_VARIETNS, USER_ROLES, actions } from "../../utils/constants";
import {
  changeOriginalValueFromMediun,
  findMediun,
  mapValues,
} from "../../utils/helper";
import { GAIN_POINTS } from "../../utils/j10.helper";
import { DEVICES, LISTENING_SIDE } from "../../utils/patient.constants";
import { closeModal } from "../../store/actions/modalAction";

const StyledButton = styled(ButtonBase)(({ theme, active }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flex: 1,
  color: active ? "unset" : theme.palette.light.main,
  border: "1px solid " + theme.palette.primary.main,

  borderLeft: 0,
  borderRight: 0,
  borderBottom: active ? 0 : 1,
  // borderTopLeftRadius: theme.shape.borderRadius *2,
  // borderTopRightRadius: theme.shape.borderRadius *2,
  background: active ? theme.palette.light.main : theme.palette.primary.main,
}));
const ActionInputButtons = memo(({ title, ...rest }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{ width: "100%", borderLeft: "1px solid" + theme.palette.grey[300] }}
      pl={3}
    >
      <Typography
        variant="body1"
        lineHeight="100%"
        color="dark.main"
        fontWeight={500}
        mb={2}
      >
        {title}
      </Typography>
      <IncreasDecreaseButton {...rest} />
    </Box>
  );
});
const ActionInputSliders = ({ title, ...rest }) => {
  const theme = useTheme();
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "-3px",
        }}
      >
        <Typography variant="h4" color="dark.main" fontWeight={500}>
          {title}
        </Typography>
        <Typography variant="h5" color="primary" fontWeight={600}>
          {rest.value}
        </Typography>
      </Box>
      <BothSideSlider {...rest} />
    </Box>
  );
};

const BasicFittingAreaController = () => {
  const params = useParams();
  const theme = useTheme();
  const { fitting, user } = useSelector((state) => state);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const onAdvanceFittingBtnClick = () => {
    dispatch(
      openModal(<AdvanceFittingController />, "xl", true, "advance-fitting")
    );
  };
  const onResetBtnClick = () => {
    dispatch(resetDevice());
    console.log("reset", fitting.data);
  };
  const onBack = () => {
    navigate(-1);
  };

  const [readLoading, setReadLoading] = useState(false);
  const [saveLoadin, setSaveLoading] = useState(false);
  const [completeLoading, setCompleteLoading] = useState(false);

  const onReadFromDeviceBtnClick = async () => {
    setReadLoading(true);
    const data = await fitting.writeFun.readData(fitting.data);
    setReadLoading(false);
    dispatch(readDataFromDevice(data));
  };
  const onSaveFitting = async (complete, callBack = () => {}, muted) => {
    if (complete === true) setCompleteLoading(true);
    else if (!muted) {
      setSaveLoading(true);
    }

    const finalFittingData = fitting.data;
    console.log("before finalFittingData", finalFittingData);
    if (fitting.device_type == DEVICES.BTE_32) {
      console.log("inside");
      finalFittingData[10] = 66;
      finalFittingData[11] = 84;
      finalFittingData[12] = 69;
      finalFittingData[13] = 51;
      finalFittingData[14] = 50;
      finalFittingData[15] = 95;
      if (fitting.device_side == LISTENING_SIDE.LEFT) {
        finalFittingData[16] = 76;
      } else {
        finalFittingData[16] = 82;
      }
      finalFittingData[17] = 0;
      finalFittingData[18] = 0;
      finalFittingData[19] = 0;
    }
    console.log("after finalFittingData", finalFittingData);
    finalFittingData[GAIN_POINTS.MASTER_GAIN] = mapValues(fitting.master_gain);
    finalFittingData[GAIN_POINTS.NOISE_REDUCTION] = mapValues(
      fitting.noise_reduction
    );
    finalFittingData[GAIN_POINTS.LOUDNESS_COMPRESSION] = mapValues(
      fitting.loudness_compression
    );
    finalFittingData[GAIN_POINTS.SELF_SPEECH_REDUCTION] = mapValues(
      fitting.self_speech_reduction
    );
    finalFittingData[GAIN_POINTS.SHARPNESS] = mapValues(fitting.sharpness);
    finalFittingData[GAIN_POINTS.WIND_NOISEREDUCTION] = mapValues(
      fitting.wind_noise_reduction
    );

    await fitting?.writeFun?.writeData?.(finalFittingData);

    const passData = {
      patient_id: params.id,
      device_id: fitting.deviceInfo?.id,
      device_name: fitting.deviceInfo?.name,
      device_type: fitting.device_type,
      device_side: fitting.device_side,
      master_gain: fitting.master_gain,
      noise_reduction: fitting.noise_reduction,
      loudness_compression: fitting.loudness_compression,
      self_speech_reduction: fitting.self_speech_reduction,
      sharpness: fitting.sharpness,
      wind_noise_reduction: fitting.wind_noise_reduction,
      fitting_data: fitting.data,
      remarks: fitting.remarks,
    };

    if (fitting.profile_id) {
      dispatch(
        callApiAction(
          async () =>
            await updatePatientProfileField({
              id: fitting.profile_id,
              ...passData,
            }),
          async (response) => {
            if (complete === true) {
              setCompleteLoading(false);
              dispatch(resetDevice());
            } else {
              setSaveLoading(false);
            }
            callBack();
            !muted &&
              dispatch(
                callSnackBar(
                  " Fitting Saved Successfully",
                  SNACK_BAR_VARIETNS.suceess
                )
              );
          },
          (err) => {
            if (complete === true) setCompleteLoading(false);
            else {
              setSaveLoading(false);
            }
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
          }
        )
      );
    } else
      dispatch(
        callApiAction(
          async () => await addPatientProfileApi(passData),
          async (response) => {
            dispatch(changeProfileId(response._id));
            callBack();
            !muted &&
              dispatch(
                callSnackBar(
                  " Fitting Saved Successfully",
                  SNACK_BAR_VARIETNS.suceess
                )
              );
            if (complete === true) setCompleteLoading(false);
            else {
              setSaveLoading(false);
            }
          },
          (err) => {
            if (complete === true) setCompleteLoading(false);
            else {
              setSaveLoading(false);
            }
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
          }
        )
      );
  };
  const onCompeteClick = () => {
    onSaveFitting(
      true,
      () => {
        fitting.disconnectFun?.();
        dispatch(disconnectDevice());
      },
      false
    );
  };
  const [tab, setTab] = useState(0);

  ///for setting last amount
  const [edgeVal, setEdgeVal] = useState(50);
  const [isMuted, setIsMuted] = useState(fitting.data[3] == 0);
  const mutedHandle = () => {
    dispatch({ type: "MUTED_ACTION" });
    onSaveFitting(false, () => {}, true);
    console.log("this is muted or unmuted data", fitting.data);
    setIsMuted(fitting.data[3] == 0);
  };
  const frequencyGridWidth = tab != 1 ? 3 : 6;
  return (
    <>
      <Box sx={{ flex: 1, overflow: "hidden" }}>
        <Grid container sx={{ height: "100%", overflow: "hidden" }}>
          <Grid item xs={5} sx={{ overflow: "hidden", height: "100%" }}>
            <FittingChartArea edgeVal={edgeVal} />
          </Grid>
          <Grid
            item
            xs={7}
            sx={{
              overflow: "hidden",
              height: "100%",
              borderLeftColor: "divider",
              borderLeft: 1,
            }}
          >
            <Box
              sx={{
                overflow: "hidden",
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  background: theme.palette.primary.main,
                }}
              >
                <StyledButton active={tab == 0} onClick={() => setTab(0)}>
                  <Typography lineHeight="100%">Gains</Typography>
                </StyledButton>
                <StyledButton active={tab == 1} onClick={() => setTab(1)}>
                  <Typography lineHeight="100%">Features</Typography>
                </StyledButton>
              </Box>
              <Box sx={{ overflow: "hidden", flex: 1 }}>
                <Grid container sx={{ height: "100%" }}>
                  {tab == 0 && (
                    <Grid
                      item
                      xs={tab != 1 ? 12 : 6}
                      sx={{ overflow: "hidden", height: "100%" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          width: "100%",
                          overflow: "hidden",
                        }}
                      >
                        <Box
                          sx={{
                            height: "100%",
                            width: "100%",
                            overflowY: "auto",
                          }}
                          p={4}
                        >
                          <Grid container spacing={1}>
                            <Grid item xs={11.1}>
                              {" "}
                              <Box
                                mb={4}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  border:
                                    "1px solid " + theme.palette.primary.main,
                                  borderRadius: theme.shape.borderRadius * 0.5,
                                }}
                                p={2}
                              >
                                <ActionInputSliders
                                  title="Master Gain"
                                  disabled={fitting.read_only}
                                  min={-5}
                                  max={5}
                                  value={fitting.master_gain}
                                  baseVal={0}
                                  onChange={(x) => {
                                    dispatch(changeMasterGain(x));
                                  }}
                                />
                              </Box>
                            </Grid>
                            {!fitting.remote && (
                              <Grid item xs={0.9}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    // border:
                                    //   "1px solid " + theme.palette.primary.main,
                                    // borderRadius: theme.shape.borderRadius * 0.5,
                                  }}
                                  pt={3}
                                  pb={2}
                                >
                                  <IconButton
                                    onClick={mutedHandle}
                                    variant="contained"
                                    aria-label="muted"
                                    size="large"
                                  >
                                    {isMuted ? (
                                      <VolumeOffIcon fontSize="inherit" />
                                    ) : (
                                      <VolumeUpIcon
                                        fontSize="inherit"
                                        size="large"
                                      />
                                    )}
                                  </IconButton>
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={frequencyGridWidth}>
                              <ActionInputButtons
                                disabled={fitting.read_only}
                                title="125HZ*"
                                onChange={(val) => {
                                  dispatch(changeByteValue(36, val));
                                }}
                                min={0}
                                max={80}
                                value={fitting.data?.[36]}
                                step={1}
                              />
                            </Grid>

                            {(fitting.device_type == DEVICES.BTE_32 ||
                              fitting.device_type == DEVICES.BTE_24) && (
                              <>
                                {
                                  // 32channel
                                }
                                <Grid item xs={12} md={frequencyGridWidth}>
                                  <ActionInputButtons
                                    disabled={fitting.read_only}
                                    title={
                                      fitting.device_type == DEVICES.BTE_24
                                        ? "189HZ"
                                        : "173HZ"
                                    }
                                    value={findMediun([
                                      fitting.data?.[36],
                                      findMediun([
                                        fitting.data?.[36],
                                        fitting.data?.[37],
                                      ]),
                                    ])}
                                    showValue={Math.round(
                                      findMediun([
                                        fitting.data?.[36],
                                        findMediun([
                                          fitting.data?.[36],
                                          fitting.data?.[37],
                                        ]),
                                      ])
                                    )}
                                    onChange={(val) => {
                                      const tmp = changeOriginalValueFromMediun(
                                        val,
                                        [
                                          fitting.data?.[36],
                                          findMediun([
                                            fitting.data?.[36],
                                            fitting.data?.[37],
                                          ]),
                                        ]
                                      );
                                      dispatch(
                                        changeMultipleByteValue(
                                          [36, 37],
                                          changeOriginalValueFromMediun(
                                            tmp[1],
                                            [
                                              fitting.data?.[36],
                                              fitting.data?.[37],
                                            ]
                                          )
                                        )
                                      );
                                    }}
                                    min={0}
                                    max={80}
                                    step={1}
                                  />
                                </Grid>
                              </>
                            )}

                            {
                              // 16channel
                            }
                            {(fitting.device_type == DEVICES.BTE_32 ||
                              fitting.device_type == DEVICES.BTE_16 ||
                              fitting.device_type == DEVICES.BTE_24) && (
                              <Grid item xs={12} md={frequencyGridWidth}>
                                <ActionInputButtons
                                  disabled={fitting.read_only}
                                  title={
                                    fitting.device_type == DEVICES.BTE_24
                                      ? "253HZ"
                                      : "221HZ"
                                  }
                                  value={findMediun([
                                    fitting.data?.[36],
                                    fitting.data?.[37],
                                  ])}
                                  showValue={Math.round(
                                    findMediun([
                                      fitting.data?.[36],
                                      fitting.data?.[37],
                                    ])
                                  )}
                                  onChange={(val) => {
                                    dispatch(
                                      changeMultipleByteValue(
                                        [36, 37],
                                        changeOriginalValueFromMediun(val, [
                                          fitting.data?.[36],
                                          fitting.data?.[37],
                                        ])
                                      )
                                    );
                                  }}
                                  min={0}
                                  max={80}
                                  step={1}
                                />
                              </Grid>
                            )}

                            {
                              // 32channel
                            }
                            {fitting.device_type == DEVICES.BTE_32 && (
                              <>
                                <Grid item xs={12} md={frequencyGridWidth}>
                                  <ActionInputButtons
                                    disabled={fitting.read_only}
                                    title="269HZ"
                                    value={findMediun([
                                      findMediun([
                                        fitting.data?.[36],
                                        fitting.data?.[37],
                                      ]),
                                      fitting.data?.[37],
                                    ])}
                                    showValue={Math.round(
                                      findMediun([
                                        findMediun([
                                          fitting.data?.[36],
                                          fitting.data?.[37],
                                        ]),
                                        fitting.data?.[37],
                                      ])
                                    )}
                                    onChange={(val) => {
                                      const tmp = changeOriginalValueFromMediun(
                                        val,
                                        [
                                          findMediun([
                                            fitting.data?.[36],
                                            fitting.data?.[37],
                                          ]),
                                          fitting.data?.[37],
                                        ]
                                      );
                                      dispatch(
                                        changeMultipleByteValue(
                                          [36, 37],
                                          changeOriginalValueFromMediun(
                                            tmp[0],
                                            [
                                              fitting.data?.[36],
                                              fitting.data?.[37],
                                            ]
                                          )
                                        )
                                      );
                                    }}
                                    min={0}
                                    max={80}
                                    step={1}
                                  />
                                </Grid>
                              </>
                            )}
                            <Grid item xs={12} md={frequencyGridWidth}>
                              {/* *********************************************************************************************************** */}
                              <ActionInputButtons
                                disabled={fitting.read_only}
                                title="317HZ*"
                                onChange={(val) => {
                                  dispatch(changeByteValue(37, val));
                                }}
                                min={0}
                                max={80}
                                value={fitting.data?.[37]}
                                step={1}
                              />
                            </Grid>

                            {
                              // 32channel
                            }
                            {fitting.device_type == DEVICES.BTE_32 ||
                              (fitting.device_type == DEVICES.BTE_24 && (
                                <>
                                  <Grid item xs={12} md={frequencyGridWidth}>
                                    <ActionInputButtons
                                      disabled={fitting.read_only}
                                      title={
                                        fitting.device_type == DEVICES.BTE_24
                                          ? "379HZ"
                                          : "364HZ"
                                      }
                                      value={findMediun([
                                        fitting.data?.[37],
                                        findMediun([
                                          fitting.data?.[37],
                                          fitting.data?.[38],
                                        ]),
                                      ])}
                                      showValue={Math.round(
                                        findMediun([
                                          fitting.data?.[37],
                                          findMediun([
                                            fitting.data?.[37],
                                            fitting.data?.[38],
                                          ]),
                                        ])
                                      )}
                                      onChange={(val) => {
                                        const tmp =
                                          changeOriginalValueFromMediun(val, [
                                            fitting.data?.[37],
                                            findMediun([
                                              fitting.data?.[37],
                                              fitting.data?.[38],
                                            ]),
                                          ]);
                                        dispatch(
                                          changeMultipleByteValue(
                                            [37, 38],
                                            changeOriginalValueFromMediun(
                                              tmp[1],
                                              [
                                                fitting.data?.[37],
                                                fitting.data?.[38],
                                              ]
                                            )
                                          )
                                        );
                                      }}
                                      min={0}
                                      max={80}
                                      step={1}
                                    />
                                  </Grid>
                                </>
                              ))}

                            {
                              // 16channel
                            }
                            {(fitting.device_type == DEVICES.BTE_32 ||
                              fitting.device_type == DEVICES.BTE_16 ||
                              fitting.device_type == DEVICES.BTE_24) && (
                              <Grid item xs={12} md={frequencyGridWidth}>
                                <ActionInputButtons
                                  disabled={fitting.read_only}
                                  title={
                                    fitting.device_type == DEVICES.BTE_24
                                      ? "441HZ"
                                      : "410Hz"
                                  }
                                  value={findMediun([
                                    fitting.data?.[37],
                                    fitting.data?.[38],
                                  ])}
                                  showValue={Math.round(
                                    findMediun([
                                      fitting.data?.[37],
                                      fitting.data?.[38],
                                    ])
                                  )}
                                  onChange={(val) => {
                                    dispatch(
                                      changeMultipleByteValue(
                                        [37, 38],
                                        changeOriginalValueFromMediun(val, [
                                          fitting.data?.[37],
                                          fitting.data?.[38],
                                        ])
                                      )
                                    );
                                  }}
                                  min={0}
                                  max={80}
                                  step={1}
                                />
                              </Grid>
                            )}

                            {
                              // 32channel
                            }
                            {fitting.device_type == DEVICES.BTE_32 && (
                              <>
                                <Grid item xs={12} md={frequencyGridWidth}>
                                  <ActionInputButtons
                                    disabled={fitting.read_only}
                                    title="456HZ"
                                    value={findMediun([
                                      findMediun([
                                        fitting.data?.[37],
                                        fitting.data?.[38],
                                      ]),
                                      fitting.data?.[38],
                                    ])}
                                    showValue={Math.round(
                                      findMediun([
                                        findMediun([
                                          fitting.data?.[37],
                                          fitting.data?.[38],
                                        ]),
                                        fitting.data?.[38],
                                      ])
                                    )}
                                    onChange={(val) => {
                                      const tmp = changeOriginalValueFromMediun(
                                        val,
                                        [
                                          findMediun([
                                            fitting.data?.[37],
                                            fitting.data?.[38],
                                          ]),
                                          fitting.data?.[38],
                                        ]
                                      );
                                      dispatch(
                                        changeMultipleByteValue(
                                          [37, 38],
                                          changeOriginalValueFromMediun(
                                            tmp[0],
                                            [
                                              fitting.data?.[37],
                                              fitting.data?.[38],
                                            ]
                                          )
                                        )
                                      );
                                    }}
                                    min={0}
                                    max={80}
                                    step={1}
                                  />
                                </Grid>
                              </>
                            )}
                            <Grid item xs={12} md={frequencyGridWidth}>
                              {/* *********************************************************************************************************** */}
                              <ActionInputButtons
                                disabled={fitting.read_only}
                                title="502HZ*"
                                onChange={(val) => {
                                  dispatch(changeByteValue(38, val));
                                }}
                                min={0}
                                max={80}
                                value={fitting.data?.[38]}
                                step={1}
                              />
                            </Grid>
                            {
                              // 32channel
                            }
                            {(fitting.device_type == DEVICES.BTE_32 ||
                              fitting.device_type == DEVICES.BTE_24) && (
                              <>
                                <Grid item xs={12} md={frequencyGridWidth}>
                                  <ActionInputButtons
                                    disabled={fitting.read_only}
                                    title={
                                      fitting.device_type == DEVICES.BTE_24
                                        ? "601HZ"
                                        : "576HZ"
                                    }
                                    value={findMediun([
                                      fitting.data?.[38],
                                      findMediun([
                                        fitting.data?.[38],
                                        fitting.data?.[39],
                                      ]),
                                    ])}
                                    showValue={Math.round(
                                      findMediun([
                                        fitting.data?.[38],
                                        findMediun([
                                          fitting.data?.[38],
                                          fitting.data?.[39],
                                        ]),
                                      ])
                                    )}
                                    onChange={(val) => {
                                      const tmp = changeOriginalValueFromMediun(
                                        val,
                                        [
                                          fitting.data?.[38],
                                          findMediun([
                                            fitting.data?.[38],
                                            fitting.data?.[39],
                                          ]),
                                        ]
                                      );
                                      dispatch(
                                        changeMultipleByteValue(
                                          [38, 39],
                                          changeOriginalValueFromMediun(
                                            tmp[1],
                                            [
                                              fitting.data?.[38],
                                              fitting.data?.[39],
                                            ]
                                          )
                                        )
                                      );
                                    }}
                                    min={0}
                                    max={80}
                                    step={1}
                                  />
                                </Grid>
                              </>
                            )}
                            {
                              // 16channel
                            }
                            {(fitting.device_type == DEVICES.BTE_32 ||
                              fitting.device_type == DEVICES.BTE_16 ||
                              fitting.device_type == DEVICES.BTE_24) && (
                              <Grid item xs={12} md={frequencyGridWidth}>
                                <ActionInputButtons
                                  disabled={fitting.read_only}
                                  title={
                                    fitting.device_type == DEVICES.BTE_24
                                      ? "700HZ"
                                      : "650Hz"
                                  }
                                  value={findMediun([
                                    fitting.data?.[38],
                                    fitting.data?.[39],
                                  ])}
                                  showValue={Math.round(
                                    findMediun([
                                      fitting.data?.[38],
                                      fitting.data?.[39],
                                    ])
                                  )}
                                  onChange={(val) => {
                                    dispatch(
                                      changeMultipleByteValue(
                                        [38, 39],
                                        changeOriginalValueFromMediun(val, [
                                          fitting.data?.[38],
                                          fitting.data?.[39],
                                        ])
                                      )
                                    );
                                  }}
                                  min={0}
                                  max={80}
                                  step={1}
                                />
                              </Grid>
                            )}

                            {
                              // 32channel
                            }
                            {fitting.device_type == DEVICES.BTE_32 && (
                              <>
                                <Grid item xs={12} md={frequencyGridWidth}>
                                  <ActionInputButtons
                                    disabled={fitting.read_only}
                                    title="723HZ"
                                    value={findMediun([
                                      findMediun([
                                        fitting.data?.[38],
                                        fitting.data?.[39],
                                      ]),
                                      fitting.data?.[39],
                                    ])}
                                    showValue={Math.round(
                                      findMediun([
                                        findMediun([
                                          fitting.data?.[38],
                                          fitting.data?.[39],
                                        ]),
                                        fitting.data?.[39],
                                      ])
                                    )}
                                    onChange={(val) => {
                                      const tmp = changeOriginalValueFromMediun(
                                        val,
                                        [
                                          findMediun([
                                            fitting.data?.[38],
                                            fitting.data?.[39],
                                          ]),
                                          fitting.data?.[39],
                                        ]
                                      );
                                      dispatch(
                                        changeMultipleByteValue(
                                          [38, 39],
                                          changeOriginalValueFromMediun(
                                            tmp[0],
                                            [
                                              fitting.data?.[38],
                                              fitting.data?.[39],
                                            ]
                                          )
                                        )
                                      );
                                    }}
                                    min={0}
                                    max={80}
                                    step={1}
                                  />
                                </Grid>
                              </>
                            )}

                            <Grid item xs={12} md={frequencyGridWidth}>
                              {/* *********************************************************************************************************** */}
                              <ActionInputButtons
                                disabled={fitting.read_only}
                                title="797HZ*"
                                onChange={(val) => {
                                  dispatch(changeByteValue(39, val));
                                }}
                                min={0}
                                max={80}
                                value={fitting.data?.[39]}
                                step={1}
                              />
                            </Grid>
                            {
                              // 32channel
                            }
                            {(fitting.device_type == DEVICES.BTE_32 ||
                              fitting.device_type == DEVICES.BTE_24) && (
                              <>
                                <Grid item xs={12} md={frequencyGridWidth}>
                                  <ActionInputButtons
                                    disabled={fitting.read_only}
                                    title={
                                      fitting.device_type == DEVICES.BTE_24
                                        ? "953HZ"
                                        : "914HZ"
                                    }
                                    value={findMediun([
                                      fitting.data?.[39],
                                      findMediun([
                                        fitting.data?.[39],
                                        fitting.data?.[40],
                                      ]),
                                    ])}
                                    showValue={Math.round(
                                      findMediun([
                                        fitting.data?.[39],
                                        findMediun([
                                          fitting.data?.[39],
                                          fitting.data?.[40],
                                        ]),
                                      ])
                                    )}
                                    onChange={(val) => {
                                      const tmp = changeOriginalValueFromMediun(
                                        val,
                                        [
                                          fitting.data?.[39],
                                          findMediun([
                                            fitting.data?.[39],
                                            fitting.data?.[40],
                                          ]),
                                        ]
                                      );
                                      dispatch(
                                        changeMultipleByteValue(
                                          [39, 40],
                                          changeOriginalValueFromMediun(
                                            tmp[1],
                                            [
                                              fitting.data?.[39],
                                              fitting.data?.[40],
                                            ]
                                          )
                                        )
                                      );
                                    }}
                                    min={0}
                                    max={80}
                                    step={1}
                                  />
                                </Grid>
                              </>
                            )}
                            {
                              // 16channel
                            }
                            {(fitting.device_type == DEVICES.BTE_32 ||
                              fitting.device_type == DEVICES.BTE_16 ||
                              fitting.device_type == DEVICES.BTE_24) && (
                              <Grid item xs={12} md={frequencyGridWidth}>
                                <ActionInputButtons
                                  disabled={fitting.read_only}
                                  title={
                                    fitting.device_type == DEVICES.BTE_24
                                      ? "1109HZ"
                                      : "1031Hz"
                                  }
                                  value={findMediun([
                                    fitting.data?.[39],
                                    fitting.data?.[40],
                                  ])}
                                  showValue={Math.round(
                                    findMediun([
                                      fitting.data?.[39],
                                      fitting.data?.[40],
                                    ])
                                  )}
                                  onChange={(val) => {
                                    dispatch(
                                      changeMultipleByteValue(
                                        [39, 40],
                                        changeOriginalValueFromMediun(val, [
                                          fitting.data?.[39],
                                          fitting.data?.[40],
                                        ])
                                      )
                                    );
                                  }}
                                  min={0}
                                  max={80}
                                  step={1}
                                />
                              </Grid>
                            )}
                            {
                              // 32channel
                            }
                            {fitting.device_type == DEVICES.BTE_32 && (
                              <>
                                <Grid item xs={12} md={frequencyGridWidth}>
                                  <ActionInputButtons
                                    disabled={fitting.read_only}
                                    title="1148HZ"
                                    value={findMediun([
                                      findMediun([
                                        fitting.data?.[39],
                                        fitting.data?.[40],
                                      ]),
                                      fitting.data?.[40],
                                    ])}
                                    showValue={Math.round(
                                      findMediun([
                                        findMediun([
                                          fitting.data?.[39],
                                          fitting.data?.[40],
                                        ]),
                                        fitting.data?.[40],
                                      ])
                                    )}
                                    onChange={(val) => {
                                      const tmp = changeOriginalValueFromMediun(
                                        val,
                                        [
                                          findMediun([
                                            fitting.data?.[39],
                                            fitting.data?.[40],
                                          ]),
                                          fitting.data?.[40],
                                        ]
                                      );
                                      dispatch(
                                        changeMultipleByteValue(
                                          [39, 40],
                                          changeOriginalValueFromMediun(
                                            tmp[0],
                                            [
                                              fitting.data?.[39],
                                              fitting.data?.[40],
                                            ]
                                          )
                                        )
                                      );
                                    }}
                                    min={0}
                                    max={80}
                                    step={1}
                                  />
                                </Grid>
                              </>
                            )}
                            <Grid item xs={12} md={frequencyGridWidth}>
                              {/* *********************************************************************************************************** */}
                              <ActionInputButtons
                                disabled={fitting.read_only}
                                title="1264HZ*"
                                onChange={(val) => {
                                  dispatch(changeByteValue(40, val));
                                }}
                                min={0}
                                max={80}
                                value={fitting.data?.[40]}
                                step={1}
                              />
                            </Grid>
                            {
                              // 32channel
                            }
                            {(fitting.device_type == DEVICES.BTE_32 ||
                              fitting.device_type == DEVICES.BTE_24) && (
                              <>
                                <Grid item xs={12} md={frequencyGridWidth}>
                                  <ActionInputButtons
                                    disabled={fitting.read_only}
                                    title={
                                      fitting.device_type == DEVICES.BTE_24
                                        ? "1511HZ"
                                        : "1450HZ"
                                    }
                                    value={findMediun([
                                      fitting.data?.[40],
                                      findMediun([
                                        fitting.data?.[40],
                                        fitting.data?.[41],
                                      ]),
                                    ])}
                                    showValue={Math.round(
                                      findMediun([
                                        fitting.data?.[40],
                                        findMediun([
                                          fitting.data?.[40],
                                          fitting.data?.[41],
                                        ]),
                                      ])
                                    )}
                                    onChange={(val) => {
                                      const tmp = changeOriginalValueFromMediun(
                                        val,
                                        [
                                          fitting.data?.[40],
                                          findMediun([
                                            fitting.data?.[40],
                                            fitting.data?.[41],
                                          ]),
                                        ]
                                      );
                                      dispatch(
                                        changeMultipleByteValue(
                                          [40, 41],
                                          changeOriginalValueFromMediun(
                                            tmp[1],
                                            [
                                              fitting.data?.[40],
                                              fitting.data?.[41],
                                            ]
                                          )
                                        )
                                      );
                                    }}
                                    min={0}
                                    max={80}
                                    step={1}
                                  />
                                </Grid>
                              </>
                            )}
                            {
                              // 16channel
                            }
                            {(fitting.device_type == DEVICES.BTE_32 ||
                              fitting.device_type == DEVICES.BTE_16 ||
                              fitting.device_type == DEVICES.BTE_24) && (
                              <Grid item xs={12} md={frequencyGridWidth}>
                                <ActionInputButtons
                                  disabled={fitting.read_only}
                                  title={
                                    fitting.device_type == DEVICES.BTE_24
                                      ? "1758HZ"
                                      : "1635Hz"
                                  }
                                  value={findMediun([
                                    fitting.data?.[40],
                                    fitting.data?.[41],
                                  ])}
                                  showValue={Math.round(
                                    findMediun([
                                      fitting.data?.[40],
                                      fitting.data?.[41],
                                    ])
                                  )}
                                  onChange={(val) => {
                                    dispatch(
                                      changeMultipleByteValue(
                                        [40, 41],
                                        changeOriginalValueFromMediun(val, [
                                          fitting.data?.[40],
                                          fitting.data?.[41],
                                        ])
                                      )
                                    );
                                  }}
                                  min={0}
                                  max={80}
                                  step={1}
                                />
                              </Grid>
                            )}

                            {
                              // 32channel
                            }
                            {fitting.device_type == DEVICES.BTE_32 && (
                              <>
                                <Grid item xs={12} md={frequencyGridWidth}>
                                  <ActionInputButtons
                                    disabled={fitting.read_only}
                                    title="1820HZ"
                                    value={findMediun([
                                      findMediun([
                                        fitting.data?.[40],
                                        fitting.data?.[41],
                                      ]),
                                      fitting.data?.[41],
                                    ])}
                                    showValue={Math.round(
                                      findMediun([
                                        findMediun([
                                          fitting.data?.[40],
                                          fitting.data?.[41],
                                        ]),
                                        fitting.data?.[41],
                                      ])
                                    )}
                                    onChange={(val) => {
                                      const tmp = changeOriginalValueFromMediun(
                                        val,
                                        [
                                          findMediun([
                                            fitting.data?.[40],
                                            fitting.data?.[41],
                                          ]),
                                          fitting.data?.[41],
                                        ]
                                      );
                                      dispatch(
                                        changeMultipleByteValue(
                                          [40, 41],
                                          changeOriginalValueFromMediun(
                                            tmp[0],
                                            [
                                              fitting.data?.[40],
                                              fitting.data?.[41],
                                            ]
                                          )
                                        )
                                      );
                                    }}
                                    min={0}
                                    max={80}
                                    step={1}
                                  />
                                </Grid>
                              </>
                            )}
                            <Grid item xs={12} md={frequencyGridWidth}>
                              {/* *********************************************************************************************************** */}
                              <ActionInputButtons
                                disabled={fitting.read_only}
                                title="2005HZ*"
                                onChange={(val) => {
                                  dispatch(changeByteValue(41, val));
                                }}
                                min={0}
                                max={80}
                                value={fitting.data?.[41]}
                                step={1}
                              />
                            </Grid>
                            {
                              // 32channel
                            }
                            {(fitting.device_type == DEVICES.BTE_32 ||
                              fitting.device_type == DEVICES.BTE_24) && (
                              <>
                                <Grid item xs={12} md={frequencyGridWidth}>
                                  <ActionInputButtons
                                    disabled={fitting.read_only}
                                    title={
                                      fitting.device_type == DEVICES.BTE_24
                                        ? "2397HZ"
                                        : "3000HZ"
                                    }
                                    value={findMediun([
                                      fitting.data?.[41],
                                      findMediun([
                                        fitting.data?.[41],
                                        fitting.data?.[42],
                                      ]),
                                    ])}
                                    showValue={Math.round(
                                      findMediun([
                                        fitting.data?.[41],
                                        findMediun([
                                          fitting.data?.[41],
                                          fitting.data?.[42],
                                        ]),
                                      ])
                                    )}
                                    onChange={(val) => {
                                      const tmp = changeOriginalValueFromMediun(
                                        val,
                                        [
                                          fitting.data?.[41],
                                          findMediun([
                                            fitting.data?.[41],
                                            fitting.data?.[42],
                                          ]),
                                        ]
                                      );
                                      dispatch(
                                        changeMultipleByteValue(
                                          [41, 42],
                                          changeOriginalValueFromMediun(
                                            tmp[1],
                                            [
                                              fitting.data?.[41],
                                              fitting.data?.[42],
                                            ]
                                          )
                                        )
                                      );
                                    }}
                                    min={0}
                                    max={80}
                                    step={1}
                                  />
                                </Grid>
                              </>
                            )}
                            {
                              // 16channel
                            }
                            {(fitting.device_type == DEVICES.BTE_32 ||
                              fitting.device_type == DEVICES.BTE_16 ||
                              fitting.device_type == DEVICES.BTE_24) && (
                              <Grid item xs={12} md={frequencyGridWidth}>
                                <ActionInputButtons
                                  disabled={fitting.read_only}
                                  title={
                                    fitting.device_type == DEVICES.BTE_24
                                      ? "2789HZ"
                                      : "2593Hz"
                                  }
                                  value={findMediun([
                                    fitting.data?.[41],
                                    fitting.data?.[42],
                                  ])}
                                  showValue={Math.round(
                                    findMediun([
                                      fitting.data?.[41],
                                      fitting.data?.[42],
                                    ])
                                  )}
                                  onChange={(val) => {
                                    dispatch(
                                      changeMultipleByteValue(
                                        [41, 42],
                                        changeOriginalValueFromMediun(val, [
                                          fitting.data?.[41],
                                          fitting.data?.[42],
                                        ])
                                      )
                                    );
                                  }}
                                  min={0}
                                  max={80}
                                  step={1}
                                />
                              </Grid>
                            )}

                            {
                              // 32channel
                            }
                            {fitting.device_type == DEVICES.BTE_32 && (
                              <>
                                <Grid item xs={12} md={frequencyGridWidth}>
                                  <ActionInputButtons
                                    disabled={fitting.read_only}
                                    title="2860HZ"
                                    value={findMediun([
                                      findMediun([
                                        fitting.data?.[41],
                                        fitting.data?.[42],
                                      ]),
                                      fitting.data?.[42],
                                    ])}
                                    showValue={Math.round(
                                      findMediun([
                                        findMediun([
                                          fitting.data?.[41],
                                          fitting.data?.[42],
                                        ]),
                                        fitting.data?.[42],
                                      ])
                                    )}
                                    onChange={(val) => {
                                      const tmp = changeOriginalValueFromMediun(
                                        val,
                                        [
                                          findMediun([
                                            fitting.data?.[41],
                                            fitting.data?.[42],
                                          ]),
                                          fitting.data?.[42],
                                        ]
                                      );
                                      dispatch(
                                        changeMultipleByteValue(
                                          [41, 42],
                                          changeOriginalValueFromMediun(
                                            tmp[0],
                                            [
                                              fitting.data?.[41],
                                              fitting.data?.[42],
                                            ]
                                          )
                                        )
                                      );
                                    }}
                                    min={0}
                                    max={80}
                                    step={1}
                                  />
                                </Grid>
                              </>
                            )}

                            <Grid item xs={12} md={frequencyGridWidth}>
                              {/* *********************************************************************************************************** */}
                              <ActionInputButtons
                                disabled={fitting.read_only}
                                title="3181HZ*"
                                onChange={(val) => {
                                  dispatch(changeByteValue(42, val));
                                }}
                                min={0}
                                max={80}
                                value={fitting.data?.[42]}
                                step={1}
                              />
                            </Grid>

                            {
                              // 32channel
                            }
                            {(fitting.device_type == DEVICES.BTE_32 ||
                              fitting.device_type == DEVICES.BTE_24) && (
                              <>
                                <Grid item xs={12} md={frequencyGridWidth}>
                                  <ActionInputButtons
                                    disabled={fitting.read_only}
                                    title={
                                      fitting.device_type == DEVICES.BTE_24
                                        ? "3802HZ"
                                        : "3647HZ"
                                    }
                                    value={findMediun([
                                      fitting.data?.[42],
                                      findMediun([
                                        fitting.data?.[42],
                                        fitting.data?.[43],
                                      ]),
                                    ])}
                                    showValue={Math.round(
                                      findMediun([
                                        fitting.data?.[42],
                                        findMediun([
                                          fitting.data?.[42],
                                          fitting.data?.[43],
                                        ]),
                                      ])
                                    )}
                                    onChange={(val) => {
                                      const tmp = changeOriginalValueFromMediun(
                                        val,
                                        [
                                          fitting.data?.[42],
                                          findMediun([
                                            fitting.data?.[42],
                                            fitting.data?.[43],
                                          ]),
                                        ]
                                      );
                                      dispatch(
                                        changeMultipleByteValue(
                                          [42, 43],
                                          changeOriginalValueFromMediun(
                                            tmp[1],
                                            [
                                              fitting.data?.[42],
                                              fitting.data?.[43],
                                            ]
                                          )
                                        )
                                      );
                                    }}
                                    min={0}
                                    max={80}
                                    step={1}
                                  />
                                </Grid>
                              </>
                            )}

                            {
                              // 16channel
                            }
                            {(fitting.device_type == DEVICES.BTE_32 ||
                              fitting.device_type == DEVICES.BTE_16 ||
                              fitting.device_type == DEVICES.BTE_24) && (
                              <Grid item xs={12} md={frequencyGridWidth}>
                                <ActionInputButtons
                                  disabled={fitting.read_only}
                                  title={
                                    fitting.device_type == DEVICES.BTE_24
                                      ? "4423HZ"
                                      : "4113Hz"
                                  }
                                  value={findMediun([
                                    fitting.data?.[42],
                                    fitting.data?.[43],
                                  ])}
                                  showValue={Math.round(
                                    findMediun([
                                      fitting.data?.[42],
                                      fitting.data?.[43],
                                    ])
                                  )}
                                  onChange={(val) => {
                                    dispatch(
                                      changeMultipleByteValue(
                                        [42, 43],
                                        changeOriginalValueFromMediun(val, [
                                          fitting.data?.[42],
                                          fitting.data?.[43],
                                        ])
                                      )
                                    );
                                  }}
                                  min={0}
                                  max={80}
                                  step={1}
                                />
                              </Grid>
                            )}

                            {
                              // 32channel
                            }
                            {fitting.device_type == DEVICES.BTE_32 && (
                              <>
                                <Grid item xs={12} md={frequencyGridWidth}>
                                  <ActionInputButtons
                                    disabled={fitting.read_only}
                                    title="4578HZ"
                                    value={findMediun([
                                      findMediun([
                                        fitting.data?.[42],
                                        fitting.data?.[43],
                                      ]),
                                      fitting.data?.[43],
                                    ])}
                                    showValue={Math.round(
                                      findMediun([
                                        findMediun([
                                          fitting.data?.[42],
                                          fitting.data?.[43],
                                        ]),
                                        fitting.data?.[43],
                                      ])
                                    )}
                                    onChange={(val) => {
                                      const tmp = changeOriginalValueFromMediun(
                                        val,
                                        [
                                          findMediun([
                                            fitting.data?.[42],
                                            fitting.data?.[43],
                                          ]),
                                          fitting.data?.[43],
                                        ]
                                      );
                                      dispatch(
                                        changeMultipleByteValue(
                                          [42, 43],
                                          changeOriginalValueFromMediun(
                                            tmp[0],
                                            [
                                              fitting.data?.[42],
                                              fitting.data?.[43],
                                            ]
                                          )
                                        )
                                      );
                                    }}
                                    min={0}
                                    max={80}
                                    step={1}
                                  />
                                </Grid>
                              </>
                            )}

                            <Grid item xs={12} md={frequencyGridWidth}>
                              {/* *********************************************************************************************************** */}
                              <ActionInputButtons
                                disabled={fitting.read_only}
                                title="5044HZ*"
                                onChange={(val) => {
                                  dispatch(changeByteValue(43, val));
                                }}
                                value={fitting.data?.[43]}
                                min={0}
                                max={80}
                                step={1}
                              />
                            </Grid>
                            {
                              // 32channel
                            }
                            {(fitting.device_type == DEVICES.BTE_32 ||
                              fitting.device_type == DEVICES.BTE_24) && (
                              <>
                                <Grid item xs={12} md={frequencyGridWidth}>
                                  <ActionInputButtons
                                    disabled={fitting.read_only}
                                    title="6522HZ"
                                    value={findMediun([
                                      fitting.data?.[43],
                                      findMediun([fitting.data?.[43], edgeVal]),
                                    ])}
                                    showValue={Math.round(
                                      findMediun([
                                        fitting.data?.[43],
                                        findMediun([
                                          fitting.data?.[43],
                                          edgeVal,
                                        ]),
                                      ])
                                    )}
                                    onChange={(val) => {
                                      const tmp = changeOriginalValueFromMediun(
                                        val,
                                        [
                                          fitting.data?.[43],
                                          findMediun([
                                            fitting.data?.[43],
                                            edgeVal,
                                          ]),
                                        ]
                                      );
                                      const tmp2 =
                                        changeOriginalValueFromMediun(tmp[1], [
                                          fitting.data?.[43],
                                          edgeVal,
                                        ]);
                                      dispatch(
                                        // changeMultipleByteValue(
                                        //     [43, 36],
                                        //     changeOriginalValueFromMediun(tmp[1], [fitting.data?.[43], edgeVal])
                                        // )
                                        changeByteValue(43, tmp2[0])
                                      );
                                      setEdgeVal(tmp2[1]);
                                    }}
                                    min={0}
                                    max={80}
                                    step={1}
                                  />
                                </Grid>
                              </>
                            )}
                            {
                              // 32channel
                            }
                            {fitting.device_type == DEVICES.BTE_32 && (
                              <>
                                <Grid item xs={12} md={frequencyGridWidth}>
                                  <ActionInputButtons
                                    disabled={fitting.read_only}
                                    title="7015HZ"
                                    value={findMediun([
                                      findMediun([fitting.data?.[43], edgeVal]),
                                      edgeVal,
                                    ])}
                                    showValue={Math.round(
                                      findMediun([
                                        findMediun([
                                          fitting.data?.[43],
                                          edgeVal,
                                        ]),
                                        edgeVal,
                                      ])
                                    )}
                                    onChange={(val) => {
                                      const tmp = changeOriginalValueFromMediun(
                                        val,
                                        [
                                          findMediun([
                                            fitting.data?.[43],
                                            edgeVal,
                                          ]),
                                          edgeVal,
                                        ]
                                      );
                                      const tmp2 =
                                        changeOriginalValueFromMediun(tmp[0], [
                                          fitting.data?.[43],
                                          edgeVal,
                                        ]);
                                      dispatch(
                                        // changeMultipleByteValue(
                                        //     [43, 36],
                                        //     changeOriginalValueFromMediun(tmp[0], [fitting.data?.[43], edgeVal])
                                        // )
                                        changeByteValue(43, tmp2[0])
                                      );
                                      dispatch(setEdgeVal(tmp2[1]));
                                    }}
                                    min={0}
                                    max={80}
                                    step={1}
                                  />
                                </Grid>
                              </>
                            )}
                            {
                              // 16channel
                            }
                            {(fitting.device_type == DEVICES.BTE_32 ||
                              fitting.device_type == DEVICES.BTE_16 ||
                              fitting.device_type == DEVICES.BTE_24) && (
                              <Grid item xs={12} md={frequencyGridWidth}>
                                <ActionInputButtons
                                  disabled={fitting.read_only}
                                  title="8000Hz"
                                  value={findMediun([
                                    edgeVal,
                                    fitting.data?.[43],
                                  ])}
                                  showValue={Math.round(
                                    findMediun([edgeVal, fitting.data?.[43]])
                                  )}
                                  onChange={(val) => {
                                    const temp = changeOriginalValueFromMediun(
                                      val,
                                      [edgeVal, fitting.data?.[43]]
                                    );
                                    dispatch(changeByteValue(43, temp[1]));
                                    setEdgeVal(temp[0]);
                                  }}
                                  min={0}
                                  max={80}
                                  step={1}
                                />
                              </Grid>
                            )}

                            <Grid xs={12} mt={5}>
                              <Divider />
                            </Grid>

                            {
                              <FormGroup>
                                <FormControlLabel
                                  disabled={fitting.read_only}
                                  sx={{
                                    marginTop: "20px",
                                    marginLeft: "20px",
                                  }}
                                  onChange={(e) =>
                                    dispatch(
                                      applyFeedbackCancellation(
                                        e.target.checked,
                                        false
                                      )
                                    )
                                  }
                                  // checked={fields.allowed_ac}
                                  control={
                                    <Checkbox
                                      checked={
                                        fitting.data[1] == 10 &&
                                        fitting.data[4] == 200
                                      }
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: 35,
                                        },
                                      }}
                                    />
                                  }
                                  label="Feedback Cancellation"
                                />
                              </FormGroup>
                            }
                            {
                              <FormGroup>
                                <FormControlLabel
                                  disabled={fitting.read_only}
                                  sx={{ marginTop: "20px", marginLeft: "20px" }}
                                  onChange={(e) =>
                                    dispatch(
                                      applyFeedbackCancellation(
                                        e.target.checked,
                                        true
                                      )
                                    )
                                  }
                                  control={
                                    <Checkbox
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 35 },
                                      }}
                                      checked={fitting.data[3] == 28}
                                    />
                                  }
                                  label="Whisle Block"
                                />
                              </FormGroup>
                            }
                          </Grid>

                          <Grid>
                            {
                              // <FormGroup>
                              //   <FormControlLabel
                              //     disabled={fitting.read_only}
                              //     sx={{ marginTop: "10px", marginLeft: "4px" }}
                              //     onChange={(e) =>
                              //       dispatch(
                              //         applyWinNoiseBlock(e.target.checked)
                              //       )
                              //     }
                              //     control={
                              //       <Checkbox
                              //         sx={{
                              //           "& .MuiSvgIcon-root": { fontSize: 35 },
                              //         }}
                              //         checked={fitting.WindNoiseBlock}
                              //       />
                              //     }
                              //     label="Wind Noise Block"
                              //   />
                              // </FormGroup>
                            }
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                  )}

                  {tab == 1 && (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        overflow: "hidden",
                        height: "100%",
                        borderLeft: 1,
                        borderLeftColor: "divider",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          width: "100%",
                          overflow: "hidden",
                        }}
                      >
                        <Box
                          sx={{
                            height: "100%",
                            width: "100%",
                            overflowY: "auto",
                          }}
                          p={4}
                        >
                          <Box mb={3}>
                            <ActionInputSliders
                              title="Noise Reduction"
                              disabled={fitting.read_only}
                              min={-5}
                              max={5}
                              value={fitting.noise_reduction}
                              baseVal={0}
                              onChange={(x) => {
                                dispatch(changeNoiseReduction(x));
                              }}
                            />
                          </Box>
                          <Box mb={3}>
                            <ActionInputSliders
                              title="Loudness Compression"
                              disabled={fitting.read_only}
                              min={-5}
                              max={5}
                              value={fitting.loudness_compression}
                              baseVal={0}
                              onChange={(x) => {
                                dispatch(changeCompression(x));
                              }}
                            />
                          </Box>
                          <Box mb={3}>
                            <ActionInputSliders
                              title="Self Speech Reduction"
                              disabled={fitting.read_only}
                              min={-5}
                              max={5}
                              value={fitting.self_speech_reduction}
                              baseVal={0}
                              onChange={(x) => {
                                dispatch(changeSpeechRedcution(x));
                              }}
                            />
                          </Box>
                          <Box mb={3}>
                            <ActionInputSliders
                              title="Sharpness"
                              disabled={fitting.read_only}
                              min={-5}
                              max={5}
                              value={fitting.sharpness}
                              baseVal={0}
                              onChange={(x) => {
                                dispatch(changeSharpness(x));
                              }}
                            />
                          </Box>
                          <Box mb={3}>
                            <ActionInputSliders
                              title="Wind Noise Reduction"
                              disabled={fitting.read_only}
                              min={-5}
                              max={5}
                              value={fitting.wind_noise_reduction}
                              baseVal={0}
                              onChange={(x) => {
                                dispatch(changeWindNoiseReduction(x));
                              }}
                            />
                          </Box>
                          {!fitting.read_only && (
                            <>
                              <Box>
                                <Typography
                                  variant="h4"
                                  fontWeight={500}
                                  color="dark.main"
                                >
                                  Remarks
                                </Typography>
                                <TextField
                                  multiline
                                  maxRows={2}
                                  variant="standard"
                                  fullWidth
                                  value={fitting.remarks}
                                  onChange={(e) =>
                                    dispatch(changeRemarks(e.target.value))
                                  }
                                />
                              </Box>
                              {user.data.role == USER_ROLES.ADMIN && (
                                <Box mt={3}>
                                  <Typography
                                    variant="h4"
                                    fontWeight={500}
                                    color="dark.main"
                                  >
                                    Mannual Fit
                                  </Typography>
                                  <TextField
                                    multiline
                                    maxRows={2}
                                    variant="standard"
                                    fullWidth
                                    onChange={(e) =>
                                      dispatch(
                                        changeDeviceArray(e.target.value)
                                      )
                                    }
                                  />
                                </Box>
                              )}
                            </>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Divider />
      <Box
        sx={{ display: "flex", justifyContent: "space-between" }}
        p={2}
        pl={3}
        pr={3}
      >
        <Box>
          <Button
            onClick={onAdvanceFittingBtnClick}
            sx={{ minWidth: "150px", ml: 2, textDecoration: "underline" }}
            variant="text"
          >
            <Typography fontWeight={500} variant="h4">
              {" "}
              Advance Fitting
            </Typography>
          </Button>
        </Box>
        {!fitting.read_only && (
          <Box sx={{ display: "flex" }}>
            <SubmitButton
              onClick={onResetBtnClick}
              sx={{ minWidth: "150px", ml: 2 }}
              variant="outlined"
              title={
                <Typography fontWeight={500} variant="h4">
                  {" "}
                  Reset
                </Typography>
              }
            ></SubmitButton>
            {!fitting.remote && (
              <>
                <SubmitButton
                  loading={readLoading}
                  disabled={completeLoading || readLoading || saveLoadin}
                  onClick={onReadFromDeviceBtnClick}
                  sx={{ minWidth: "200px", ml: 2 }}
                  variant="outlined"
                  title={
                    <Typography fontWeight={500} variant="h4">
                      {" "}
                      Read From Device
                    </Typography>
                  }
                ></SubmitButton>
                <SubmitButton
                  disabled={completeLoading || readLoading || saveLoadin}
                  onClick={onSaveFitting}
                  loading={saveLoadin}
                  sx={{ minWidth: "150px", ml: 2 }}
                  variant="outlined"
                  title={
                    <Typography fontWeight={500} variant="h4">
                      {" "}
                      Save Fitting
                    </Typography>
                  }
                ></SubmitButton>
              </>
            )}
            <SubmitButton
              disabled={completeLoading || readLoading || saveLoadin}
              onClick={onCompeteClick}
              sx={{ minWidth: "200px", ml: 2 }}
              variant="contained"
              title={
                <Typography fontWeight={500} variant="h4">
                  {" "}
                  Complete Session
                </Typography>
              }
            ></SubmitButton>
          </Box>
        )}
        {fitting.read_only && (
          <Box sx={{ display: "flex" }}>
            <SubmitButton
              onClick={onBack}
              sx={{ minWidth: "150px", ml: 2 }}
              variant="outlined"
              title={
                <Typography fontWeight={500} variant="h4">
                  {" "}
                  Back
                </Typography>
              }
            ></SubmitButton>
          </Box>
        )}
      </Box>
    </>
  );
};

export default BasicFittingAreaController;

// IGi=0 for Hi<20dB HL
// IGi=0.6(Hi-20) for 20≤Hi≤60dB HL
// IGi=0.8Hi-23 for Hi > 60dB HL
