import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";


export const getAudiometryApi = async params => {
    const callResponse = await axios({
      url: endpoints.audiometryBase,
      method: "get",
      headers: getHeaders(),
      params,
    })
      .then(response => response.data)
      .catch(err => err.response.data);
  
    return callResponse;
  };

  
export const addAudiometryApi = async data => {
    const callResponse = await axios({
      url: endpoints.audiometryBase,
      method: "POST",
      headers: getHeaders(),
  
      data,
    })
      .then(response => response.data)
      .catch(err => err.response.data);
  
    return callResponse;
  };
  