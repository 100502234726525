import { Add, Remove } from "@mui/icons-material"
import { Box, ButtonBase, styled } from "@mui/material"
import { memo } from "react"

// const { Box, ButtonBase, styled } = require("@mui/material")

const CustomizeButton = styled(ButtonBase)(({ theme, disabled }) => ({
    height: "30px",
    width: "30px",
    display: "flex",
    alignItems: 'center',
    justifyContent: "center",
    background: disabled ? theme.palette.grey[200] : theme.palette.secondary.light,
    border: disabled ? "1px solid transparent" : "1px solid " + theme.palette.primary.main,
    cursor: disabled ? "not-allowed !important" : "pointer",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1]
}))
const InputArea = styled(Box)(({ theme }) => ({
    // height: '100%',
    width: "50px",
    display: "flex",
    alignItems: 'center',
    justifyContent: "center",

    border: "1px solid " + theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius
}))

const IncreasDecreaseButton = ({ value,showValue, onChange, min = 0, max = 10, step = 1, disabled }) => {

    const onChangeFun = (decrement) => {
        let addVal = step
        if (decrement) {
            addVal = -1 * step
        }
        let changableVal = value + addVal

        if (changableVal > max) {
            changableVal = max
        }
        if (changableVal < min) {
            changableVal = min
        }

        onChange(changableVal)
    }

    return <Box sx={{ display: 'flex' }}>
        <CustomizeButton onClick={() => onChangeFun(true)} disabled={disabled || (value - step) < min} sx={{ display: "flex" }}>
            <Remove />
        </CustomizeButton>
        <InputArea sx={{ width: "50px" }} ml={2} mr={2}>
            {showValue?showValue:value}
        </InputArea>
        <CustomizeButton onClick={() => onChangeFun(false)} disabled={disabled || (value + step) > max}>
            <Add />
        </CustomizeButton>
    </Box>


}
export default memo(IncreasDecreaseButton)