import { History } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import { useDispatch } from "react-redux"
import { openModal } from "../../store/actions/modalAction"
import AudiogramListController from "./AudiogramListController"

const OpenAudioGramHistoryButton = ({ patient_id,...props }) => {
    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(openModal(<AudiogramListController patient_id={patient_id} />,"lg",undefined,'audiogram-history'))
    }
    return <Tooltip title="show history" >
         <IconButton onClick={onClick}  sx={{ padding: 0 }}>
        <History {...props}/>
    </IconButton>
    </Tooltip>
}
export default OpenAudioGramHistoryButton