
import CustomInput from '../../components/inputs/CustomInput';
import { Box, Fade, Grid, Paper, Slide, TextField, Typography, styled } from '@mui/material';
import OneViewBox, { CenteredBox } from '../../components/layouts/OneViewBox';
import SubmitButton from '../../components/button/SubmitButton';
import { center } from '../../assets/css/theme/common';

import Logo from '../../assets/images/logo.png'
import ImageComponent from '../../components/ImageComponent';
import { Link, useLocation } from 'react-router-dom';
import { useRef } from 'react';

const SignInBoxUi = styled(Box)(({ theme }) => ({
    display: "flex",
    maxWidth: "90%",
    height: "90%",
    borderRadius: theme.shape.borderRadius * 4,
    background: theme.palette.light.main,

    width: "1416px",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid " + theme.palette.light.main

}));

const OnewViewContainer = styled(OneViewBox)(({ theme }) => ({
    overflow: "hidden",
    background: `linear-gradient(224deg, ${theme.palette.primary.secondary} 0%, ${theme.palette.primary.main} 100%)`
}));

const LeftSideBox = styled(CenteredBox)(({ theme }) => ({
    height: "100%",
    width: "100%",
    flexDirection: "column",
    position: "relative",
    "::after": {
        content: "' '",
        top: "0px",
        right: "0px",
        position: "absolute",
        height: "100%",
        width: "2px",
        background: 'linear-gradient(#CCCCCC00, #B5B5B5, #CCCCCC00)',
        [theme.breakpoints.down('md')]: {
            display: "none"
        }
    }

}));

const MainUi = ({ onSubmit, ...props }) => {
    const ref = useRef()
    const location = useLocation()

    return (

        <>
            <OnewViewContainer sx={{}}  >
                <Box sx={{ ...center, width: "100%", height: "100%" }} >
                    <SignInBoxUi p={4} component={"form"} width={"100%"} onSubmit={onSubmit}>
                        <Grid container spacing={2} sx={{ height: "100%" }}>
                            <Grid item md={7} xs={12}>
                                <LeftSideBox >
                                    <Box sx={{ width: "50%", maxWidth: "393.37px", minWidth: "203.37px" }}>
                                        <ImageComponent isStatic noClickEffect={true} sx={{ height: "auto", objectFit: "contain" }} src={Logo} alt={'Logo'} />
                                    </Box>
                                    {/* <Typography color="primary" fontWeight="600" variant='display1'>Wehear Config Pro</Typography> */}
                                </LeftSideBox>
                            </Grid>
                            <Grid item md={5} xs={12} >

                                <CenteredBox sx={{ flexDirection: "column", position: "relative", overflow: 'hidden' }} ref={ref} >
                                    <Slide timeout={400} direction={location.pathname != '/sign-up' ? "right" : 'left'} in={true} container={ref.current}>
                                        <Box sx={{width:"100%"}}>
                                            {props.children}
                                        </Box>
                                    </Slide>
                                </CenteredBox>

                            </Grid>
                        </Grid>
                        {/*  */}
                    </SignInBoxUi>
                </Box>


            </OnewViewContainer>


        </>
    )
}
export default MainUi