import { Autocomplete, Box, CircularProgress, InputAdornment, MenuItem, Select, Typography } from "@mui/material"
import { memo } from "react"
import { useDispatch, useSelector } from 'react-redux'

import CustomInput from "../../components/inputs/CustomInput"
import { USER_ROLES } from "../../utils/constants"
import { findNameByRole, getAllowedRoles, titleCase } from "../../utils/helper"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getUserApi } from "../../apis/user.api"
import FileInput from "../../components/inputs/FileInput"
import COUNTRY_CODES from "../../utils/CountryCode"




const CreateUi = ({ title, modalKey, isUpdate, fields, setFields, loading, onSubmit, isRolePredefined }) => {
    const { user } = useSelector(state => state)

    let allowedRoles = getAllowedRoles(user.data.role).map((key) => ({ _id: USER_ROLES[key], label: titleCase(key) }))



    const dispatch = useDispatch()
    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {loading && <CenteredBox><CircularProgress /></CenteredBox>}
            {
                <>
                    <CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.name}
                        onChange={(e) => setFields({ ...fields, err: '', name: e.target.value })}
                        type="text"
                        label={"Name*"}

                    />

                    {!isUpdate && <CustomInput
                        disabled={loading}
                        value={fields.email}
                        onChange={(e) => setFields({ ...fields, err: '', email: e.target.value })}
                        type="text"
                        label={"Email*"}

                    />}
                    {<CustomInput
                        InputProps={{
                            startAdornment:
                                <InputAdornment position="start">
                                    <Select
                                        disableUnderline
                                        value={fields.country_code}
                                        onChange={(e) =>
                                            setFields({
                                                ...fields,
                                                err: "",
                                                country_code: e.target.value,
                                            })
                                        }

                                        variant="standard"
                                        size="small"
                                    >
                                        {
                                            COUNTRY_CODES.map((item) => <MenuItem key={item.code} value={item.dial_code} >{item.code}({item.dial_code})</MenuItem>)
                                        }
                                    </Select>
                                </InputAdornment>
                        }
                        }
                        disabled={loading}
                        value={fields.phone}
                        onChange={(e) => setFields({ ...fields, err: '', phone: e.target.value })}
                        type="number"
                        label={"Phone No*"}

                    />}

                    {!isUpdate && !isRolePredefined && <Autocomplete

                        // key={loading}
                        disableClearable
                        options={allowedRoles}
                        value={findNameByRole(fields.role)}
                        // id="user-role-id"
                        // name="user-role-id"
                        onChange={(e, newVal) => {
                            setFields({ ...fields, role: newVal ? newVal._id : null, parent_id: null })
                        }}

                        sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                        renderInput={(params) => <CustomInput placeholder="Select Role*" {...params} label="Select Role*" margin="dense" />}
                    />}
                    {!isUpdate && fields.role != USER_ROLES.ADMIN && <AsyncDropDown
                        InputComponent={(props) => <CustomInput label placeholder="Select Parent Firm/Company"  {...props} />}
                        lazyFun={async (props) => {
                            return await getUserApi({ ...props, role: USER_ROLES.AUDIO_LOGIST })
                        }}
                        OptionComponent={({ option, ...rest }) => {
                            return <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} {...rest}>
                                <Typography ml={3} variant="h5">{option.name}</Typography>
                            </Box>
                        }}
                        onChange={async (changedVal) => { setFields({ ...fields, err: '', parent_id: changedVal?._id }) }}
                        titleKey={'name'}
                        valueKey={"_id"}


                    />}
                    {/* {!isUpdate && <CustomInput
                        disabled={loading}
                        value={fields.password}
                        onChange={(e) => setFields({ ...fields, err: '', password: e.target.value })}
                        type="password"
                        label={"Default Password*"}
                        margin="dense"
                    />} */}




                    {<CustomInput
                        disabled={loading}
                        value={fields.firm_name}
                        onChange={(e) => setFields({ ...fields, err: '', firm_name: e.target.value })}
                        type="text"
                        label={"Firm Name*"}

                    />}

                    <FileInput
                        key={loading}
                        onDelete={() => {
                            setFields({ ...fields, firm_logo: '' })
                        }}
                        defaults={fields.firm_logo ? [fields.firm_logo] : []}
                        title="Firm Logo"
                        subTitle="Only .png,.jpg,.jpeg  less than 2mb are valid."
                        onlyImage
                        onChange={(newUrl) => {
                            setFields({ ...fields, firm_logo: newUrl })
                        }} />


                </>}

        </CustomDialog>


    </>
}
export default memo(CreateUi)