
const TYPE_OF_HEARING_LOSS = {
    CONDUCTIVE: 1,
    SN: 2,
    MIXED: 3
}
const TYPE_OF_HEARING_LOSS_NAMES = {
    1: 'Conductive',
    2: 'SN',
    3: 'Mixed'
}
const LEVEL_OF_HEARING_LOSS = {
    MILD: 1,
    MODERATE: 2,
    SEVERE: 3,
    PROFOUND: 4
}

const DEVICES = {
    BTE: 1,
    BTE_16: 3,
    BTE_24: 5,
    BTE_32: 4,
    GLASSES: 2

}

const DEVICES_NAME = {
    1:"BTE OPTIMA",
    2:"GLASSES",
    3:"BTE PRIME-C16",
    4:"BTE PRIME ",
    5:"BTE PRIME-C24",
}

const LISTENING_SIDE = {
    LEFT: 1,
    RIGHT: 2
}
const IMPAIREMENT_TYPE = {
    UNILATERAL: 1,
    BILATERAL: 2
}

export { TYPE_OF_HEARING_LOSS, LEVEL_OF_HEARING_LOSS, TYPE_OF_HEARING_LOSS_NAMES, LISTENING_SIDE,DEVICES_NAME, DEVICES, IMPAIREMENT_TYPE }