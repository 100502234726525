import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"

import { validateEmail, validatePhone } from "../../utils/helper"
import { addPatientApi, getPatientByIdApi, updatePatientField } from "../../apis/patient.api"
import { GENDER, SNACK_BAR_VARIETNS } from "../../utils/constants"
import { LEVEL_OF_HEARING_LOSS, TYPE_OF_HEARING_LOSS } from "../../utils/patient.constants"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { addPatientProfileApi, getPatientProfileByIdApi } from "../../apis/patientprofile.api"

const CreateController = ({ callBack = () => { }, title, id, patient_id, only_view }) => {
    const validate = useValidate()
    const dispatch = useDispatch()

    const modalKey = 'patient-profile'
    const defaultTitle = title ?? `Add Patient Profile`

    const createApi = addPatientProfileApi
    const getByIdApi = getPatientProfileByIdApi

    const [loading, setLoading] = useState(false)

    const [connections, setConnection] = useState({
        left_device: false,
        right_device: false,
    })

    const [fields, setFields] = useState({
        err: '',
        id,
        left_device_id: '',
        right_device_id: '',
        f1l: 50,
        f2l: 50,
        f3l: 50,
        f4l: 50,
        f5l: 50,
        f6l: 50,
        f7l: 50,
        f8l: 50,
        f1r: 50,
        f2r: 50,
        f3r: 50,
        f4r: 50,
        f5r: 50,
        f6r: 50,
        f7r: 50,
        f8r: 50,
        patient_id

    })



    const validationSchemaForCreate = useMemo(() => ([


        {
            required: true,
            value: fields.left_device_id,
            field: 'Left Device ',

        },
        {
            required: true,
            value: fields.right_device_id,
            field: 'Right Device ',

        },
        {
            value: fields.f1l,
            field: 'F1l',
            required: true
        },
        {
            value: fields.f2l,
            field: 'F2l',
            required: true
        },
        {
            value: fields.f3l,
            field: 'F3l',
            required: true
        },
        {
            value: fields.f4l,
            field: 'F4l',
            required: true
        },
        {
            value: fields.f5l,
            field: 'F5l',
            required: true
        },
        {
            value: fields.f6l,
            field: 'F6l',
            required: true
        },
        {
            value: fields.f7l,
            field: 'F7l',
            required: true
        },
        {
            value: fields.f8l,
            field: 'F8l',
            required: true
        },

        {
            value: fields.f1r,
            field: 'F1r',
            required: true
        },
        {
            value: fields.f2r,
            field: 'F2r',
            required: true
        },
        {
            value: fields.f3r,
            field: 'F3r',
            required: true
        },
        {
            value: fields.f4r,
            field: 'F4r',
            required: true
        },
        {
            value: fields.f5r,
            field: 'F5r',
            required: true
        },
        {
            value: fields.f6r,
            field: 'F6r',
            required: true
        },
        {
            value: fields.f7r,
            field: 'F7r',
            required: true
        },
        {
            value: fields.f8r,
            field: 'F8r',
            required: true
        },

    ]), [fields])




    const createFunction = async () => {

        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true && !only_view) {
            const passData = { ...fields }


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi(passData),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(callSnackBar(defaultTitle + " Created Successfully", SNACK_BAR_VARIETNS.suceess))
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }






    const onSubmit = async (e) => {

        e.preventDefault()

        createFunction()

    }

    const fetchById = (id) => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id }),
                async (response) => {

                    if (response) {
                        const updatableData = {
                            device_type: response.device_type,
                            patient_id: response.patient_id,
                            old_left_device_id: response.left_device_id,
                            old_right_device_id: response.right_device_id
                        }
                        response.left_frequncy_values.forEach((item, index) => {
                            updatableData['f' + (index + 1) + 'l'] = item
                        })

                        response.right_frequncy_values.forEach((item, index) => {
                            updatableData['f' + (index + 1) + 'r'] = item
                        })
                        setFields({ ...fields, ...updatableData, old: true })
                    }


                    setLoading(false)

                },
                (err) => {
                    setFields({ ...fields, err })
                    setLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])


    return <CreateUi only_view={only_view} modalKey={modalKey} title={defaultTitle} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)