import { Button } from "@mui/material"
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"
import ChangeParentFirmController from "./ChangeParentFirmController"

const ChangeUserParentButton = ({id})=>{
    const dispatch = useDispatch()
    const onClick = ()=>{
        dispatch(openModal(<ChangeParentFirmController id={id} />,"sm",undefined,'change-form'))
    }
return <Button variant="contained" size="small" onClick={onClick} >
    Change Parent Firm/Company
</Button>
}
export default ChangeUserParentButton