import { People, Settings, Key, Dashboard, LocalHospital } from "@mui/icons-material"

import { Navigate } from "react-router-dom"
import AppContainer from "./components/layouts/common/AppContainer"

import SignInController from "./pages/signin/SignInController"
import UserListController from "./pages/user/ListController"
import PagenotFound from "./components/layouts/PagenotFound"
import ListController from "./pages/secrets/ListController"
import PatientListController from "./pages/patient/ListController"
import PatientProfileController from "./pages/patientprofile/PatientProfileMain"
import MODULES from "./utils/module.constant"

import NotAllowedComponent from "./components/layouts/NotAllowedComponent"
import { getDefaultRedirect } from "./utils/routinghelper"
import DashboardMainUi from "./pages/dashboard/DashboardMainUi"
import SignUpController from "./pages/signin/SignUpController"
import CreateController from "./pages/patient/CreateController"
import PatientDetailedViewCommonController from "./pages/patient/PatientDetailedViewCommonController"
import UpdatePatientDetails from "./pages/patient/UpdatePatientDetails"
import ModuleContainer from "./components/layouts/common/ModuleContainer"
import FittingMainController from "./pages/fitting/FittingMainController"
import Audiometrycreateui from "./pages/inSituAudiometry/Audiometrycreateui"
import AudioGram from "./pages/patient/AudioGram"
import InSituConnectionController from "./pages/inSituAudiometry/InSituConnectionController"

const loggedInPathElementRender = (login, allowed = [], permittedModule = [], Component, defaultRedirect, hideInPannel = false) => {

    const obj = {
        hideInPannel,
        element: Component,
    }
    if (!login) {
        obj['element'] = <Navigate replace to={'/sign-in'} />
    } else {

        let found = false
        for (let module of allowed) {
            for (let allowedModule of permittedModule) {
                if (module == allowedModule) {
                    found = true
                    break;
                }
            }
        }
        if (!found) {
            obj['hideInPannel'] = true
            obj['element'] = <NotAllowedComponent />
        }
    }
    return obj
}

const defineRoutes = (user) => {
    const allowedModules = user.data.modules ?? []
    const defaultRedirect = getDefaultRedirect(allowedModules, user.data.role)
    return ([
        {
            path: "sign-in",
            element: !user.isLoggedIn ? <SignInController /> : <Navigate replace to={defaultRedirect} />,
            hideInPannel: true

        },
       
        {
            path: "",
            element: user.isLoggedIn ? <Navigate replace to={defaultRedirect} /> : <Navigate replace to="/sign-in" />,
            hideInPannel: true

        },

        {
            path: "patient-details",
            icon: <LocalHospital />,
            title: "Patient Details",
            ...loggedInPathElementRender(user.isLoggedIn, [MODULES.PATIENT_MODULE], allowedModules, <AppContainer >


            </AppContainer>, defaultRedirect),
            children: [

                {
                    path: "",
                    title: "Patient Details",
                    icon: Key,

                    element: <PatientListController  />
                },
                {
                    path: "new-patient",
                    title: "New Patient",
                    icon: Key,

                    element: <CreateController />
                },
                {
                    path: ":id/update/",
                    title: "Patient Update",
                    icon: Key,
                    element: <UpdatePatientDetails />,
                    children: [
                        {
                            path: "",
                            title: "Patient Update",
                            icon: Key,
                            element: <></>,
                        },
                        {
                            path: ":step",
                            title: "Patient Update",
                            icon: Key,
                            element: <></>,
                        }
                    ]

                },
                {
                    path: ":id/new-fitting/",
                    title: "Device Fitting",
                    icon: Key,

                    element: <FittingMainController />
                },
                //-->
                // {
                //     // path: "new-Patient/:id/:type/audio/:step",
                // //    path: "new-Patient/:id/inSitu/:step",

                //      path: ":id/insitu-new-fitting/",
                //     title: "In Situ Audiometry",
                //     icon: Key,

                //     element: <Audiometrycreateui/>
                // },
                {
                    path: "new-Patient/:id/insitu-new-fitting/",
                    title: "In Situ Audiometry",    
                    icon: Key,

                    element: <InSituConnectionController/>
                },
                {
                    path: "new-Patient/:id/:type/:step",
                    title: "Device Fitting",
                    icon: Key,

                    element: <FittingMainController />
                },
                // {
                //     path: "new-Patient/:id",
                //     title: "Device Fitting",
                //     icon: Key,

                //     element: <AudioGram />
                // }
                ,
                //-->
                {
                    path: ":id",
                    title: "Patient View",
                    icon: Key,

                    element: <PatientDetailedViewCommonController  />
                },
                {
                    path: ":id/:fitid/fitting",
                    title: "Device Fitting",
                    icon: Key,
                        
                    element: <FittingMainController   />
                },
                {
                    path: ":id/:fitid/fitting/:readonly",
                    title: "Device Fitting",
                    icon: Key,
                        
                    element: <FittingMainController   />
                },
            ]

        },
        {
            path: "configure-device",
            icon: <LocalHospital />,
            title: "Device Configure",
            ...loggedInPathElementRender(user.isLoggedIn, [MODULES.PATIENT_MODULE], allowedModules, <AppContainer >

                <PatientProfileController />
            </AppContainer>, defaultRedirect)

        },


        {
            path: "admin",
            icon: <Settings />,
            title: "Admin Area",
            ...loggedInPathElementRender(user.isLoggedIn, [MODULES.ADMIN_AREA], allowedModules, <AppContainer />, defaultRedirect),

            children: [

                {
                    path: "secrets",
                    title: "Secrets Management",
                    icon: Key,

                    element: <ListController />
                },
                {
                    path: "users",
                    icon: People,
                    title: "Users",
                    ...loggedInPathElementRender(user.isLoggedIn, [MODULES.USER_MODULE], allowedModules, <UserListController />, defaultRedirect),
                }
            ],
        },


        {
            path: "*",
            hideInPannel: true,
            element: !user.isLoggedIn ? <Navigate replace to={"/sign-in"} /> : <AppContainer>
                <ModuleContainer>
                    <PagenotFound />
                </ModuleContainer>
            </AppContainer>,

        },
    ])
}
export default defineRoutes


/* 

================================================================
Demo Object
================================================================
        {
            path: "sign-in",
            element: !user.isLoggedIn ? <SignInController /> : <Navigate replace to="/leaves" />,
            
            title: "Leave Management", // it will use as name in side navbar
            component:<></>


             children: [
                {
                    path: "",
                    title: "My Leaves",// it will use as name in side navbar
                    element: <MyLeaveController />,
                    
                }
            ],
        },




================================================================
================================================================


*/