import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { DEVICE_SIDE } from "./audiometry.constant";
import { CenteredBox } from "../../../components/layouts/OneViewBox";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import AudiometryPlayArea from "./AudioMetryPlayArea";
import { Box, styled } from "@mui/system";
import { ButtonBase, Typography } from "@mui/material";
import CoonectDeviceModule from "../../../components/bluetooth/CoonectDeviceModule";
import { useDispatch } from "react-redux";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../../utils/constants";
import { closeModal } from "../../../store/actions/modalAction";
import { callApiAction } from "../../../store/actions/commonAction";
import { addAudiometryApi } from "../../../apis/audiometry.api";

const SelectionSide = styled(ButtonBase)(({ theme, active, disabled }) => ({
  border:
    "1px solid " +
    (active ? theme.palette.primary.main : theme.palette.primary.secondary),
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  flexDirection: "column",
  opacity: disabled ? 0.5 : 1,
  position: "relative",
  "::after": {
    content: "' '",
    position: "absolute",
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",

    opacity: 0.1,
    background: active ? theme.palette.primary.main : "unset",
    zIndex: 0,
  },
}));
const SideSelectionComponent = ({
  side,
  deviceChange,
  onConnect,
  onDisConnect,
  onLoadingChange,
  setWriteFun,
}) => {
  return (
    <CenteredBox flexDirection="column">
      <Box>
        <SelectionSide
          disabled={side.right}
          active={side.current == DEVICE_SIDE.RIGHT}
          onClick={() => {
            deviceChange(DEVICE_SIDE.RIGHT);
          }}
        >
          <Typography zIndex={1}>Right Ear</Typography>
          <Box>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="97"
              height="97"
              viewBox="0 0 97 97"
              fill="none"
            >
              <path
                d="M17 39.5364C17 39.9342 17.158 40.3157 17.4393 40.597C17.7206 40.8783 18.1022 41.0364 18.5 41.0364C18.8978 41.0364 19.2794 40.8783 19.5607 40.597C19.842 40.3157 20 39.9342 20 39.5364C20 31.9777 23.0027 24.7286 28.3475 19.3838C33.6922 14.039 40.9413 11.0364 48.5 11.0364C56.0587 11.0364 63.3078 14.039 68.6525 19.3838C73.9973 24.7286 77 31.9777 77 39.5364C77 50.1864 73.4187 53.6401 69.9537 56.9814C66.7812 60.0376 63.5 63.2026 63.5 71.0364C63.5 75.0146 61.9196 78.8299 59.1066 81.643C56.2936 84.456 52.4782 86.0364 48.5 86.0364C44.2363 86.0364 40.6888 84.2514 37.655 80.5801C37.3978 80.2856 37.0358 80.1032 36.6461 80.0717C36.2564 80.0402 35.8698 80.1621 35.5686 80.4114C35.2674 80.6607 35.0755 81.0178 35.0338 81.4066C34.992 81.7953 35.1037 82.185 35.345 82.4926C38.93 86.8351 43.3587 89.0364 48.5 89.0364C53.2724 89.0314 57.8479 87.1334 61.2224 83.7588C64.597 80.3842 66.495 75.8087 66.5 71.0364C66.5 64.4776 69.065 62.0026 72.035 59.1414C75.77 55.5414 80 51.4576 80 39.5364C80 31.1821 76.6813 23.1699 70.7739 17.2625C64.8665 11.3551 56.8543 8.03638 48.5 8.03638C40.1457 8.03638 32.1335 11.3551 26.2261 17.2625C20.3187 23.1699 17 31.1821 17 39.5364Z"
                fill="#3C508B"
                stroke="#3C508B"
                stroke-width="4"
              />
              <path
                d="M31.6895 62.0891C31.3056 61.9865 30.8967 62.0403 30.5525 62.2389C30.2087 62.438 29.9579 62.7655 29.8552 63.1494C29.7526 63.5333 29.8065 63.9422 30.005 64.2864C30.8306 65.7161 32.1048 66.8335 33.6301 67.4652C35.1554 68.097 36.8465 68.2078 38.4412 67.7805C40.0359 67.3532 41.445 66.4116 42.4501 65.1019C43.4551 63.7921 43.9999 62.1873 44 60.5364C44 55.5465 41.5674 52.298 39.2126 49.1532L39.2 49.1364L39.198 49.1337C37.0387 46.2547 35 43.5364 35 39.5364C35 35.956 36.4223 32.5222 38.9541 29.9904C41.4858 27.4587 44.9196 26.0364 48.5 26.0364C52.0804 26.0364 55.5142 27.4587 58.0459 29.9904C60.5777 32.5222 62 35.956 62 39.5364C62 39.9342 62.158 40.3157 62.4393 40.597C62.7206 40.8783 63.1022 41.0364 63.5 41.0364C63.8978 41.0364 64.2794 40.8783 64.5607 40.597C64.842 40.3157 65 39.9342 65 39.5364C65 35.1603 63.2616 30.9635 60.1673 27.8691C57.0729 24.7748 52.8761 23.0364 48.5 23.0364C44.1239 23.0364 39.9271 24.7748 36.8327 27.8691C33.7384 30.9635 32 35.1603 32 39.5364C32 44.5262 34.4326 47.7748 36.7874 50.9196L36.8 50.9364L36.802 50.9391C38.9613 53.8181 41 56.5364 41 60.5364C41.0011 61.5279 40.6747 62.492 40.0715 63.279C39.4683 64.0659 38.6221 64.6316 37.6643 64.8882C36.7066 65.1447 35.6909 65.0777 34.7751 64.6977C33.8593 64.3176 33.0947 63.6457 32.6 62.7864C32.4008 62.4425 32.0734 62.1918 31.6895 62.0891Z"
                fill="#E2B29D"
                stroke="#E2B29D"
                stroke-width="4"
              />
            </svg>
          </Box>
        </SelectionSide>

        <SelectionSide
          disabled={side.left}
          active={side.current == DEVICE_SIDE.LEFT}
          onClick={() => {
            deviceChange(DEVICE_SIDE.LEFT);
          }}
          sx={{ ml: 2 }}
        >
          <Typography>Left Ear</Typography>
          <Box sx={{ transform: "rotateY(180deg)" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="97"
              height="97"
              viewBox="0 0 97 97"
              fill="none"
            >
              <path
                d="M17 39.5364C17 39.9342 17.158 40.3157 17.4393 40.597C17.7206 40.8783 18.1022 41.0364 18.5 41.0364C18.8978 41.0364 19.2794 40.8783 19.5607 40.597C19.842 40.3157 20 39.9342 20 39.5364C20 31.9777 23.0027 24.7286 28.3475 19.3838C33.6922 14.039 40.9413 11.0364 48.5 11.0364C56.0587 11.0364 63.3078 14.039 68.6525 19.3838C73.9973 24.7286 77 31.9777 77 39.5364C77 50.1864 73.4187 53.6401 69.9537 56.9814C66.7812 60.0376 63.5 63.2026 63.5 71.0364C63.5 75.0146 61.9196 78.8299 59.1066 81.643C56.2936 84.456 52.4782 86.0364 48.5 86.0364C44.2363 86.0364 40.6888 84.2514 37.655 80.5801C37.3978 80.2856 37.0358 80.1032 36.6461 80.0717C36.2564 80.0402 35.8698 80.1621 35.5686 80.4114C35.2674 80.6607 35.0755 81.0178 35.0338 81.4066C34.992 81.7953 35.1037 82.185 35.345 82.4926C38.93 86.8351 43.3587 89.0364 48.5 89.0364C53.2724 89.0314 57.8479 87.1334 61.2224 83.7588C64.597 80.3842 66.495 75.8087 66.5 71.0364C66.5 64.4776 69.065 62.0026 72.035 59.1414C75.77 55.5414 80 51.4576 80 39.5364C80 31.1821 76.6813 23.1699 70.7739 17.2625C64.8665 11.3551 56.8543 8.03638 48.5 8.03638C40.1457 8.03638 32.1335 11.3551 26.2261 17.2625C20.3187 23.1699 17 31.1821 17 39.5364Z"
                fill="#3C508B"
                stroke="#3C508B"
                stroke-width="4"
              />
              <path
                d="M31.6895 62.0891C31.3056 61.9865 30.8967 62.0403 30.5525 62.2389C30.2087 62.438 29.9579 62.7655 29.8552 63.1494C29.7526 63.5333 29.8065 63.9422 30.005 64.2864C30.8306 65.7161 32.1048 66.8335 33.6301 67.4652C35.1554 68.097 36.8465 68.2078 38.4412 67.7805C40.0359 67.3532 41.445 66.4116 42.4501 65.1019C43.4551 63.7921 43.9999 62.1873 44 60.5364C44 55.5465 41.5674 52.298 39.2126 49.1532L39.2 49.1364L39.198 49.1337C37.0387 46.2547 35 43.5364 35 39.5364C35 35.956 36.4223 32.5222 38.9541 29.9904C41.4858 27.4587 44.9196 26.0364 48.5 26.0364C52.0804 26.0364 55.5142 27.4587 58.0459 29.9904C60.5777 32.5222 62 35.956 62 39.5364C62 39.9342 62.158 40.3157 62.4393 40.597C62.7206 40.8783 63.1022 41.0364 63.5 41.0364C63.8978 41.0364 64.2794 40.8783 64.5607 40.597C64.842 40.3157 65 39.9342 65 39.5364C65 35.1603 63.2616 30.9635 60.1673 27.8691C57.0729 24.7748 52.8761 23.0364 48.5 23.0364C44.1239 23.0364 39.9271 24.7748 36.8327 27.8691C33.7384 30.9635 32 35.1603 32 39.5364C32 44.5262 34.4326 47.7748 36.7874 50.9196L36.8 50.9364L36.802 50.9391C38.9613 53.8181 41 56.5364 41 60.5364C41.0011 61.5279 40.6747 62.492 40.0715 63.279C39.4683 64.0659 38.6221 64.6316 37.6643 64.8882C36.7066 65.1447 35.6909 65.0777 34.7751 64.6977C33.8593 64.3176 33.0947 63.6457 32.6 62.7864C32.4008 62.4425 32.0734 62.1918 31.6895 62.0891Z"
                fill="#E2B29D"
                stroke="#E2B29D"
                stroke-width="4"
              />
            </svg>
          </Box>
        </SelectionSide>
      </Box>
      <Box mt={2}>
        <CoonectDeviceModule
          onConnectWithDevice={(data, deviceInfo, disconnectFun) => {
            onConnect(data, deviceInfo, disconnectFun);
          }}
          //   Component={ConnectButton}
          onLoadingChange={(loader, message) => {
            onLoadingChange(loader, message);
          }}
          onEnableChange={(val) => {}}
          onWriteFunctionEnabled={(fun) => {
            setWriteFun(fun);
          }}
          onDisconnect={() => {
            onDisConnect();
          }}
        />
      </Box>
    </CenteredBox>
  );
};

const InsuitAudiometryMain = ({ patient_id }) => {
  const dispatch = useDispatch();
  const [connection, setConnection] = useState({
    deviceInfo: null,
    connected: false,
    loading: false,
    message: "",
    disconnectFun: undefined,
  });

  const [writeFun, setWriteFun] = useState(undefined);
  const [step, setStep] = useState(0);
  const [side, setSide] = useState({
    left: null,
    right: null,
    current: DEVICE_SIDE.LEFT,
  });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    patient_id,
    freq: [125, 250, 500, 1000, 2000, 4000, 6000],
    freqNames: [125, 250, 500, "1k", "2k", "4k", "6k"],
    left: [
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    ],
    lastLeftIndex: 0,
    lastRightIndex: 0,
    err: "",
    right: [
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    ],
  });

  const onConnect = (data, deviceInfo, disconnectFun) => {
    setConnection({
      ...connection,
      connected: true,
      deviceInfo,
      disconnectFun,
    });
  };
  const onConnectionLoadingChange = (loader, message) => {
    setConnection({ ...connection, loading: loader, message });
  };

  const onDisConnect = useCallback(() => {
    connection?.disconnectFun?.();

    setConnection({
      ...connection,
      connected: false,
      deviceInfo: null,
      disconnectFun: undefined,
    });
    dispatch(callSnackBar("Device Disconnected", SNACK_BAR_VARIETNS.error));
  }, [connection]);

  const updateData = (side, freqIndex, value) => {
    if (side == DEVICE_SIDE.LEFT) {
      const leftData = [...data.left];
      leftData[freqIndex] = value;
      const lastIndex =
        freqIndex > data.lastLeftIndex ? freqIndex : data.lastLeftIndex;
      setData({ ...data, lastLeftIndex: lastIndex, left: leftData });
    }
    if (side == DEVICE_SIDE.RIGHT) {
      const rightData = [...data.right];
      rightData[freqIndex] = value;
      const lastIndex =
        freqIndex > data.lastRightIndex ? freqIndex : data.lastRightIndex;
      setData({ ...data, lastRightIndex: lastIndex, right: rightData });
    }
  };

  const submitData = (e) => {
    e?.preventDefault();
    let err = null;
    const selectedSide = side.current == DEVICE_SIDE.LEFT ? "left" : "right";

    const audioMetryData = data[selectedSide];

    for (let item of audioMetryData) {
      if (item === undefined || item === null || isNaN(item)) {
        dispatch(
          callSnackBar(
            " All frequency must be tested",
            SNACK_BAR_VARIETNS.error
          )
        );
        break;
      }
    }

    if (err == null)
      if (step == 0) {
        setSide({
          ...side,
          [selectedSide]: true,
          current:
            side.current == DEVICE_SIDE.LEFT
              ? DEVICE_SIDE.RIGHT
              : DEVICE_SIDE.LEFT,
        });
        setStep(1);
        onDisConnect();
      } else {
        const passData = {
          patient_id: data.patient_id,
        };
        let rightIndex = 1;
        for (let item of data.right) {
          passData["insitu_rf" + rightIndex] = item;
          rightIndex++;
        }
        let leftIndex = 1;
        for (let item of data.left) {
          passData["insitu_lf" + leftIndex] = item;
          leftIndex++;
        }

        setLoading(true);
        dispatch(
          callApiAction(
            async () => await addAudiometryApi({ ...passData }),
            (response) => {
              setSide({
                ...side,
                [selectedSide]: true,
                current:
                  side.current == DEVICE_SIDE.LEFT
                    ? DEVICE_SIDE.RIGHT
                    : DEVICE_SIDE.LEFT,
              });

              dispatch(closeModal("audiometry"));
              dispatch(
                callSnackBar(
                  "Audiometry stored successfully",
                  SNACK_BAR_VARIETNS.suceess
                )
              );
              setLoading(false);
              onDisConnect();
            },
            (err) => {
              setLoading(false);
            }
          )
        );
      }
    else setData({ ...data});
  };

  const deviceChange = (side) => {
    setSide({ ...side, current: side });
  };

  // useLayoutEffect(() => {
  //     return () => {
  //         onDisConnect();
  //         return false
  //     }
  // }, [])
  return (
    <CustomDialog
      title="In-Situ-Audiometry"
      err={data.err}
      onSubmit={submitData}
      onClose={() => onDisConnect()}
      dialogProps={{ sx: { height: "1000px" } }}
      id={"audiometry"}
      hideSubmit={!connection.connected}
      disabledSubmit={!connection.connected || step == 2}
      confirmText={step == 1 ? "Submit" : "Next"}
    >
      {!connection.connected && (
        <SideSelectionComponent
          onConnect={onConnect}
          onDisConnect={onDisConnect}
          setWriteFun={setWriteFun}
          onLoadingChange={onConnectionLoadingChange}
          side={side}
          deviceChange={deviceChange}
        />
      )}
      {connection.connected && (
        <AudiometryPlayArea
          writeFun={writeFun}
          side={side}
          updateData={updateData}
          data={data}
          connection={connection}
        />
      )}
    </CustomDialog>
  );
};
export default InsuitAudiometryMain;
