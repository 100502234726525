import { combineReducers } from "@reduxjs/toolkit"
import modalReducer from "./modalReducer";
import userReducer from "./userReducer";
import notificationReducer from "./notificationReducer";
import snackBarReducer from "./snackbarReducer";
import fittingReducer from "./fittingReducer";

const rootReducer = combineReducers({
    user: userReducer,
    modal: modalReducer,    
    notifications: notificationReducer,
    fitting:fittingReducer,
    snackBar: snackBarReducer
})
export default rootReducer;