import { memo, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import {
  Box,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  styled,
  tableCellClasses,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import FittingChartArea from "./FittingChartArea";
import { closeModal } from "../../store/actions/modalAction";
import { Add } from "@mui/icons-material";
import { changeByteValue } from "../../store/actions/fittingAction";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.light,

    paddingTop: 1,
    paddingBottom: 1,
    color: theme.palette.primary.main,
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    maxWidth: "80px",
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  ".MuiSelect-root": {
    padding: "0px",
  },
  width: "70px",
  "& .MuiSelect-select": {
    minHeight: "10px",
    padding: "2px 5px",
    minWidth: "0px",

    fontSize: theme.typography.subtitle2.fontSize,
    width: "50px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ExpanseRateDropDown = (props) => {
  const dropDownArr = useMemo(
    () => [
      0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4,
      1.5, 1.6, 1.7, 1.8, 1.9, 2, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9,
      3, 3.1, 3.2, 3.3, 3.4, 3.5, 3.6, 3.7, 3.8, 3.9, 4.0,
    ],
    []
  );
  return (
    <StyledSelect fullWidth size="small" {...props}>
      {dropDownArr.map((item) => (
        <MenuItem key={item} value={item * 10}>
          {item}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};
const ExpansionKneePointDropDown = (props) => {
  const dropDownArr = useMemo(
    () => [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
      39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
      57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74,
      75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92,
      93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108,
      109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123,
      124, 125, 126, 127, 128, 129, 130,
    ],
    []
  );
  return (
    <StyledSelect fullWidth size="small" sx={{}} {...props}>
      {dropDownArr.map((item) => (
        <MenuItem key={item} value={item}>
          {item} dB
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

const LinearGainDropDown = (props) => {
  const dropDownArr = useMemo(
    () => [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
      39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
      57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74,
      75, 76, 77, 78, 79, 80,
    ],
    []
  );
  return (
    <StyledSelect fullWidth size="small" {...props}>
      {dropDownArr.map((item) => (
        <MenuItem key={item} value={item}>
          {item} dB
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

const CompressKnee = (props) => {
  const dropDownArr = useMemo(
    () => [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
      39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
      57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74,
      75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92,
      93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108,
      109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123,
      124, 125, 126, 127, 128, 129, 130,
    ],
    []
  );
  return (
    <StyledSelect fullWidth size="small" {...props}>
      {dropDownArr.map((item) => (
        <MenuItem key={item} value={item}>
          {item} dB
        </MenuItem>
      ))}
    </StyledSelect>
  );
};
const CompressionRateDropDown = (props) => {
  const dropDownArr = useMemo(
    () => [
      0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4,
      1.5, 1.6, 1.7, 1.8, 1.9, 2, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9,
      3, 3.1, 3.2, 3.3, 3.4, 3.5, 3.6, 3.7, 3.8, 3.9, 4.0,
    ],
    []
  );
  return (
    <StyledSelect fullWidth size="small" {...props}>
      {dropDownArr.map((item) => (
        <MenuItem key={item} value={item * 10}>
          {item}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

const LimitKnee = (props) => {
  const dropDownArr = useMemo(
    () => [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
      39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
      57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74,
      75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92,
      93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108,
      109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123,
      124, 125, 126, 127, 128, 129, 130,
    ],
    []
  );
  return (
    <StyledSelect fullWidth size="small" {...props}>
      {dropDownArr.map((item) => (
        <MenuItem key={item} value={item}>
          {item} dB
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

const AdvanceFittingController = ({}) => {
  const { user, fitting } = useSelector((state) => state);
  const dispatch = useDispatch();
  const onClose = (e) => {
    e.preventDefault();
    dispatch(closeModal("advance-fitting"));
  };
  return (
    <>
      <CustomDialog
        id={"advance-fitting"}
        onSubmit={onClose}
        dialogProps={{ sx: { height: "1000px", overflow: "hidden" } }}
        title={"Advance Fitting"}
        closeText={"Close"}
        hideSubmit
        confirmText={`Save`}
      >
        <Box sx={{ height: "100%", overflow: "hidden" }}>
          <Grid container sx={{ height: "100%", overflow: "hidden" }}>
            <Grid item xs={4} sx={{ overflow: "hidden", height: "100%" }}>
              <FittingChartArea />
            </Grid>
            <Grid
              item
              xs={8}
              sx={{ overflow: "hidden", height: "100%", pt: 2 }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                  border: 1,
                  borderColor: "divider",
                  borderRadius: 1,
                }}
              >
                <Box p={2} pl={3} pr={3} mt={1} mb={1}>
                  <Typography variant="h4">
                    Wide Dynamic Range Compression
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flex: 1, overflow: "hidden" }}>
                  <TableContainer sx={{ display: "flex", overflow: "auto" }}>
                    <Table sx={{ minWidth: "600px" }}>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Name</StyledTableCell>
                          <StyledTableCell>Band0</StyledTableCell>
                          <StyledTableCell>Band1</StyledTableCell>
                          <StyledTableCell>Band2</StyledTableCell>
                          <StyledTableCell>Band3</StyledTableCell>
                          <StyledTableCell>Band4</StyledTableCell>
                          <StyledTableCell>Band5</StyledTableCell>
                          <StyledTableCell>Band6</StyledTableCell>
                          <StyledTableCell>Band7</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell>
                            Cross Over Frequency
                          </StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell>317Hz</StyledTableCell>
                          <StyledTableCell>502Hz</StyledTableCell>
                          <StyledTableCell>797Hz</StyledTableCell>
                          <StyledTableCell>1264Hz</StyledTableCell>
                          <StyledTableCell>2005Hz</StyledTableCell>
                          <StyledTableCell>3181Hz</StyledTableCell>
                          <StyledTableCell>8000Hz</StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>Expansion Rate</StyledTableCell>
                          <StyledTableCell>
                            <ExpanseRateDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(20, e.target.value));
                              }}
                              value={fitting.data?.[20]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <ExpanseRateDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(21, e.target.value));
                              }}
                              value={fitting.data?.[21]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <ExpanseRateDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(22, e.target.value));
                              }}
                              value={fitting.data?.[22]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <ExpanseRateDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(23, e.target.value));
                              }}
                              value={fitting.data?.[23]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <ExpanseRateDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(24, e.target.value));
                              }}
                              value={fitting.data?.[24]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <ExpanseRateDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(25, e.target.value));
                              }}
                              value={fitting.data?.[25]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <ExpanseRateDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(26, e.target.value));
                              }}
                              value={fitting.data?.[26]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <ExpanseRateDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(27, e.target.value));
                              }}
                              value={fitting.data?.[27]}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>
                            Expansion Knee Point
                          </StyledTableCell>
                          <StyledTableCell>
                            <ExpansionKneePointDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(28, e.target.value));
                              }}
                              value={fitting.data?.[28]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <ExpansionKneePointDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(29, e.target.value));
                              }}
                              value={fitting.data?.[29]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <ExpansionKneePointDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(30, e.target.value));
                              }}
                              value={fitting.data?.[30]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <ExpansionKneePointDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(31, e.target.value));
                              }}
                              value={fitting.data?.[31]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <ExpansionKneePointDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(32, e.target.value));
                              }}
                              value={fitting.data?.[32]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <ExpansionKneePointDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(33, e.target.value));
                              }}
                              value={fitting.data?.[33]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <ExpansionKneePointDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(34, e.target.value));
                              }}
                              value={fitting.data?.[34]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <ExpansionKneePointDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(35, e.target.value));
                              }}
                              value={fitting.data?.[35]}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>Linear Gain</StyledTableCell>
                          <StyledTableCell>
                            <LinearGainDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(36, e.target.value));
                              }}
                              value={fitting.data?.[36]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <LinearGainDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(37, e.target.value));
                              }}
                              value={fitting.data?.[37]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <LinearGainDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(38, e.target.value));
                              }}
                              value={fitting.data?.[38]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <LinearGainDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(39, e.target.value));
                              }}
                              value={fitting.data?.[39]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <LinearGainDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(40, e.target.value));
                              }}
                              value={fitting.data?.[40]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <LinearGainDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(41, e.target.value));
                              }}
                              value={fitting.data?.[41]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <LinearGainDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(42, e.target.value));
                              }}
                              value={fitting.data?.[42]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <LinearGainDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(43, e.target.value));
                              }}
                              value={fitting.data?.[43]}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>Compress Knee</StyledTableCell>
                          <StyledTableCell>
                            <CompressKnee
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(44, e.target.value));
                              }}
                              value={fitting.data?.[44]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CompressKnee
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(45, e.target.value));
                              }}
                              value={fitting.data?.[45]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CompressKnee
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(46, e.target.value));
                              }}
                              value={fitting.data?.[46]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CompressKnee
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(47, e.target.value));
                              }}
                              value={fitting.data?.[47]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CompressKnee
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(48, e.target.value));
                              }}
                              value={fitting.data?.[48]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CompressKnee
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(49, e.target.value));
                              }}
                              value={fitting.data?.[49]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CompressKnee
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(50, e.target.value));
                              }}
                              value={fitting.data?.[50]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CompressKnee
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(51, e.target.value));
                              }}
                              value={fitting.data?.[51]}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>Compress Ratio</StyledTableCell>
                          <StyledTableCell>
                            <CompressionRateDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(52, e.target.value));
                              }}
                              value={fitting.data?.[52]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CompressionRateDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(53, e.target.value));
                              }}
                              value={fitting.data?.[53]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CompressionRateDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(54, e.target.value));
                              }}
                              value={fitting.data?.[54]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CompressionRateDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(55, e.target.value));
                              }}
                              value={fitting.data?.[55]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CompressionRateDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(56, e.target.value));
                              }}
                              value={fitting.data?.[56]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CompressionRateDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(57, e.target.value));
                              }}
                              value={fitting.data?.[57]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CompressionRateDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(58, e.target.value));
                              }}
                              value={fitting.data?.[58]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <CompressionRateDropDown
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(59, e.target.value));
                              }}
                              value={fitting.data?.[59]}
                            />
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>Limit Knee</StyledTableCell>
                          <StyledTableCell>
                            <LimitKnee
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(60, e.target.value));
                              }}
                              value={fitting.data?.[60]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <LimitKnee
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(61, e.target.value));
                              }}
                              value={fitting.data?.[61]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <LimitKnee
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(62, e.target.value));
                              }}
                              value={fitting.data?.[62]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <LimitKnee
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(63, e.target.value));
                              }}
                              value={fitting.data?.[63]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <LimitKnee
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(64, e.target.value));
                              }}
                              value={fitting.data?.[64]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <LimitKnee
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(65, e.target.value));
                              }}
                              value={fitting.data?.[65]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <LimitKnee
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(66, e.target.value));
                              }}
                              value={fitting.data?.[66]}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <LimitKnee
                              disabled={fitting.read_only}
                              onChange={(e) => {
                                dispatch(changeByteValue(67, e.target.value));
                              }}
                              value={fitting.data?.[67]}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CustomDialog>
    </>
  );
};
export default memo(AdvanceFittingController);
