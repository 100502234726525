import { memo } from "react"

import CreateController from "./CreateController"
import { useNavigate, useParams } from "react-router-dom"

const UpdatePatientDetails = ({  }) => {
  const params = useParams()
const navigate = useNavigate()
    return <CreateController id={params.id} callBack={()=>{
        navigate("/patient-details/"+params.id)
    }} />
}
export default memo(UpdatePatientDetails)