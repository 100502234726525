import { useState } from "react"
import { Box, Chip, CircularProgress, IconButton, Popover, Tooltip, Typography } from "@mui/material"
import { Cancel, Edit, Info, KeyboardArrowRight, QrCode, } from "@mui/icons-material"

import QRCode from "react-qr-code";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
import { CenteredBox } from "../../components/layouts/common/boxes";

export const QrCodeModal = ({ name, id }) => {

    return <CustomDialog
        id={'patient-qr'}



        title={name + "'s Qr Code"}
        closeText="Close"
    >
        <CenteredBox sx={{flexDirection:"column"}} >
            <Typography sx={{mt:3,mb:3}} >
                Scan QR code to login in the app
            </Typography>
            <Box sx={{ width: "100px" }} >


                <QRCode

                    size={100}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={id}
                    viewBox={`0 0 100 100`}
                />
            </Box>
        </CenteredBox>
    </CustomDialog>
}

export const QrCodeButton = ({ params }) => {

    const dispatch = useDispatch()
    const handleClick = (event) => {
        dispatch(openModal(<QrCodeModal name={params.first_name + " " + params.last_name} id={params._id} />, 'xs', undefined, 'patient-qr'))
    };



    return <>
        <Tooltip title="view Qr Code" >
            <IconButton variant="contained" onClick={handleClick} >
                <QrCode />
            </IconButton>

        </Tooltip>

    </>
}