import { Menu } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonBase,
  Collapse,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";

import Profile from "./Profile";

import Logo from "./Logo";
import { Link, useLocation } from "react-router-dom";
import { CenteredBox } from "../OneViewBox";
import { USER_ROLES } from "../../../utils/constants";

const headerStyle = (theme) => ({
  width: "100%",
  background: "white",
  position: "sticky",
  top: "0px",
  display: "flex",
  alignItems: "center",
  pt: 3,
  pb: 3,
  pr: 3,
  borderBottom: "1px solid " + theme.palette.grey.secondary,
  zIndex: 111,
  borderRadius: "0px",
  height: "75px",
  [theme.breakpoints.down("lg")]: {
    height: "60px",
  },
  // border-bottom: 1px solid #E5E5E6;
});
const logoContainer = (theme) => ({
  width: "100%",
  top: "0px",
  backgroundColor: theme.palette.light.main,
});
const Header = () => {
  const location = useLocation();
  const user = useSelector((state) => state.user);
  return (
    <Paper elevation={0} sx={headerStyle}>
      <CenteredBox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "95%",
            alignItems: "center",
            maxWidth: "1824px",
          }}
        >
          <Box>
            <Link to="/" sx={logoContainer}>
              <Logo />
            </Link>
          </Box>
          {/* {user.data.role==USER_ROLES.ADMIN && <Box>
                   
                        </Box>       }  */}
          <Profile />
        </Box>
      </CenteredBox>
    </Paper>
  );
};
export default Header;
