import { useDispatch, useSelector } from "react-redux";
import SubmitButton from "../../components/button/SubmitButton";
import { useState } from "react";
import {
  autofitDevice,
  insituAutofitDevice,
} from "../../store/actions/fittingAction";
import { useParams } from "react-router-dom";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";
import { Box } from "@mui/material";

export const InsituAutoFitDeviceButton = () => {
  const params = useParams();
  const { fitting } = useSelector((state) => state);
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();
  if (fitting.enabled && fitting.connected)
    return (
      <Box>
        <SubmitButton
          sx={{ height: "30px", fontSize: "20px" }}
          variant="outlined"
          title="In-Situ Auto Fit"
          loading={loading}
          onClick={() => {
            setLoading(true);
            dispatch(
              insituAutofitDevice(
                params.id,
                () => {
                  setLoading(false);
                  dispatch(
                    callSnackBar(
                      "Device fitted as per patient insitu audiogram.",
                      SNACK_BAR_VARIETNS.suceess
                    )
                  );
                },
                (err) => {
                  setLoading(false);
                  dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
                }
              )
            );
          }}
        ></SubmitButton>
      </Box>
    );
  return <></>;
};
const AutoFitDeviceButton = () => {
  const params = useParams();
  const { fitting } = useSelector((state) => state);
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();
  if (fitting.enabled && fitting.connected)
    return (
      <Box>
        <SubmitButton
          sx={{ height: "30px", fontSize: "20px" }}
          variant="outlined"
          title="Auto Fit"
          loading={loading}
          onClick={() => {
            setLoading(true);
            dispatch(
              autofitDevice(
                params.id,
                () => {
                  setLoading(false);
                  dispatch(
                    callSnackBar(
                      "Device fitted as per patient audiogram.",
                      SNACK_BAR_VARIETNS.suceess
                    )
                  );
                },
                (err) => {
                  setLoading(false);
                  dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
                }
              )
            );
          }}
        ></SubmitButton>
      </Box>
    );
  return <></>;
};

export default AutoFitDeviceButton;
