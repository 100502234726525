
import { Autocomplete, Button, ButtonBase, ButtonGroup, CircularProgress, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { useDispatch, useSelector } from "react-redux"



import HearingAidImag from '../../assets/images/aid.png'
import moment from "moment"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import NoDataComponent from "../../components/layouts/NoDataComponent"
import { openModal } from "../../store/actions/modalAction"
import CreateController from "./CreateController"



const ProfileBox = ({ date, by,id }) => {
    const disaptch = useDispatch()
    const onDetailsCLick = ()=>{
        disaptch(openModal(<CreateController title="Profile Details" only_view id={id} />,"lg",true,'patient-profile'))
    }
    const onReconfigureCLick = ()=>{
        disaptch(openModal(<CreateController title="Re Configure Device"  id={id} />,"lg",true,'patient-profile'))
    }
    return <Paper elevation={0} component={Box} p={3} sx={{ minHeight: "150px", width: "100%", border: "1px solid #000" }} >
        <Typography align="right" variant="body2">
            {date}
        </Typography>
        <CenteredBox mt={2} mb={3}>
            <img src={HearingAidImag} style={{ width: "70%", objectFit: "contain" }} alt="Hearing Aid" />
        </CenteredBox>
        <Typography align="center" variant="body1">
            By: {by}
        </Typography>

        <Box sx={{ display: "flex" }} mt={2}>
            <Button onClick={onDetailsCLick} disableElevation variant="contained" sx={{ display: "flex", flex: 1 }}>
                View Details
            </Button>
            <Button onClick={onReconfigureCLick} disableElevation variant="contained" sx={{ display: "flex", flex: 1, ml: 2 }}>
                Re&nbsp;Configure
            </Button>
        </Box>

        <Box>

        </Box>
    </Paper>
}
const CreateProfileBox = ({ onCreate }) => {
    return <ButtonBase onClick={onCreate} elevation={0} component={Box} p={3}  sx={(theme)=>({mb:4,minHeight: "50px", width: "100%", border: "1px dashed #000",background:theme.palette.secondary.main })} >
       

        <Typography variant="h6" color={"primary.main"}>Add New Profile</Typography>
    </ButtonBase>
}


const ListUi = ({ title, filters, setFilters, list, loading, onCreateBtnClick }) => {

    const user = useSelector(state => state.user)

    return (
        <>

            <Box mb={3}>
                <Paper elevation={0} sx={{ width: "100%", padding: 3 }} >
                    <Typography variant="h6" fontWeight="bold" mb={3} >Previous Profiles:</Typography>

                    
                    {loading && <Skeleton width="100%" height="250px" variant="rounded" />}
                    {!loading && <CreateProfileBox onCreate={onCreateBtnClick} />}
                    {
                        !loading && list && list.length>0 && <Grid container spacing={2}>
                            {
                                list.map((item)=>{
                                    return (
                                    <Grid key={item._id} item xs={6} md={4}>
                                        <ProfileBox id={item._id} date={moment(item.createdAt).format('DD/MM/yyyy')} by={item.created_by?.name} />
                                    </Grid>)
                               
                                })
                            }
                         </Grid>
                    }
                    {
                        !loading && list.length==0 && <CenteredBox>
                            <NoDataComponent message="No Previous Profiles Found" />
                        </CenteredBox>
                    }
                </Paper>
            </Box>

        </>
    )
}
export default ListUi