import { Box, Typography, styled, useTheme } from "@mui/material"
import { useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import BTEDevice from '../../assets/images/bte.png'
import { useSelector } from "react-redux"
import { DEVICES, DEVICES_NAME, LISTENING_SIDE } from "../../utils/patient.constants";
import { WDRC_circuit_gain } from "../../utils/j10.helper";
import { findMediun, findMediunsInBulk } from "../../utils/helper";


const ChartComponent = ({fitting}) => { 
  const createData = (fittingData, deviceType) => {
  
    const defaultDSummyValueSet = [0.6, 48, 70, 2, 100]
  
    const data =fitting.inSitudB;
  
    const round = (deviceType == DEVICES.BTE_32 || deviceType == DEVICES.BTE_24) ? 2 : (deviceType == DEVICES.BTE_16 ? 1 : 0)  
    const startVal = 20
  
  
    const temp = [
        [], [], [], [], [], []
    ]
    for (let i = 0; i < 8; i++) {
        temp[0].push(fittingData[startVal + 0 + i])
        temp[1].push(fittingData[startVal + 8 + i])
        temp[2].push(fittingData[startVal + 16 + i])
        temp[3].push(fittingData[startVal + 24 + i])
        temp[4].push(fittingData[startVal + 32 + i])
        temp[5].push(fittingData[startVal + 40 + i])
    }
  
  
    const finalValArray = [
  
        findMediunsInBulk(temp[0], defaultDSummyValueSet[0], round),
        findMediunsInBulk(temp[1], defaultDSummyValueSet[1], round),
        findMediunsInBulk(temp[2], defaultDSummyValueSet[2], round),
        findMediunsInBulk(temp[3], defaultDSummyValueSet[3], round),
        findMediunsInBulk(temp[4], defaultDSummyValueSet[4], round),
        findMediunsInBulk(temp[5], defaultDSummyValueSet[5], round)
  
    ]
    if (deviceType == DEVICES.BTE_24) {
  
        for (let i = 0; i < finalValArray.length; i++) {
            // for (let subArr of finalValArray[i]) {
  
            const temp = [...finalValArray[i]]
  
            const newArray = temp.filter((item, index) => ((index + 1) % 4) != 0 )
            finalValArray[i] = newArray
  
            // }
  
        }
    }
  
    for (let i = 0; i < finalValArray[0].length; i++) {
        const val = WDRC_circuit_gain(
            finalValArray[0][i]/10,
            finalValArray[1][i],
            finalValArray[2][i],
            finalValArray[3][i],
            finalValArray[4][i]/10,
            finalValArray[5][i],
        )
  
        data.push(val[0])
  
    }
  
    return data
  }
  const labels =["125", "250", "500", "1K", "2K", "4K", "6K"];

  const dataFields = useMemo(
    () => ({
      labels,
      datasets: [
        {
          label: "" ,
          data: createData(40, fitting.data, fitting.device_type),
          borderColor: "blue",
        }
      ],
    }),
    [fitting.data, fitting.device_type]
  );

  return (
    <Line
      width="100%"
      height="100%"
      options={{
        interaction: {
          mode: "index",
        },
        plugins: {
          datalabels: {
            display: 0,
            backgroundColor: "red",
          },
          tooltip: {
            enabled: false,

            // <-- this option disables tooltips
        },
          legend: {
            display:false
            // enabled: false,
            // color: "red",
            // position: "bottom",
            // labels: {
            //   // color:"red"
            // },
          },
        },

        responsive: true,
        animation: false,

        bezierCurve: true,
        elements: {},
        spanGaps: 1,
        aspectRatio: 1.5,
        scales: {
          y: {
            title: {
              display: true,
              text: "Gain",
              fontWeight: "600",
              color: "#3C508B",
            },
            max: 100,
            min: 0,
            suggestedMax: 0,
            suggestedMin: 80,
          },
          x: {
            title: {
              display: true,
              text: "Frequency",
              fontWeight: "6001",
              color: "#3C508B",
            },
            
          },
        },

        onClick: (e, ele, chart) => {},
        onHover: (e, ele, chart) => {},
      }}
      data={dataFields}
    />
  );
};

const BorderSquareChip = styled(Typography)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: '1px solid ' + theme.palette.success.main,
  color: theme.palette.success.main,
  padding: theme.spacing(2),
  background: theme.palette.success.light,
}))


const Audiometrycreateuileft = ({fitting}) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }} p={2}>
        <img src={BTEDevice} style={{ height: "24px", width: "24px" }} />
        <Typography variant="h4" fontWeight={500} color="dark" pl={2}>
          {DEVICES_NAME[fitting.device_type] ?? "Hear Nu"} -
        </Typography>
        <Typography variant="h4" fontWeight={500} color="grey" pl={2}>
          {fitting?.deviceInfo?.name} -
        </Typography>
        &nbsp;
        <BorderSquareChip variant="subtitle1">
          {fitting.device_side === LISTENING_SIDE.LEFT ? "Left" : "Right"}{" "}
          Hearing Aid
        </BorderSquareChip>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          overflow: "hidden",
        }}
        p={2}
      >
        <ChartComponent fitting={fitting} ></ChartComponent>
      </Box>
    </Box>
  );
};

export default Audiometrycreateuileft;
