import { Box, Typography, styled, useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import BTEDevice from "../../assets/images/bte.png";
import { useSelector } from "react-redux";
import {
  DEVICES,
  DEVICES_NAME,
  LISTENING_SIDE,
} from "../../utils/patient.constants";
import { WDRC_circuit_gain } from "../../utils/j10.helper";
import { findMediun, findMediunsInBulk } from "../../utils/helper";

const BorderSquareChip = styled(Typography)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: "1px solid " + theme.palette.success.main,
  color: theme.palette.success.main,
  padding: theme.spacing(2),
  background: theme.palette.success.light,
}));

const createData = (envDb, fittingData, deviceType, edgeVal) => {
  const defaultDSummyValueSet = [0.6, 48, edgeVal, 70, 2, 100];

  const data = [];

  const round =
    deviceType == DEVICES.BTE_32 || deviceType == DEVICES.BTE_24
      ? 2
      : deviceType == DEVICES.BTE_16
      ? 1
      : 0;

  // for (let i = 0; i < 8; i++) {

  //     const startVal = 20

  //     const val = WDRC_circuit_gain(
  //         envDb,
  //         fittingData[startVal + 0 + i],
  //         fittingData[startVal + 8 + i],
  //         fittingData[startVal + 16 + i],
  //         fittingData[startVal + 24 + i],
  //         fittingData[startVal + 32 + i],
  //         fittingData[startVal + 40 + i],
  //     )

  //     data.push(val[0] - envDb)
  // }

  const startVal = 20;

  const temp = [[], [], [], [], [], []];
  for (let i = 0; i < 8; i++) {
    temp[0].push(fittingData[startVal + 0 + i]);
    temp[1].push(fittingData[startVal + 8 + i]);
    temp[2].push(fittingData[startVal + 16 + i]);
    temp[3].push(fittingData[startVal + 24 + i]);
    temp[4].push(fittingData[startVal + 32 + i]);
    temp[5].push(fittingData[startVal + 40 + i]);
  }

  const finalValArray = [
    findMediunsInBulk(temp[0], defaultDSummyValueSet[0], round),
    findMediunsInBulk(temp[1], defaultDSummyValueSet[1], round),
    findMediunsInBulk(temp[2], defaultDSummyValueSet[2], round),
    findMediunsInBulk(temp[3], defaultDSummyValueSet[3], round),
    findMediunsInBulk(temp[4], defaultDSummyValueSet[4], round),
    findMediunsInBulk(temp[5], defaultDSummyValueSet[5], round),
  ];
  if (deviceType == DEVICES.BTE_24) {
    for (let i = 0; i < finalValArray.length; i++) {
      // for (let subArr of finalValArray[i]) {

      const temp = [...finalValArray[i]];

      const newArray = temp.filter((item, index) => (index + 1) % 4 != 0);
      finalValArray[i] = newArray;

      // }
    }
  }

  for (let i = 0; i < finalValArray[0].length; i++) {
    const val = WDRC_circuit_gain(
      envDb,
      finalValArray[0][i] / 10,
      finalValArray[1][i],
      finalValArray[2][i],
      finalValArray[3][i],
      finalValArray[4][i] / 10,
      finalValArray[5][i]
    );

    data.push(val[0] - envDb);
  }
//   if (data.length > 30) {
//     data[29] = data[28] + 1;
//     data[30] = data[27] + 1;
//     data[31] = data[26] + 1;
//   }
console.log("this is final array",data);
  return data;
};
const ChartComponent = ({ edgeVal }) => {
  const { fitting } = useSelector((state) => state);

  //   console.log("this is edgeVal",edgeVal)
  //   const { fitting } = useSelector(state => state)

  const labels = useMemo(() => {
    if (fitting.device_type == DEVICES.BTE_16)
      return [
        "125",
        "221",
        "317",
        "410",
        "502",
        "650",
        "797",
        "1031",
        "1264",
        "1635",
        "2005",
        "2593",
        "3181",
        "4113",
        "5044",
        "8000",
      ];
    else if (fitting.device_type == DEVICES.BTE_24)
      return [
        "125",
        "189",
        "253",
        "317",
        "379",
        "441",
        "502",
        "601",
        "700",
        "797",
        "953",
        "1109",
        "1264",
        "1511",
        "1758",
        "2005",
        "2397",
        "2789",
        "3181",
        "3802",
        "4423",
        "5044",
        "6522",
        "8000",
      ];
    else if (fitting.device_type == DEVICES.BTE_32)
      return [
        "125",
        "173",
        "221",
        "269",
        "317",
        "364",
        "410",
        "456",

        "502",
        "576",
        "650",
        "723",
        "797",
        "914",
        "1031",
        "1148",

        "1264",
        "1450",
        "1635",
        "1820",
        "2005",
        "3000",
        "2593",
        "2860",

        "3181",
        "3647",
        "4113",
        "4578",
        "5044",
        "6522",
        "7015",
        "8000",
      ];
    else return ["125", "317", "502", "797", "1264", "2005", "3181", "5044"];
  }, [fitting.device_type]);

  const dataFields = useMemo(
    () => ({
      labels,
      datasets: [
        {
          label: "40dB",
          data: createData(40, fitting.data, fitting.device_type, edgeVal),

          borderColor: "blue",
        },
        {
          label: "65dB",
          data: createData(65, fitting.data, fitting.device_type, edgeVal),

          borderColor: "red",
        },
        {
          label: "95dB",
          data: createData(95, fitting.data, fitting.device_type, edgeVal),
          // fill: "start",
          borderColor: "green",
          // pointBackgroundColor: "white",
          // backgroundColor: (context) => {
          //     // const ctx = context.chart.ctx;
          //     // const gradient = ctx.createLinearGradient(50, 0, 0, 200);
          //     // gradient.addColorStop(0, "rgba(250,174,50,0.1)");
          //     // gradient.addColorStop(1, "rgba(250,174,50,0.1)");
          //     // return gradient;
          // },
        },
      ],
    }),
    [fitting.data, fitting.device_type, edgeVal]
  );

  return (
    <Line
      width="100%"
      height="100%"
      options={{
        interaction: {
          mode: "index",
        },
        plugins: {
          datalabels: {
            display: false,
            backgroundColor: "red",
          },

          legend: {
            enabled: false,
            color: "red",
            position: "bottom",
            labels: {
              // color:"red"
            },
          },
        },

        responsive: true,
        animation: false,

        bezierCurve: true,
        elements: {
          // line: {
          //     borderWidth: 1,
          //     tension: 0.5,
          //     // borderColor: "#00F",
          //     hoverBorderColor: title == "Right Ear" ? "#E62323" : "#3C508B",
          //     borderColor: title == "Right Ear" ? "#E62323" : "#3C508B",
          // },
          // point: {
          //     pointStyle: title == "Right Ear" ? "circle" : "crossRot",
          //     hoverBorderColor: title == "Right Ear" ? "#E62323" : "#3C508B",
          //     borderColor: title == "Right Ear" ? "#E62323" : "#3C508B",
          //     radius: title == "Right Ear" ? 6 : 10,
          //     backgroundColor: "white",
          //     hoverBackgroundColor: "white",
          //     hoverRadius: title == "Right Ear" ? 8 : 12,
          //     borderWidth: title == "Right Ear" ? 1 : 3,
          //     hoverBorderWidth: title == "Right Ear" ? 1 : 3,
          // },
        },
        spanGaps: 1,
        aspectRatio: 1.5,
        scales: {
          y: {
            title: {
              display: true,
              text: "Gain",
              fontWeight: "600",
              color: "#3C508B",
            },
            max: 80,
            min: 0,
            suggestedMax: 0,
            suggestedMin: 80,
            // ticks: {
            //     callback: function (value, index, ticks) {

            //         return -1 * (value)
            //     }
            // }
          },
          x: {
            title: {
              display: true,
              text: "Frequency",
              fontWeight: "6001",
              color: "#3C508B",
            },
          },
        },

        onClick: (e, ele, chart) => {},
        onHover: (e, ele, chart) => {},
      }}
      data={dataFields}
    />
  );
};

const FittingChartArea = ({ edgeVal }) => {
  const { fitting } = useSelector((state) => state);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }} p={2}>
        <img src={BTEDevice} style={{ height: "24px", width: "24px" }} />
        <Typography variant="h4" fontWeight={500} color="dark" pl={2}>
          {DEVICES_NAME[fitting.device_type] ?? "Hear Nu"} -
        </Typography>
        <Typography variant="h4" fontWeight={500} color="grey" pl={2}>
          {fitting?.deviceInfo?.name} -
        </Typography>
        &nbsp;
        <BorderSquareChip variant="subtitle1">
          {fitting.device_side === LISTENING_SIDE.LEFT ? "Left" : "Right"}{" "}
          Hearing Aid
        </BorderSquareChip>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          overflow: "hidden",
        }}
        p={2}
      >
        <ChartComponent edgeVal={edgeVal}></ChartComponent>
      </Box>
    </Box>
  );
};

export default FittingChartArea;
