import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import CreateUi from "./CreateUi";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";

import { closeModal } from "../../store/actions/modalAction";
import { useNavigate, useParams } from "react-router-dom";
import { openModal } from "../../store/actions/modalAction";

import { calculateAge, validateEmail, validatePhone } from "../../utils/helper";
import {
  addPatientApi,
  getPatientByIdApi,
  updatePateintAudioGram,
  updatePatientField,
} from "../../apis/patient.api";
import { GENDER, SNACK_BAR_VARIETNS } from "../../utils/constants";
import {
  IMPAIREMENT_TYPE,
  LEVEL_OF_HEARING_LOSS,
  TYPE_OF_HEARING_LOSS,
} from "../../utils/patient.constants";
import { callSnackBar } from "../../store/actions/snackbarAction";
import moment from "moment";
import InSituConnectionController from "../inSituAudiometry/InSituConnectionController";

const CreateController = ({ callBack = () => { }, id }) => {
  const validate = useValidate();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const modalKey = "patient";
  const title = "Patients ";
  const createApi = addPatientApi;

  const getByIdApi = getPatientByIdApi;

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(params?.step === "audiogram" ? 1 : 0);
  const [create, setCreate] = useState(false);
  console.log("params", params);
  const updateApi = step == 0 ? updatePatientField : updatePateintAudioGram;
  const inSituAudiometry = () => {
    dispatch(
      openModal(
        <InSituConnectionController />,
        "xl",
        true,
        "in-situ-connection"
      )
    );
  };

  const [fields, setFields] = useState({
    err: "",
    id,
    first_name: "",
    middle_name: "",
    last_name: "",
    email: null,
    phone: "",
    country_code: "+91",
    dob: moment().toISOString(),
    age: calculateAge(moment().toISOString()),
    address: "",
    gender: GENDER.MALE,
    type_of_loss: TYPE_OF_HEARING_LOSS.CONDUCTIVE,
    level_of_loss: LEVEL_OF_HEARING_LOSS.MILD,
    impairment_type: IMPAIREMENT_TYPE.UNILATERAL,
    left_ear_loss: 0,
    right_ear_loss: 0,
    allowed_ac: true,
    allowed_bc: true,
    allowed_aided: false,
    lf1: 50,
    lf2: 50,
    lf3: 50,
    lf4: 50,
    lf5: 50,
    lf6: 50,
    lf7: 50,
    lf8: 50,
    rf1: 50,
    rf2: 50,
    rf3: 50,
    rf4: 50,
    rf5: 50,
    rf6: 50,
    rf7: 50,
    rf8: 50,

    blf1: 40,
    blf2: 40,
    blf3: 40,
    blf4: 40,
    blf5: 40,
    blf6: 40,
    blf7: 40,
    blf8: 40,
    brf1: 40,
    brf2: 40,
    brf3: 40,
    brf4: 40,
    brf5: 40,
    brf6: 40,
    brf7: 40,
    brf8: 40,

    aided_lf1: -1,
    aided_lf2: -1,
    aided_lf3: -1,
    aided_lf4: -1,
    aided_lf5: -1,
    aided_lf6: -1,
    aided_lf7: -1,
    aided_lf8: -1,
    aided_rf1: -1,
    aided_rf2: -1,
    aided_rf3: -1,
    aided_rf4: -1,
    aided_rf5: -1,
    aided_rf6: -1,
    aided_rf7: -1,
    aided_rf8: -1,
    assigned: null
  });
  const [originalDocument, setOriginalDocument] = useState({});

  const validationSchemaForCreate = useMemo(
    () => [
      [
        {
          required: true,
          value: fields.first_name,
          field: "First Name",
        },
        {
          required: true,
          value: fields.phone,
          field: "Phone No.",
        },

        {
          value: fields.email,
          field: "Email",
          custom: () => {
            if (
              fields.email &&
              fields.email != "" &&
              !validateEmail(fields.email)
            ) {
              return "Email is invalid";
            }
            return true;
          },
        },
        {
          required: true,
          value: fields.dob,
          field: "Date of Birth",
        },
        {
          required: true,
          value: fields.address,
          field: "Address",
        },
      ],
      [
        {
          field: "Left",
          custom: () => {
            for (let i of [1, 2, 3, 4, 5, 6, 7, 8]) {
              for (let j of ["l", "r"]) {
                const freq = j + "f" + i;
                if (
                  !fields[freq] ||
                  isNaN(fields[freq]) ||
                  fields[freq] < 0 ||
                  fields[freq] > 120
                ) {
                  return (
                    "All " +
                    (j == "l" ? "Left" : "Right") +
                    " Ear  Threshold should be between 0 to 12  0"
                  );
                }
              }
            }

            return true;
          },
        },
      ],
      [
        {
          field: "Left",
          custom: () => {
            for (let i of [1, 2, 3, 4, 5, 6, 7, 8]) {
              for (let j of ["l", "r"]) {
                const freq = "b" + j + "f" + i;
                if (
                  !fields[freq] ||
                  isNaN(fields[freq]) ||
                  fields[freq] < 0 ||
                  fields[freq] > 120
                ) {
                  return (
                    "All " +
                    (j == "l" ? "Left" : "Right") +
                    " Ear  Threshold should be between 0 to 120"
                  );
                }
              }
            }

            return true;
          },
        },
      ],
    ],
    [fields]
  );

  const validationSchemaForUpdate = useMemo(
    () => [
      [
        {
          required: true,
          value: fields.first_name,
          field: "First Name",
        },
        {
          required: true,
          value: fields.phone,
          field: "Phone No.",
        },

        {
          value: fields.email,
          field: "Email",
          custom: () => {
            if (
              fields.email &&
              fields.email != "" &&
              !validateEmail(fields.email)
            ) {
              return "Email is invalid";
            }
            return true;
          },
        },
        {
          required: true,
          value: fields.dob,
          field: "Date Of Birth",
        },
        {
          required: true,
          value: fields.address,
          field: "Address",
        },
      ],
      [
        {
          field: "Left",
          custom: () => {
            for (let i of [1, 2, 3, 4, 5, 6, 7, 8]) {
              for (let j of ["l", "r"]) {
                const freq = j + "f" + i;
                if (
                  !fields[freq] ||
                  isNaN(fields[freq]) ||
                  fields[freq] < 0 ||
                  fields[freq] > 120
                ) {
                  return (
                    "All " +
                    (j == "l" ? "Left" : "Right") +
                    " Ear  Threshold should be between 0 to 120"
                  );
                }
              }
            }

            return true;
          },
        },
      ],
      [
        {
          field: "Left",
          custom: () => {
            for (let i of [1, 2, 3, 4, 5, 6, 7, 8]) {
              for (let j of ["l", "r"]) {
                const freq = "b" + j + "f" + i;
                if (
                  !fields[freq] ||
                  isNaN(fields[freq]) ||
                  fields[freq] < 0 ||
                  fields[freq] > 120
                ) {
                  return (
                    "All " +
                    (j == "l" ? "Left" : "Right") +
                    " Ear  Threshold should be between 0 to 120"
                  );
                }
              }
            }

            return true;
          },
        },
      ],
    ],
    [fields]
  );

  const createFunction = async () => {
    const validationResponse = validate(validationSchemaForCreate[step]);

    if (validationResponse === true) {
      if (step == 0) {
        setFields({ ...fields, err: "" });
      }

      const passData = { ...fields };
      if (!fields.email && fields.email == "") {
        delete passData["email"];
      }

      setLoading(true);
      dispatch(
        callApiAction(
          async () => await createApi(passData),
          async (response) => {
            await callBack(response);
            setLoading(false);

            setFields({ ...fields, id: response?._id });
            if (step == 0) {
              setStep(1);
              setCreate(true);
            }
            dispatch(
              callSnackBar(
                title + " Created Successfully",
                SNACK_BAR_VARIETNS.suceess
              )
            );
            // dispatch(closeModal(modalKey));
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const updateFunction = async () => {
    const validationResponse = validate(validationSchemaForUpdate[step]);
    let updatedData = { id };

    for (let field in fields) {
      if (
        originalDocument[field] &&
        fields[field] &&
        fields[field] != originalDocument[field]
      ) {
        updatedData[field] = fields[field];
      }
      if (!originalDocument[field]) {
        updatedData[field] = fields[field];
      }
    }

    updatedData["allowed_ac"] = fields["allowed_ac"];
    updatedData["allowed_bc"] = fields["allowed_bc"];
    updatedData["allowed_aided"] = fields["allowed_aided"];
    if (validationResponse === true) {
      if (step == 0 && !id) {
        setStep(1);
        setFields({ ...fields, err: "" });
        return;
      }
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await updateApi(updatedData),
          async (response) => {
            await callBack(response, updatedData);
            setLoading(false);
            dispatch(
              callSnackBar(
                title + " Updated Successfully",
                SNACK_BAR_VARIETNS.suceess
              )
            );
            dispatch(closeModal(modalKey));
            console.log("step", step);
            if (!create) {
              navigate(`/patient-details/${id}`);
            } else {
              navigate("/patient-details");
            }
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (fields.id) {
      await updateFunction();
      // navigate("/patient-details");
    } else {
      createFunction();
    }
  };

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getByIdApi({ id }),
        async (response) => {
          setFields({
            ...fields,
            ...response,
            id: response._id,
            age: moment(response.dob).toISOString(),
          });
          setOriginalDocument(response);
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) fetchById(id);
  }, [id]);

  return (
    <CreateUi
      setStep={setStep}
      step={step}
      inSituAudiometry={inSituAudiometry}
      modalKey={modalKey}
      title={title}
      isUpdate={id}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  );
};
export default memo(CreateController);
