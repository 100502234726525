import ModuleContainer from "../../components/layouts/common/ModuleContainer";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import { CenteredBox } from "../../components/layouts/common/boxes";
import BTEDevice from "../../assets/images/bte.png";
import LeftGlasses from "../../assets/images/left-glasses.png";
import { useDispatch, useSelector } from "react-redux";
import {
  DEVICES,
  DEVICES_NAME,
  LISTENING_SIDE,
} from "../../utils/patient.constants";
import NoDataComponent from "../../components/layouts/NoDataComponent";
import { Circle, Error } from "@mui/icons-material";
import CoonectDeviceModule from "../../components/bluetooth/CoonectDeviceModule";
import {
  applyForRemoteFitting,
  changeDeviceCompatiblity,
  changeDeviceSelection,
  changeDeviceSideSelection,
  changeFittingData,
  changeLoadinfMessages,
  connectDevice,
  disconnectDevice,
  onWriteFunctionChange,
  resetDevice,
  resetToDefault,
} from "../../store/actions/fittingAction";
import SubmitButton from "../../components/button/SubmitButton";

import BasicFittingAreaController from "./BasicFittingAreaController";
import AutoFitDeviceButton, {
  InsituAutoFitDeviceButton,
} from "./AutoFitDeviceButton";
import { findObjectKeyByValue } from "../../utils/helper";
import { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getPatientProfileByIdApi } from "../../apis/patientprofile.api";
import { callApiAction } from "../../store/actions/commonAction";
import Audiometrycreateui from "../inSituAudiometry/Audiometrycreateui";

const DeviceSideSelection = ({
  selected,
  title,
  onClick,
  rotated,
  img,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Box
      onClick={onClick}
      component={ButtonBase}
      sx={{
        borderRadius: 1,
        maxHeight: "350px",
        maxWidth: "350px",
        boxShadow: selected ? 8 : 0,
        display: "flex",
        position: "relative",
        aspectRatio: 1,
        height: "100%",
        flexDirection: "column",
        border:
          "2px solid " +
          (selected
            ? theme.palette.success.main
            : theme.palette.primary.secondary),
      }}
      p={3}
      {...props}
    >
      <Typography
        fontWeight={500}
        variant="h6"
        color={selected ? "primary" : "gray"}
        mb={2}
      >
        {" "}
        {title == "L" ? "Left" : "Right"} Device{" "}
      </Typography>
      <Box sx={{ display: "flex", flex: 1, width: "100%", overflow: "hidden" }}>
        <img
          src={img ?? BTEDevice}
          style={{
            height: "100%",
            transform: rotated ? "rotateY(180deg)" : "rotateY(0deg)",
            width: "100%",
            objectFit: "contain",
            opacity: selected ? 1 : 0.4,
          }}
        />
      </Box>
      <CenteredBox mt={2} mb={2}>
        <Avatar
          sx={{
            background: selected
              ? theme.palette.primary.main
              : theme.palette.primary.secondary,
          }}
          color="primary.main"
        >
          {title}
        </Avatar>
      </CenteredBox>
    </Box>
  );
};

const DeviceSelection = ({ disabled }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { fitting } = useSelector((state) => state);
  const params = useParams();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDeviceSelect = (device) => {
    dispatch(changeDeviceSelection(device));
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <CenteredBox>
      {!params.type && (
        <>
          <Box
            disabled={disabled}
            component={ButtonBase}
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
            p={2}
            pr={3}
            pl={3}
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid" + theme.palette.primary.secondary,
              borderRadius: 1,
            }}
          >
            <Typography
              variant="h4"
              fontWeight={400}
              color="grey"
              mr={2}
              pr={2}
              sx={{
                borderRight: "1px solid" + theme.palette.primary.secondary,
              }}
            >
              Device
            </Typography>
            <img
              src={
                fitting.device_type == DEVICES.GLASSES ? LeftGlasses : BTEDevice
              }
              style={{ height: "32px", width: "32px", objectFit: "contain" }}
            />
            <Typography variant="h4" fontWeight={600} color="primary" pl={2}>
              {
                // findObjectKeyByValue(fitting.device_type, DEVICES)
                DEVICES_NAME[fitting.device_type]
              }
            </Typography>
          </Box>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <MenuList sx={{ width: anchorEl?.clientWidth ?? "100%" }}>
              <MenuItem
                disabled={disabled}
                onClick={() => {
                  onDeviceSelect(DEVICES.BTE);
                }}
              >
                BTE Optima
              </MenuItem>
              {/* <MenuItem
                disabled={disabled}
                onClick={() => {
                  onDeviceSelect(DEVICES.BTE_16);
                }}
              >
                BTE Prime-C16
              </MenuItem>
              <MenuItem
                disabled={disabled}
                onClick={() => {
                  onDeviceSelect(DEVICES.BTE_24);
                }}
              >
                BTE Prime-C24
              </MenuItem> */}
              <MenuItem
                disabled={disabled}
                onClick={() => {
                  onDeviceSelect(DEVICES.BTE_32);
                }}
              >
                BTE Prime
              </MenuItem>
              {/* <MenuItem
                disabled={disabled}
                onClick={() => {
                  onDeviceSelect(DEVICES.GLASSES);
                }}
              >
                Glasses
              </MenuItem> */}
            </MenuList>
          </Popover>
        </>
      )}
    </CenteredBox>
  );
};
const ConnectButton = ({ loading, connected, onClick, disconnect }) => {
  if (connected) {
    return (
      <SubmitButton
        loading={loading}
        disabled={loading}
        disableElevation
        variant="contained"
        onClick={disconnect}
        title={"Disconnect"}
      />
    );
  }
  return (
    <SubmitButton
      loading={loading}
      disabled={loading}
      disableElevation
      variant="contained"
      onClick={onClick}
      title={"Connect"}
    />
  );
};

const ConncetionScreen = ({ show, disabled }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const params = useParams();

  const { fitting } = useSelector((state) => state);
  useEffect(() => {
    return () => {
      if (fitting.connected && fitting?.writeFun && !fitting.read_only) {
        dispatch(disconnectDevice());
      } else {
        dispatch(resetToDefault());
      }
    };
  }, []);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flex: 1,
          overflow: "hidden",
          flexDirection: "column",
          display: show ? "flex" : "none",
        }}
        p={3}
      >
        <DeviceSelection disabled={disabled} />
        <CenteredBox
          p={5}
          sx={{ display: "flex", overflow: "hidden", flex: 1 }}
        >
          <DeviceSideSelection
            disabled={disabled}
            img={
              fitting.device_type == DEVICES.GLASSES ? LeftGlasses : BTEDevice
            }
            onClick={() => {
              dispatch(changeDeviceSideSelection(LISTENING_SIDE.RIGHT));
            }}
            selected={fitting.device_side == LISTENING_SIDE.RIGHT}
            title={"R"}
          />
          <Box sx={{ width: "30px", height: "100%" }} />
          <DeviceSideSelection
            disabled={disabled}
            img={
              fitting.device_type == DEVICES.GLASSES ? LeftGlasses : BTEDevice
            }
            onClick={() => {
              dispatch(changeDeviceSideSelection(LISTENING_SIDE.LEFT));
            }}
            selected={fitting.device_side == LISTENING_SIDE.LEFT}
            title={"L"}
            rotated
          />
        </CenteredBox>
        <CenteredBox pb={4} sx={{ flexDirection: "column" }}>
          <Typography fontWeight={500} color="gray" variant="h6" mt={2} mb={2}>
            {fitting.loadingMessage}
          </Typography>
          <Box>
            <CoonectDeviceModule
              onConnectWithDevice={(data, deviceInfo, disconnectFun) => {
                dispatch(connectDevice(data, deviceInfo, disconnectFun));
              }}
              Component={ConnectButton}
              onLoadingChange={(loader, message) => {
                dispatch(changeLoadinfMessages(loader, message));
              }}
              onEnableChange={(val) => dispatch(changeDeviceCompatiblity(val))}
              onWriteFunctionEnabled={(fun) =>
                dispatch(onWriteFunctionChange(fun))
              }
              onDisconnect={() => dispatch(disconnectDevice())}
            />
          </Box>
          {!params.type && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button
                sx={{ p: 0, textDecoration: "underline" }}
                onClick={() => {
                  if (!params.fitid) {
                    dispatch(resetDevice());
                  }
                  dispatch(applyForRemoteFitting());
                }}
              >
                <Typography variant="subtitle1">Remote Fitting</Typography>
              </Button>
            </Box>
          )}
        </CenteredBox>
      </Box>
    </>
  );
};
const HeaderComponent = () => {
  return (
    <>
      <InsituAutoFitDeviceButton />
      <AutoFitDeviceButton />
    </>
  );
};
const FittingMainController = () => {
  const dispatch = useDispatch();
  const { fitting } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const [step, setStep] = useState();

  useEffect(() => {
    if (params.step) {
      setStep(parseInt(params.step) + 1);
    } else {
      setStep(0);
    }
  }, []);

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getPatientProfileByIdApi({ id }),
        async (response) => {
          if (response["_id"]) {
            dispatch(changeFittingData(response, params.readonly));
          }

          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  useEffect(() => {
    if (params.fitid) {
      fetchById(params.fitid);
    }
  }, [params.fitid]);
  return (
    <ModuleContainer
      HeaderComponent={!fitting.read_only ? HeaderComponent : undefined}
    >
      {loading && (
        <CenteredBox>
          <CircularProgress />
        </CenteredBox>
      )}
      {!loading && fitting.enabled === false && (
        <NoDataComponent
          message={"Your browser does'nt support this software."}
          Icon={Error}
        />
      )}
      {
        <ConncetionScreen
          disabled={params.fitid && params.fitid != ""}
          show={!fitting.connected && fitting.enabled !== false && !loading}
        />
      }
      {fitting.connected && fitting.enabled && !loading && (
        <>
          <BasicFittingAreaController />
        </>
      )}
    </ModuleContainer>
  );
};

export default FittingMainController;
