import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Add, Remove } from "@mui/icons-material";
import { Box, ButtonBase, styled } from "@mui/material";
import { memo } from "react";
import SubmitButton from "../../components/button/SubmitButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Paper from "@mui/material/Paper";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Mark_value } from "../../store/actions/fittingAction";
const Audiometrycreateuiright = () => {
  const { fitting } = useSelector((state) => state);

  console.log("fittingInfo", fitting);
  const dispatch = useDispatch();
  const [value, setValue] = useState(100);
  const [loading, setLoading] = useState(false);
  const [fre, setFre] = useState(125);
  const [index, setIndex] = useState(0);
  // const [writeFunction, setWriteFunction] = useState(undefined);
  const [bgcolor, setBgcolor] = useState([
    "contained",
    "outlined",
    "outlined",
    "outlined",
    "outlined",
    "outlined",
    "outlined",
    "outlined",
  ]);

  const [dis, setDis] = useState([
    false,
    false,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);

  const decreasedB = () => {
    if (value > 0) {
      setValue(value - 5);
    }
  };
  const increasedB = () => {
    if (value < 100) {
      setValue(value + 5);
    }
  };

  const audio = (fre, value) => {
    setLoading(true);
    // const finalFittingData = [170, 171, 3, 0, 9, 196, 10] ;
    var op = 1;
    var freq = fre;
    var gain = value;
    switch (gain) {
      case 100:
        gain = 0;
        break;

      case 95:
        gain = 5;
        break;

      case 90:
        gain = 10;
        break;

      case 85:
        gain = 15;
        break;

      case 80:
        gain = 20;
        break;

      case 75:
        gain = 25;
        break;

      case 70:
        gain = 30;
        break;

      case 65:
        gain = 35;
        break;

      case 60:
        gain = 40;
        break;

      case 55:
        gain = 45;
        break;

      case 50:
        gain = 50;
        break;

      case 45:
        gain = 55;
        break;

      case 40:
        gain = 60;
        break;

      case 35:
        gain = 65;
        break;

      case 30:
        gain = 70;
        break;

      case 25:
        gain = 75;
        break;

      case 20:
        gain = 80;
        break;

      case 15:
        gain = 85;
        break;

      case 10:
        gain = 90;
        break;

      case 5:
        gain = 95;
        break;

      case 0:
        gain = 100;
        break;
    }

    const arr_send = new Uint8Array(7);
    if (op == 1) {
      arr_send[0] = 0xaa;
      arr_send[1] = 0xab;
      arr_send[2] = 3;
      arr_send[3] = 0;
      arr_send[4] = freq / 256;
      arr_send[5] = freq % 256;
      arr_send[6] = gain;
    } else {
      arr_send[0] = 0xaa;
      arr_send[1] = 0xab;
      arr_send[2] = 3;
      arr_send[3] = 0;
      arr_send[4] = 0;
      arr_send[5] = 0;
      arr_send[6] = 0;
    }
    console.log("lll", arr_send);

    const data = fitting?.writeFun?.writeData?.(arr_send);
    console.log("fitting", data);
  };

  // const audio = (fre,value) => {

  //   var eq_characteristic = null

  //   var op = 1;
  // 	var freq = fre
  // 	var gain = value

  // 	  var arr_send = new Uint8Array(7) ;
  //     console.log("FREQ",freq)
  //     console.log("Gain",gain)
  //     console.log("lplpl",arr_send);

  // 	  if (op==1) {
  // 	  arr_send[0] = 0xAA;
  // 	  arr_send[1] =  0xAB;
  // 	  arr_send[2] =   3;
  // 	  arr_send[3] = 0;
  // 	  arr_send[4] = freq /256;
  // 	  arr_send[5] =freq % 256;
  // 	  arr_send[6] = gain;
  // 	  } else {
  // 		    arr_send[0] = 0xAA;
  // 		  arr_send[1] =  0xAB;
  // 		  arr_send[2] =   3;
  // 		  arr_send[3] = 0;
  // 		  arr_send[4] = 0 ;
  // 		  arr_send[5] = 0 ;
  // 		  arr_send[6] = 0;

  // 	  }
  // 	  let resetEnergyExpended = arr_send;
  // 	  console.log("popo",resetEnergyExpended)
  // 	   try {

  //       setWriteFunction( {
  //         writeData: async (arr_send) => {
  //                    return await eq_characteristic.writeValueWithoutResponse(Uint8Array.from(arr_send))
  //                }

  //               }
  //               )

  //             //  console.log("lolo",setWriteFunction)
  // 			// log('> 发送 '+arr_send.join(' '));
  // 			// eq_characteristic.writeValue(resetEnergyExpended);
  //       // var audioCtx = new AudioContext();
  //       // console.log("Audio",audioCtx)
  //       // var gain = audioCtx.createGain();
  //       // gain.channelInterpretation = "headphones";
  //       // console.log("Gain",gain)
  //       // var ocs = audioCtx.createOscillator();
  //       // console.log("OCS",ocs)

  //       // ocs.frequency.value = fre;
  //       // gain.gain.value = Math.pow(10, value / 20);
  //       // var panNode = audioCtx.createStereoPanner();
  //       // console.log("Pan",panNode)
  //       // panNode.pan.value = 0;
  //       // gain.connect(panNode);
  //       // panNode.connect(audioCtx.destination);
  //       // ocs.connect(gain);
  //       // ocs.start(0);
  //       // setLoading(true);

  //       // ocs.stop(audioCtx.currentTime + 1);
  //       // ocs.onended = () => {
  //       //   gain.disconnect();
  //       //   panNode.disconnect();
  //       //   ocs.disconnect();
  //       //   audioCtx.close();
  //       // };

  //       // setTimeout(() => {
  //       //   setLoading(false);
  //       // }, audioCtx.currentTime + 1000);

  // 			// console.log("lolool",eq_characteristic)
  // 			// ChromeSamples.setStatus('>发送给助听器BLE');

  // 		}catch(error) {
  //       console.log(error.message);
  // 				// log('Argh! ' + error);
  // 				// ChromeSamples.setStatus('出错了');
  // 		}

  // 		return false

  // };

  const changeFre = (freq, index) => {
    setLoading(false);
 
    setIndex(index);
    for (let i = 0; i < 8; i++) {
      bgcolor[i] = "outlined";
      dis[i] = true;
    }
    bgcolor[index] = "contained";
    dis[index] = false;
    if (index - 1 >= 0) {
      dis[index - 1] = false;
    }

    if (index + 1 <= 7) {
      dis[index + 1] = false;
    }
    setFre(freq);
  };

  const markvalue = () => {
    setValue(0);
    setFre(0);
    const data = {
      index,
      value,
    };
    dispatch(Mark_value(index, value));
  };

  const CustomizeButton = styled(ButtonBase)(({ theme, disabled }) => ({
    height: "40px",
    width: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: disabled
      ? theme.palette.grey[200]
      : theme.palette.secondary.light,
    border: disabled
      ? "1px solid transparent"
      : "1px solid " + theme.palette.primary.main,
    cursor: disabled ? "not-allowed !important" : "pointer",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  }));

  const InputArea = styled(Box)(({ theme }) => ({
    // height: '100%',
    width: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    border: "1px solid " + theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
  }));

  return (
    <Box mt={5} p={3} sx={{ border: "1px solid black" }}>
      <Grid container columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
        <Grid item xs={6}>
          <Stack spacing={2}>
            <Button
              variant={bgcolor[0]}
              sx={{ width: 150 }}
              onClick={() => {
                changeFre(125, 0);
              }}
              disabled={dis[0]}
            >
              125 Hz
            </Button>
            <Button
              variant={bgcolor[1]}
              sx={{ width: 150 }}
              onClick={() => {
                changeFre(250, 1);
              }}
              disabled={dis[1]}
            >
              250 Hz
            </Button>
            <Button
              variant={bgcolor[2]}
              sx={{ width: 150 }}
              onClick={() => {
                changeFre(500, 2);
              }}
              disabled={dis[2]}
            >
              500 Hz
            </Button>
            <Button
              variant={bgcolor[3]}
              sx={{ width: 150 }}
              onClick={() => changeFre(1000, 3)}
              disabled={dis[3]}
            >
              1K Hz
            </Button>
            <Button
              variant={bgcolor[4]}
              sx={{ width: 150 }}
              onClick={() => changeFre(2000, 4)}
              disabled={dis[4]}
            >
              2k Hz
            </Button>
            <Button
              variant={bgcolor[5]}
              sx={{ width: 150 }}
              onClick={() => changeFre(4000, 5)}
              disabled={dis[5]}
            >
              4K Hz
            </Button>
            <Button
              variant={bgcolor[6]}
              sx={{ width: 150 }}
              onClick={() => changeFre(6000, 6)}
              disabled={dis[6]}
            >
              6K Hz
            </Button>
            {/* <Button
              variant={bgcolor[7]}
              sx={{ width: 150 }}
              onClick={() => changeFre(8000, 7)}
              disabled={dis[7]}
            >
              8K Hz
            </Button> */}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Box pt={5} pl={5} pb={4} sx={{ border: "1px solid black" }}>
            <Button
              variant="outlined"
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "210px",
                color: "black",
                cursor: "disabled",
              }}
            >
              <Typography fontWeight={800} lineHeight="100%" variant="h3">
                {fre >= 1000 ? fre / 1000 + "K" : fre} Hz
              </Typography>
            </Button>
            <Box pt={3} sx={{ display: "flex" }}>
              <CustomizeButton onClick={decreasedB} sx={{ display: "flex" }}>
                <Remove />
              </CustomizeButton>
              <InputArea sx={{ width: "130px" }}>{value} dB</InputArea>
              <CustomizeButton onClick={increasedB}>
                <Add />
              </CustomizeButton>
            </Box>

            <Box pt={2}>
              <Button
                variant="outlined"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "210px",
                }}
                onClick={() => audio(fre, value)}
              >
                {!loading ? (
                  <>
                    {console.log(loading)}
                    <Typography variant="h3" lineHeight="70%">
                      <PlayArrowIcon />
                    </Typography>
                    &nbsp;
                    <Typography fontWeight={500} lineHeight="70%" variant="h3">
                      Stimulus
                    </Typography>
                  </>
                ) : (
                  <GraphicEqIcon />
                )}
              </Button>

              {/* 
              <Button
                variant="outlined"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "210px",
                }}
                onClick={() => audioStatic()}
              >
                {!loading && (
                  <>
                    <Typography variant="h3" lineHeight="70%">
                      <PlayArrowIcon />
                    </Typography>
                    &nbsp;
                    <Typography fontWeight={500} lineHeight="70%" variant="h3">
                      Static
                    </Typography>
                  </>
                )}
                {loading && <GraphicEqIcon />}
              </Button> */}
            </Box>
         
            <Box pt={6}>
              <SubmitButton
                type="submit"
                onClick={markvalue}
                sx={{ maxWidth: "210px" }}
                title={"Mark value"}
              />
            </Box>
       </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Audiometrycreateuiright;
