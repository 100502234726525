import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import CreateUi from "./CreateUi";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import {
  addUserApi,
  getUserByIdApi,
  updateInstallerUserField,
  updateUserField,
} from "../../apis/user.api";
import { closeModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";
import { USER_ROLES } from "../../utils/constants";
import { validateInternationPhone, validatePhone } from "../../utils/helper";

const CreateController = ({ callBack = () => {}, id, role = null }) => {
  const validate = useValidate();
  const dispatch = useDispatch();
  const params = useParams();
  const modalKey = "users";
  const title = "User ";
  const createApi = addUserApi;
  const updateApi = updateUserField;
  const getByIdApi = getUserByIdApi;

  const [loading, setLoading] = useState(false);
  const isRolePredefined = role !== null;
  const [fields, setFields] = useState({
    err: "",
    id,
    name: "",
    email: "",
    phone: "",
    parent_id: null,
    country_code: "+91",
    firm_name: "",
    firm_logo: "",
    role,
  });
  const [originalDocument, setOriginalDocument] = useState({});

  const validationSchemaForCreate = useMemo(
    () => [
      {
        required: true,
        value: fields.name,
        field: "Name",
      },
      {
        required: true,
        value: fields.email,
        field: "Email",
        isEmail: true,
      },
      {
        required: true,
        value: fields.phone,
        field: "Phone",
        custom: () => {
          if (!validateInternationPhone(fields.country_code + fields.phone)) {
            return "Phone is  invalid";
          }
          return true;
        },
      },

      {
        required: true,
        value: fields.role,
        field: "Role",
      },
    ],
    [fields]
  );

  const validationSchemaForUpdate = useMemo(
    () => [
      {
        required: true,
        value: fields.name,
        field: "Name",
      },
    ],
    [fields]
  );

  const createFunction = async () => {
    const validationResponse = validate(validationSchemaForCreate);

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await createApi(fields),
          async (response) => {
            await callBack(response);
            setLoading(false);
            dispatch(closeModal(modalKey));
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const updateFunction = async () => {
    const validationResponse = validate(validationSchemaForUpdate);
    let updatedData = { id };

    for (let field in fields) {
      if (
        originalDocument[field] &&
        fields[field] &&
        fields[field] != originalDocument[field]
      ) {
        updatedData[field] = fields[field];
      }

      if (!originalDocument[field]) {
        updatedData[field] = fields[field];
      }
    }

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await updateApi(updatedData),
          async (response) => {
            await callBack(response, updatedData);
            setLoading(false);
            dispatch(closeModal(modalKey));
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (id) updateFunction();
    else createFunction();
  };

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getByIdApi({ id }),
        async (response) => {
          setFields({ ...fields, ...response });
          setOriginalDocument(response);
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) fetchById(id);
  }, [id]);

  return (
    <CreateUi
      isRolePredefined={isRolePredefined}
      modalKey={modalKey}
      title={title}
      isUpdate={id}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  );
};
export default memo(CreateController);
