import {
  Box,
  Button,
  ButtonBase,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { memo, useState } from "react";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import AudioGram from "../patient/AudioGram";
import NoDataComponent from "../../components/layouts/NoDataComponent";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import AudiometryChart from "../inSituAudiometry/AudiometryChart";
import {
  ArrowDownward,
  BrowserNotSupported,
  DocumentScanner,
  History,
  KeyboardArrowDown,
  Note,
} from "@mui/icons-material";
import SubmitButton from "../../components/button/SubmitButton";
import Audiometrycreateuileft from "../inSituAudiometry/Audiometrycreateuileft";
import { AudioMetryChartComponent } from "../inSituAudiometry/new/AudioMetryPlayArea";

const AudioGramHistoryComponent = ({
  fields,
  setFields,
  indexNo,
  loading,
  no_frequncy_box,
  defaultOpen,
}) => {
  const [open, setOpen] = useState(defaultOpen);
  const { fitting } = useSelector((state) => state);
  return (
    <Box component={Paper} sx={{ width: "100%" }} mt={1} mb={1}>
      <Box
        component={ButtonBase}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          background: "#f2f2f2",
        }}
        p={2}
        onClick={() => setOpen(!open)}
      >
        <Box sx={{ display: "flex" }}>
          <Typography variant="subtitle1" sx={{ mr: 2 }}></Typography>
          <Typography variant="subtitle1">
            Updated By: {fields?.updated_by?.name}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="subtitle1">
            {moment(fields?.createdAt).format("DD/MM/YYYY")}
          </Typography>
          {
            <Typography variant="h3" ml={3}>
              {!defaultOpen ? (
                <KeyboardArrowDown sx={{ fontSize: "inherit" }} />
              ) : (
                ""
              )}
              &nbsp;
            </Typography>
          }{" "}
        </Box>
      </Box>
      <Collapse in={open}>
        <Box sx={{ padding: 2 }}>
          <AudioGram
            fields={fields}
            only_view={true}
            loading={loading}
            setFields={() => { }}
            no_frequncy_box={no_frequncy_box}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

const AudiogramListUi = ({ title, filters, setFilters, modalKey, loading, list, audiometry }) => {
  const { fitting } = useSelector((state) => state);


  return (
    <>



      {/* 
      //-->Audio history */}
      {(
        <CustomDialog
          dialogProps={{ sx: { height: "1000px", overflow: "hidden" } }}
          id={modalKey}
          loading={loading}
          title={<Box sx={{ display: "flex" }}>
            <Tabs value={filters.tab} size="Small" onChange={(e, newVal) => setFilters({ ...filters, tab: newVal })} >
              <Tab size="small" label="Audiogram History" />
              <Tab size="small" label="In Situ Audiogram" />
            </Tabs>

          </Box>}
          closeText="Close"
        >


          {loading && (
            <CenteredBox>
              <CircularProgress />
            </CenteredBox>
          )}

          {filters.tab == 0 &&
            <>
              {!loading && list[0] && (
                <Grid container columnSpacing={4}>
                  <Grid item xs={12} md={6}>
                    <Box mt={3} mb={3}>
                      <Divider>
                        <Chip
                          label="Current Audiogram"
                          size="small"
                          color="primary"
                        />
                      </Divider>
                    </Box>
                    {/* <Typography variant="body1" align="center" mt={3}  mb={3}>Current Audiogram</Typography> */}
                    <AudioGramHistoryComponent
                      indexNo={1}
                      defaultOpen
                      loading={loading}
                      fields={list[0]}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box mt={3} mb={3}>
                      {console.log("LIST", list)}
                      {list && list.length > 1 && (
                        <Divider>
                          <Chip
                            label="Audiogram History"
                            size="small"
                            color="primary"
                          />
                        </Divider>
                      )}
                    </Box>
                    <Box p={2} pt={0} sx={{ height: "100%" }}>
                      {list && list.length > 1 ? (
                        list
                          .slice(1)
                          .map((item, index) => (
                            <AudioGramHistoryComponent
                              indexNo={index + 2}
                              no_frequncy_box={true}
                              key={item._id}
                              loading={loading}
                              fields={item}
                            />
                          ))
                      ) : (
                        <CenteredBox sx={{ height: "100%" }}>
                          <NoDataComponent message="" Icon={BrowserNotSupported} />
                        </CenteredBox>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              )}


              {!loading && !list[0] && (
                <CenteredBox>
                  <NoDataComponent message="No History Found" />
                </CenteredBox>
              )}

            </>
          }

          {filters.tab == 1 &&
            <>
              {!loading && audiometry?.['_id'] && (
                <CenteredBox>
                  <AudioMetryChartComponent

                    data={{
                      freq: [125, 250, 500, 1000, 2000, 4000, 6000],
                      freqNames: [125, 250, 500, '1k', '2k', '4k', '6k'],
                      left: [
                        audiometry?.insitu_lf1
                        , audiometry?.insitu_lf2
                        , audiometry?.insitu_lf3
                        , audiometry?.insitu_lf4
                        , audiometry?.insitu_lf5
                        , audiometry?.insitu_lf6

                        , audiometry?.insitu_lf7
                      ],

                      right: [
                        audiometry?.insitu_rf1
                        , audiometry?.insitu_rf2
                        , audiometry?.insitu_rf3
                        , audiometry?.insitu_rf4
                        , audiometry?.insitu_rf5
                        , audiometry?.insitu_rf6

                        , audiometry?.insitu_rf7
                      ]
                    }}
                  />
                </CenteredBox>
              )}


              {!loading && !audiometry?.['_id'] && (
                <CenteredBox>
                  <NoDataComponent message="No History Found" />
                </CenteredBox>
              )}

            </>
          }

        </CustomDialog>
      )}
    </>
  );
};
export default memo(AudiogramListUi);
