import { useEffect, useState } from "react"
import AudiogramListUi from "./AudiogramListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { getPatientAudiogramApi } from "../../apis/patient.api"
import { getAudiometryApi } from "../../apis/audiometry.api"


const AudiogramListController = ({ patient_id }) => {

    const dispatch = useDispatch()


    const title = "Audiogram History"
    const modalKey = 'audiogram-history'
    const fetchApi = getPatientAudiogramApi
    const fetchAudiometryApi = getAudiometryApi





    const [filters, setFilters] = useState({

        patient_id,
        tab: 0
    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])
    const [audiometry, setAudioMetry] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }

    const fetchAudiMetry = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchAudiometryApi({ ...filters }),
            (response) => {
                setAudioMetry(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }




    useEffect(() => {
        if (list.length == 0 && filters.tab == 0)
            fetchList()
        if (filters.tab == 1)
            fetchAudiMetry()
    }, [filters])



    return (
        <>
            <AudiogramListUi
                title={title}


                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                audiometry={audiometry}
                modalKey={modalKey}

            />

        </>
    )
}
export default AudiogramListController