import { TextField } from "@mui/material"
import { memo, useEffect, useState } from "react"
import { StyledSearchBar } from "./SearchBar"

const AsyncSearchBar = ({ onChange, InputComponent, defaultValue, ...props }) => {
    const [timeOut, setTimeOutState] = useState()
    const [firstTime, setFirstTime] = useState(true)
    const [val, setVal] = useState(defaultValue ?? '')



    useEffect(() => {
        if (!firstTime) {
            if (timeOut) {
                clearTimeout(timeOut)
            }
            const fun = () => {
                onChange(val)
                clearTimeout(timeOut)
            }


            const newTimeOut = setTimeout(fun, 500)
            setTimeOutState(newTimeOut)
        }

    }, [val])
    useEffect(() => {
        setFirstTime(false)
    }, [])
    return <StyledSearchBar {...props} value={val} onChange={(e) => { setVal(e.target.value) }} />

}
export default memo(AsyncSearchBar)