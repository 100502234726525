import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal, openModal } from "../../store/actions/modalAction"

import CreateController from "./CreateController"
import { getPatientProfileApi } from "../../apis/patientprofile.api"


const ListController = ({ patient_id }) => {
    const dispatch = useDispatch()

    const title = "Patients Profile"
    const fetchApi = getPatientProfileApi

    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        patient_id,
        search: '',        
        role: '',
        sort: 'createdAt',
        sortDirection: -1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})


    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController  patient_id={patient_id} callBack={async () => { fetchList() }} />, "lg", false, "patient-profile"))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
                title={title}
                onCreateBtnClick={onCreateBtnClick}

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}                


            />

        </>
    )
}
export default ListController