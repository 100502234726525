import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogTitle, Fade, IconButton,  Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { getFile } from "../apis/file.api";


const ImageComponent = ({ src, alt, sx = {}, noClickEffect, isStatic, ...props }) => {
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        if (!noClickEffect) {
            setLoading(true)
            setOpen(true);
        }
    };
    const handleClose = () => {
        setOpen(false);
    };

    const fetchImage = async () => {
        const file = await getFile(src)

        setImage(URL.createObjectURL(file))

        setLoading(false)
    }
    const [img, setImage] = useState(isStatic ? src : null)

    useEffect(() => {
        if (!isStatic)
            fetchImage()
    }, [src])

    return (

        <> <Dialog

            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            fullWidth
        >
            <DialogTitle sx={{ justifyContent: "flex-end", display: "flex" }}>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent   >

                <Box sx={{ width: "100%", background: "lightgray", position: "relative" }}>

                    <img onLoad={() => { setLoading(false) }} src={img} alt={alt} style={sx} {...props} onClick={handleOpen} />

                </Box>
            </DialogContent>
        </Dialog>

            <Box sx={{ width: "100%", position: "relative", height: "100%" }}>
                {loading && <Box sx={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", minHeight: "200px" }}>
                    <Skeleton sx={{ height: "100%", width: "100%" }} animation="wave">

                    </Skeleton>
                </Box>}
                <img src={img} onLoad={() => { setLoading(false) }} alt={alt} style={{ objectFit: "cover", width: "100%", height: "100%", ...sx, cursor: noClickEffect ? "default" : "pointer" }} {...props} onClick={handleOpen} />
            </Box>

        </>)
}
export default ImageComponent