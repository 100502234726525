
import { useDispatch, useSelector } from 'react-redux'

import CustomDialog from '../../components/layouts/common/CustomDialog'
import { closeModal } from '../../store/actions/modalAction'
import { Box, Typography } from '@mui/material'
import AsyncDropDown from '../../components/inputs/AsyncDropDown'
import CustomInput from '../../components/inputs/CustomInput'
import { getUserApi } from '../../apis/user.api'
import { USER_ROLES } from '../../utils/constants'
import { useState } from 'react'
import { assignAudiologist } from '../../apis/patient.api'



const MessageDilog = ({ id, title = "Reassigned AudioLogist ", message, onSubmit, loading, confirmText, modalId = 'error' }) => {
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(closeModal())
    }

    console.log("this is id", id)

    const [data, setData] = useState({
        id: id,
        assigned: "",
        err: ''

    })

    const updateClick = async () => {
        const response = await assignAudiologist(data);
    }

    const { user } = useSelector(state => state);
    return (
        <>
            <CustomDialog
                id="reassign"
                loading={loading}
                onClose={handleClose}
                onSubmit={updateClick}
                title={title} 
                closeText="Close"
                confirmText={`Update`}
            >

                <Box>
                    {user.data.role === USER_ROLES.ADMIN && <AsyncDropDown
                        InputComponent={(props) => <CustomInput label placeholder="Select Audiologist"  {...props} />}
                        lazyFun={async (props) => {
                            return await getUserApi({ ...props, role: USER_ROLES.AUDIO_LOGIST })
                        }}
                        OptionComponent={({ option, ...rest }) => {
                            return <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} {...rest}>
                                <Typography ml={3} variant="h5">{option.name}</Typography>
                            </Box>
                        }}
                        onChange={async (changedVal) => { setData({ ...data, err: '', assigned: changedVal?._id }) }}
                        titleKey={'name'}
                        valueKey={"_id"}
                    />}
                </Box>
            </CustomDialog>


        </>
    )
}
export default MessageDilog