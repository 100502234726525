import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { deleteUserApi, getUserApi, updateUserField } from "../../apis/user.api"
import { useParams } from "react-router-dom"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { findNameByRole, findObjectKeyByValue, titleCase, toTitleCase } from "../../utils/helper"
import { Box, Chip, CircularProgress, IconButton, Typography } from "@mui/material"
import { useMemo } from "react"
import CreateController from "./CreateController"
import { ChangeCircle, Delete, Edit, LockClock, LockOpen, WhatsApp } from "@mui/icons-material"
import MessageDilog from "../../components/MessageDilog"
import ResetPasswordController from "./ResetPassword.controller"
import { USER_ROLES } from "../../utils/constants"
import ModulePermissionController from "./ModulePermissionController"
import MODULES from "../../utils/module.constant"
import ChangeUserParentButton from "./parent/ChangeUserParentButton"

const ActionComponent = memo(({ params, setParams, deleteApi }) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const { user } = useSelector(state => state)

    const onEdit = () => {
        dispatch(openModal(<CreateController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "sm", false, "users"))
    }

    const onResetPassword = () => {
        dispatch(openModal(<ResetPasswordController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "sm", false, "reset-password"))
    }

    const deleteFun = async () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteApi({ id: params._id }),
            (response) => {
                setParams({})
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())


    }
    const onDelete = () => {
        dispatch(openModal(<MessageDilog onSubmit={deleteFun} title="Alert!" message={`Are you sure to delete "${params.name || params.title}" ?`} />, "sm"))
    }
    const whatsAppMsg = `
Hi ${params.name}

Below is your credential for the Weconfig Pro Cloud Solution:

Email: ${params.email}
Password: ${toTitleCase(params.name.replace(/dr./i,"").replace(/dr/i,"").replace(/dr /i,"").slice(0,3))}@123


Keep this information secure!

You can open application via this link:
${window.location.hostname}

If you need any assistance, feel free to reach out to us via ccare@wehear.in

Best regards,
WeHear Team
`
    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        <IconButton disabled={loading} size="inherit" onClick={onEdit}>
            <Edit color="info" fontSize="inherit" />
        </IconButton>
        <IconButton disabled={loading} size="inherit" onClick={onResetPassword}>
            <LockOpen color="info" fontSize="inherit" />
        </IconButton>
        {user.data.role == USER_ROLES.ADMIN && <>

            {loading && <CircularProgress color="primary" fontSize="inherit" />}
            {!loading && <IconButton disabled={loading} size="inherit" onClick={onDelete}>
                <Delete color="error" fontSize="inherit" />
            </IconButton>}
        </>}

        <IconButton disabled={loading} size="inherit"  LinkComponent={'a'} target="_blank" href={`https://web.whatsapp.com/send?phone=+91${params?.phone}&text=${encodeURI(whatsAppMsg)}`}>
            <WhatsApp color="success" fontSize="inherit" />
        </IconButton>
    </Box>
})
const ModulesComponent = memo(({ params, setParams }) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const { user } = useSelector(state => state)

    const onEdit = () => {
        dispatch(openModal(<ModulePermissionController id={params._id} {...params} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "sm", false, "modules"))
    }

    return <>
        <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
            {(params.modules && params.modules.map((item) => (<Chip size="small" sx={{mr:1}} key={item} label={findObjectKeyByValue(item,MODULES)} />)))}
            <IconButton disabled={loading} size="inherit" onClick={onEdit}>
                <ChangeCircle color="info" fontSize="inherit" />
            </IconButton>
        </Box>



    </>


})
const ListController = () => {
    const dispatch = useDispatch()



    const title = "Users"
    const fetchApi = getUserApi
    const deleteApi = deleteUserApi

    const columns = useMemo(() => [

        { id: 1, minWidth: "50px", fieldName: 'name', label: 'Name', align: "left", sort: true },
      
        { id: 2, minWidth: "50px", fieldName: 'email', label: 'Email', align: "left", sort: true },
        {
            id: 4,
            fieldName: 'role',
            label: 'Role',

            sort: true,
            align: "left",
            renderValue: (params) => findNameByRole(params.role) || '',
        },
        { id: 3, minWidth: "50px", fieldName: 'firm_name', label: 'Firm Name', align: "left", sort: true },
        
        {
            id: 5,
            fieldName: 'parent',
            label: 'Parent Action',
            minWidth: "100px",
            sort: true,
            align: "left",
            renderValue: (params, setParams) => {
                return <ChangeUserParentButton id={params._id} />
            },
        },

        {
            id: 5,
            fieldName: '',
            label: 'Action',

            align: "right",
            renderValue: (params, setParams) => <ActionComponent deleteApi={deleteApi} params={params} setParams={setParams} />,
        },

    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        role: null,
        sort: 'createdAt',
        sortDirection: -1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController callBack={async () => { fetchList() }} />, "sm", false, "users"))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
                title={title}
                onCreateBtnClick={onCreateBtnClick}

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController