import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomInput from "../../components/inputs/CustomInput";
import { GENDER, USER_ROLES } from "../../utils/constants";
import {
  calculateAge,
  findNameByRole,
  getAllowedRoles,
  titleCase,
} from "../../utils/helper";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import {
  IMPAIREMENT_TYPE,
  LEVEL_OF_HEARING_LOSS,
  TYPE_OF_HEARING_LOSS,
} from "../../utils/patient.constants";
import ModuleContainer from "../../components/layouts/common/ModuleContainer";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import SubmitButton from "../../components/button/SubmitButton";
import AudioGram from "./AudioGram";
import { useTheme } from "@mui/material";
import COUNTRY_CODES from "../../utils/CountryCode";
import { openModal } from "../../store/actions/modalAction";
import AudioMetryButton from "../inSituAudiometry/new/AudioMetryButton";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { getUserApi } from "../../apis/user.api";

const CreateUi = ({
  step,
  setStep,
  isUpdate,
  fields,
  setFields,
  loading,
  inSituAudiometry,
  onSubmit,
}) => {
  // useEffect(() => {
  //   let timeOut = setTimeout(() => {
  //     const dob = moment()
  //       .set("date", 1)
  //       .set("month", 5)
  //       .add(-1 * fields.age, "year");
  //     setFields({ ...fields, err: "", dob: dob.toISOString() });
  //   }, 500);
  //   return () => clearTimeout(timeOut);
  // }, [fields.age]);

  const { user } = useSelector((state) => state);

  const [isAgeModified, setIsAgeModified] = useState(false);
  useEffect(() => {
    if (isAgeModified) {
      let timeOut = setTimeout(() => {
        if (
          fields.age !== null &&
          fields.age !== undefined &&
          !isNaN(fields.age) &&
          fields.age !== 0
        ) {
          const dob = moment()
            .set("date", 1)
            .set("month", 5)
            .subtract(fields.age, "years");

          if (dob.isValid()) {
            setFields((prevFields) => ({
              ...prevFields,
              err: "",
              dob: dob.toISOString(),
            }));
          } else {
            setFields((prevFields) => ({
              ...prevFields,
              err: "Invalid date of birth",
            }));
          }
        }
      }, 500);

      return () => clearTimeout(timeOut);
    }
  }, [fields.age, isAgeModified]);

  return (
    <>
      <ModuleContainer
        containerProps={{
          component: "form",
          onSubmit,
        }}
        loading={loading}
        err={fields.err}
      >
        {loading && (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        )}
        <Box
          p={3}
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflowY: "scroll",
          }}
        >
          {
            <>
              <Typography color="error">{fields.err}</Typography>

              {step == 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ display: "flex" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={4}>
                          <CustomInput
                            autoFocus={true}
                            disabled={loading}
                            value={fields.first_name}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                first_name: e.target.value,
                              })
                            }
                            type="text"
                            label={"Full Name*"}
                          />
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <CustomInput
                            disabled={loading}
                            value={fields.middle_name}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                middle_name: e.target.value,
                              })
                            }
                            type="text"
                            label={"Middle Name"}
                          />
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <CustomInput
                            disabled={loading}
                            value={fields.last_name}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                last_name: e.target.value,
                              })
                            }
                            type="text"
                            label={"Last Name"}
                          />
                        </Grid>

                        <Grid item xs={6} md={6}>
                          <CustomInput
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Select
                                    disableUnderline
                                    value={fields.country_code}
                                    onChange={(e) =>
                                      setFields({
                                        ...fields,
                                        err: "",
                                        country_code: e.target.value,
                                      })
                                    }
                                    variant="standard"
                                    size="small"
                                  >
                                    {COUNTRY_CODES.map((item) => (
                                      <MenuItem
                                        key={item.code}
                                        value={item.dial_code}
                                      >
                                        {item.code}({item.dial_code})
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </InputAdornment>
                              ),
                            }}
                            disabled={loading}
                            value={fields.phone}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                phone: e.target.value,
                              })
                            }
                            type="number"
                            label={"Phone No.*"}
                          />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <CustomInput
                            disabled={loading}
                            value={fields.email}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                email: e.target.value,
                              })
                            }
                            type="email"
                            label={"Email"}
                          />
                        </Grid>

                        <Grid item xs={6} md={8}>
                          <DesktopDatePicker
                            disableFuture
                            inputFormat="DD/MM/yyyy"
                            renderInput={(props) => <CustomInput {...props} />}
                            autoFocus={true}
                            disabled={loading}
                            value={fields.dob ? moment(fields.dob) : null}
                            onChange={(val) => {
                              if (val && val.isValid()) {
                                const isoString = val.toISOString();
                                setFields({
                                  ...fields,
                                  err: "",
                                  dob: isoString,
                                  age: calculateAge(isoString),
                                });
                                setIsAgeModified(false); 
                              } else {
                                setFields({
                                  ...fields,
                                  err: "",
                                  dob: "",
                                  age: 0,
                                });
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Box sx={{ height: "100%", width: "100%" }}>
                            {/* <CenteredBox sx={{ height: "100%", width: "100%", background: "#f2f2f2", border: "1px solid " + theme.palette.primary.main }} p={2} >
                                                    Age:   {calculateAge(fields.dob)} years
                                                </CenteredBox> */}

                            <CustomInput
                              disabled={loading}
                              value={fields.age}
                              onChange={(e) => {
                                // const dob = moment().set('date',1).set('month',5).add(-1*e.target.value,'year')
                                setFields({ ...fields, age: e.target.value });
                              }}
                              onBlur={(e) => {
                                const dob = moment()
                                  .set("date", 1)
                                  .set("month", 5)
                                  .add(-1 * e.target.value, "year");
                                setFields({
                                  ...fields,
                                  err: "",
                                  dob: dob.toISOString(),
                                });
                              }}
                              type="number"
                              label={"Age*"}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <CustomInput
                            multiline
                            rows={3}
                            disabled={loading}
                            value={fields.address}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                address: e.target.value,
                              })
                            }
                            type="address"
                            label={"Address*"}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {user.data.role === USER_ROLES.ADMIN && (
                            <AsyncDropDown
                              InputComponent={(props) => (
                                <CustomInput
                                  label
                                  placeholder="Select Audiologist"
                                  {...props}
                                />
                              )}
                              lazyFun={async (props) => {
                                return await getUserApi({
                                  ...props,
                                  role: USER_ROLES.AUDIO_LOGIST,
                                });
                              }}
                              OptionComponent={({ option, ...rest }) => {
                                return (
                                  <Box
                                    sx={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    {...rest}
                                  >
                                    <Typography ml={3} variant="h5">
                                      {option.name}
                                    </Typography>
                                  </Box>
                                );
                              }}
                              onChange={async (changedVal) => {
                                setFields({
                                  ...fields,
                                  err: "",
                                  assigned: changedVal?._id,
                                });
                              }}
                              titleKey={"name"}
                              valueKey={"_id"}
                            />
                          )}
                        </Grid>
                        <Grid item xs={7}>
                          <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              Gender*
                            </FormLabel>
                            <RadioGroup
                              value={fields.gender}
                              onChange={(e) =>
                                setFields({
                                  ...fields,
                                  err: "",
                                  gender: e.target.value,
                                })
                              }
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                            >
                              {Object.keys(GENDER).map((item) => (
                                <FormControlLabel
                                  key={item}
                                  value={GENDER[item]}
                                  control={<Radio />}
                                  label={item}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={1}></Grid>
                  <Grid item xs={12} md={5}>
                    <Box pl={0}>
                      {/* <FormControl margin="dense">
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Type Of Hearing Loss*
                        </FormLabel>
                        <RadioGroup
                          value={fields.type_of_loss}
                          onChange={(e) =>
                            setFields({
                              ...fields,
                              err: "",
                              type_of_loss: e.target.value,
                            })
                          }
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          {Object.keys(TYPE_OF_HEARING_LOSS).map((item) => (
                            <FormControlLabel
                              key={item}
                              value={TYPE_OF_HEARING_LOSS[item]}
                              control={<Radio />}
                              label={item}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl> */}

                      {/* <FormControl margin="dense">
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Level of Hearing Loss*
                        </FormLabel>
                        <RadioGroup
                          value={fields.level_of_loss}
                          onChange={(e) =>
                            setFields({
                              ...fields,
                              err: "",
                              level_of_loss: e.target.value,
                            })
                          }
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          {Object.keys(LEVEL_OF_HEARING_LOSS).map((item) => (
                            <FormControlLabel
                              key={item}
                              value={LEVEL_OF_HEARING_LOSS[item]}
                              control={<Radio />}
                              label={item}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl> */}
                      {/* <FormControl margin="dense">
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Impairment Type*
                        </FormLabel>
                        <RadioGroup
                          value={fields.impairment_type}
                          onChange={(e) =>
                            setFields({
                              ...fields,
                              err: "",
                              impairment_type: e.target.value,
                            })
                          }
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          {Object.keys(IMPAIREMENT_TYPE).map((item) => (
                            <FormControlLabel
                              key={item}
                              value={IMPAIREMENT_TYPE[item]}
                              control={<Radio />}
                              label={item}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl> */}
                    </Box>
                  </Grid>
                </Grid>
              )}
              {step == 1 && (
                <AudioGram
                  loading={loading}
                  fields={fields}
                  setFields={setFields}
                />
              )}
            </>
          }
        </Box>
        <Divider />
        <Grid container spacing={2}>
          <Grid item xs={8}>
            {step != 0 && (
              <Box p={3}>
                {/* <SubmitButton
                  onClick={inSituAudiometry}
                  type="submit"
                  sx={{ maxWidth: "250px" }}
                  title={"In-Situ-Audiometry"}
                /> */}

                <AudioMetryButton patient_id={fields.id} />
              </Box>
            )}
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }} p={3}>
              {step > 0 && !isUpdate && (
                <Box mr={2}>
                  <SubmitButton
                    onClick={() => {
                      setStep(step - 1);
                    }}
                    variant="text"
                    type="button"
                    sx={{
                      minWidth: "200px",
                      textDecoration: "underline !important",
                    }}
                    title="Back"
                  />
                </Box>
              )}
              <Box>
                <SubmitButton
                  type="submit"
                  sx={{ minWidth: "200px" }}
                  title={step == 0 && !isUpdate ? "Next" : "Submit"}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ModuleContainer>
    </>
  );
};
export default memo(CreateUi);

{
  /* <DesktopDatePicker
      disableFuture
      inputFormat="DD/MM/yyyy"
      renderInput={(props) => <CustomInput {...props} />}
      autoFocus={true}
      disabled={loading}
      value={moment(fields.dob)}
      onChange={(val) =>
        setFields({
          ...fields,
          err: "",
          dob: val.toISOString(),
          age: calculateAge(val.toISOString()),
        })
      }
      type="number"
      label={"Date of Birth*"}
    /> */
}
