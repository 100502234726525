import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";


export const getPatientAudiogramApi = async params => {
  const callResponse = await axios({
    url: endpoints.patientAudiogram,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getPatientApi = async params => {
  const callResponse = await axios({
    url: endpoints.patientBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const downloadPatientReport = async params => {
  const callResponse = await axios({
    url: endpoints.patientReport,
    method: "get",
    headers: getHeaders(),
    responseType:"blob",
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getPatientByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.patientById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const addPatientApi = async data => {
  const callResponse = await axios({
    url: endpoints.patientBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updatePatientField = async data => {
  const callResponse = await axios({
    url: endpoints.patientBase,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updatePateintAudioGram = async data => {
  const callResponse = await axios({
    url: endpoints.patientAudiogram,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const deletePatientApi = async data => {
  const callResponse = await axios({
    url: endpoints.patientBase,
    method: "DELETE",
    headers: getHeaders(),

    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const assignAudiologist = async data => {
  const callResponse = await axios({
    url: endpoints.patientAssign,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};