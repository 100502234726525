import { Box, Divider, Paper, Typography } from "@mui/material"

import { Link, useLocation, useParams } from "react-router-dom"
import { memo, useEffect, useState } from "react"

const ModuleContainer = ({ breadCrumbList = [], HeaderComponent=()=><></>, containerProps = {},headrComponentProps={}, ...props }) => {

    const location = useLocation()

    const params = useParams()


    const [breadCrumb, setBreadCrumb] = useState(breadCrumbList)


    const createBreadCrumb = () => {
        if (breadCrumbList.length == 0) {
            const path = location.pathname.split('/')

            const breadcrumTempArr = []

            let redirect = '/'

            path.forEach((item, index) => {
                if (item && item != '') {

                    redirect += (item + "/")

                    if (item != params.fitid 
                        // && item != params.step
                        )
                        breadcrumTempArr.push({
                            title: item.replace(/-/g, ' '),
                            url: index == path.length - 1 ? null : redirect
                        })
                }

            })

            setBreadCrumb(breadcrumTempArr)
        }
    }
    useEffect(() => {
        createBreadCrumb()
    }, [])

    return <Paper sx={{ display: "flex", flexDirection: "column", overflowY: "hidden", flex: 1 }}  {...containerProps} >
        <Box p={3} pl={3} pr={3} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >

            <Box sx={{ display: "flex" }}>
                {breadCrumb?.map((item, index) => <Link to={item.url}  >
                    <Typography textTransform="capitalize" variant="h3" fontWeight={600} color="primary" >
                        <span dangerouslySetInnerHTML={{ __html: `${decodeURI(item.title)} ${(index == breadCrumb.length - 1) ? " " : " > "}&nbsp;` }}></span>

                    </Typography>
                </Link>)}
            </Box>
            <Box sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                <HeaderComponent {...headrComponentProps} />
            </Box>
        </Box>
        <Divider />

        {props.children}
    </Paper>
}
export default memo(ModuleContainer)