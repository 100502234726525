import {
  DriveFileRenameOutlineOutlined,
  Earbuds,
  Edit,
  Info,
  Person,
  Share,
  Visibility,
  WhatsApp,
} from "@mui/icons-material";
import NoDataComponent from "../../components/layouts/NoDataComponent";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { calculateAge, findObjectKeyByValue } from "../../utils/helper";
import { GENDER } from "../../utils/constants";
import {
  DEVICES,
  DEVICES_NAME,
  IMPAIREMENT_TYPE,
  LEVEL_OF_HEARING_LOSS,
  LISTENING_SIDE,
  TYPE_OF_HEARING_LOSS,
  TYPE_OF_HEARING_LOSS_NAMES,
} from "../../utils/patient.constants";
import ModuleContainer from "../../components/layouts/common/ModuleContainer";
import AudioGram from "./AudioGram";
import HearingAidIcon from "../../assets/icons/HearingAidIcon";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";
import { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import OpenAudioGramHistoryButton from "../audiogramhistory/OpenAudioGramHistoryButton";
import DowanloadReportButton from "./DowanloadReportButton";
import { useSelector } from "react-redux";
import DeletePatientButton from "./DeletePatientButton";
import { QrCodeButton } from "./PatientQrCode";
import { useDispatch } from "react-redux";
import {
  applyForRemoteFitting,
  resetDevice,
} from "../../store/actions/fittingAction";
import { openModal } from "../../store/actions/modalAction";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import ReassignComponent from "./reassignComponent";

const ProfileButton = styled(ButtonBase)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  lineHeight: "100%",
  padding: theme.spacing(2),

  background: theme.palette.secondary.light,
  borderRadius: theme.shape.borderRadius,
}));

const OuterContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  borderRadius: theme.shape.borderRadius,
  border: "1px solid " + theme.palette.primary.main,
}));
const TitleBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2) + " " + theme.spacing(3),
  borderBottom: "1px solid " + theme.palette.primary.main,
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "*": {
    fontSize: theme.typography.h3.fontSize,
  },
}));
const ContentContainer = styled(Box)(
  ({ theme, overflow = "auto", noPadding }) => ({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: noPadding ? 0 : theme.spacing(2) + " " + theme.spacing(3),
    width: "100%",
    overflowY: overflow,
    borderRadius: theme.shape.borderRadius,
    // border: "1px solid " + theme.palette.primary.main
  })
);

const HistoryBox = ({ even, patientData, ...data }) => {
  const { user } = useSelector((state) => state);

  const message = `Please Open Wehear App,

And Add following details in remote fitting section:
Patient Id: ${patientData?.patient_uid}
Profile Id: ${data?._id}

Message By,
${user.data.name}
    `;
  return (
    <Box
      sx={{
        borderBottom: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        textAlign: "start",
        alignItems: "flex-start",
        alignContent: "flex-start",
        borderBottomColor: "divider",
        background: even ? "light" : "#e5e5e5",
      }}
    >
      <Box pt={2} pl={3} pr={3}>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            <Box>
              <Typography
                variant="h6"
                mb={1}
                fontWeight={500}
                color={"dark.main"}
              >
                {moment(data.updatedAt).format("DD/MM/YYYY")}
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} color={"grey"}>
                {moment(data.updatedAt).format("HH:mm")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography
                variant="h6"
                mb={1}
                fontWeight={500}
                color={"dark.main"}
              >
                {DEVICES_NAME[data.device_type]}
              </Typography>
              <Typography variant="subtitle1" fontWeight={500} color={"grey"}>
                {data.device_name}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex" }} pt={1} pb={0}>
          <Typography variant="subtitle1" fontWeight={500} color={"dark.main"}>
            Remarks:
          </Typography>
          <Typography
            ml={1}
            sx={{ display: "flex", flex: 1 }}
            variant="subtitle1"
            fontWeight={500}
            color={"grey"}
          >
            {data.remarks && data.remarks != "" ? data.remarks : "NA"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            borderRadius: "100px",
          }}
          mt={0}
          mb={2}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              borderRadius: "100px",
              background: "#f2f2f2",
              border: "1px solid gray",
            }}
            p={2}
            pt={0}
            pb={0}
          >
            <IconButton
              sx={{ padding: 0 }}
              LinkComponent={Link}
              to={data._id + "/fitting"}
            >
              <Edit color="info" />
            </IconButton>
            <IconButton
              sx={{ padding: 0, ml: 2 }}
              LinkComponent={Link}
              to={data._id + "/fitting" + "/view"}
            >
              <Visibility color="info" />
            </IconButton>
            {/* https://wa.me/+91${patientData?.phone}?text=${encodeURI(message)} */}
            {/* <IconButton sx={{ padding: 0, ml: 2 }} LinkComponent={'a'} target="_blank" href={`https://web.whatsapp.com/send?phone=${patientData?.country_code??"+91"}${patientData?.phone}&text=${encodeURI(message)}`} >
                        <WhatsApp color="success" />
                    </IconButton> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
const HistoryView = ({ profiles, patientData }) => {
  const theme = useTheme();
  const [val, setVal] = useState(LISTENING_SIDE.LEFT);
  return (
    <>
      <Tabs
        sx={{ width: "100%", padding: 0, minHeight: "25px" }}
        onChange={(e, newVal) => {
          setVal(newVal);
        }}
        value={val}
      >
        <Tab
          value={LISTENING_SIDE.RIGHT}
          size="small"
          sx={{
            display: "flex",
            minHeight: "25px",
            padding: theme.spacing(1) + "!important",
            flex: 1,
            fontSize: theme.typography.h4.fontSize + " !important",
          }}
          label="Right"
        ></Tab>
        <Tab
          value={LISTENING_SIDE.LEFT}
          size="small"
          sx={{
            display: "flex",
            minHeight: "25px",
            padding: theme.spacing(1) + "!important",
            flex: 1,
            fontSize: theme.typography.h4.fontSize + " !important",
          }}
          label="Left"
        ></Tab>
      </Tabs>
      <Grid
        container
        p={2}
        sx={{
          background: theme.palette.secondary.light,
          borderTop: "1px solid" + theme.palette.primary.secondary,
          borderBottom: "1px solid" + theme.palette.primary.secondary,
        }}
      >
        <Grid item xs={5} pl={2}>
          <Typography variant="h6">Date and Time</Typography>
        </Grid>
        <Grid item xs={5} pl={2}>
          <Typography variant="h6">Device Name</Typography>
        </Grid>
      </Grid>
      <ContentContainer noPadding overflow="auto">
        {!profiles ||
        !Array.isArray(profiles) ||
        profiles.filter((item) => item.device_side === val).length == 0 ? (
          <CenteredBox>
            <NoDataComponent message="No Profile Found" Icon={Earbuds} />
          </CenteredBox>
        ) : (
          profiles
            .filter((item) => item.device_side === val)
            .map((item, index) => (
              <HistoryBox
                key={item._id}
                patientData={patientData}
                even={index % 2 === 0}
                {...item}
              />
            ))
        )}
      </ContentContainer>
    </>
  );
};

const PatientDetailedViewCommonUI = ({
  loading,
  data,
  onEdit,
  onNewFitting,
  title,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    dispatch(resetDevice());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const reassign = () => {
    dispatch(
      openModal(<ReassignComponent id={data._id} />, "sm", false, "reassign")
    );
  };

  return (
    <>
      <ModuleContainer
        HeaderComponent={() => {
          return (
            <Box>
              <DowanloadReportButton
                id={data._id}
                disabled={loading}
                name={data?.first_name}
              />
            </Box>
          );
        }}
      >
        {loading && (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        )}
        {!loading && (!data || !data["_id"]) && (
          <NoDataComponent message={title + " not found"} Icon={Person} />
        )}

        {!loading && data && data["_id"] && (
          <Box
            sx={(theme) => ({
              display: "flex",
              flex: 1,
              overflow: "hidden",
              borderRadius: 1,
            })}
            p={3}
          >
            <Grid container spacing={3} sx={{ overflow: "hidden" }}>
              <Grid item xs={3.25} sx={{ maxHeight: "100%" }}>
                <OuterContainer>
                  <TitleBox>
                    <Typography variant="h3" fontWeight={600}>
                      Information
                    </Typography>
                    <Box>
                      <QrCodeButton params={data} />
                      <IconButton onClick={onEdit} sx={{ padding: 0 }}>
                        <DriveFileRenameOutlineOutlined
                          fontSize="inherit"
                          color="primary"
                        />
                      </IconButton>
                    </Box>
                  </TitleBox>
                  <ContentContainer>
                    <Typography
                      fontWeight={500}
                      color="primary"
                      variant="h4"
                      mb={3}
                      mt={3}
                    >
                      Personal Details
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={400}
                          variant="h6"
                          color="grey"
                        >
                          Patient Id
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={500}
                          variant="h6"
                          color="primary"
                        >
                          {data?.patient_uid}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={400}
                          variant="h6"
                          color="grey"
                        >
                          Full Name
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={500}
                          variant="h6"
                          color="primary"
                        >
                          {data?.first_name +
                            " " +
                            data?.middle_name +
                            " " +
                            data?.last_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={400}
                          variant="h6"
                          color="grey"
                        >
                          Contact Number
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={500}
                          variant="h6"
                          color="primary"
                        >
                          {data?.country_code}&nbsp;
                          {data?.phone}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={400}
                          variant="h6"
                          color="grey"
                        >
                          Email
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={500}
                          variant="h6"
                          color="primary"
                        >
                          {data?.email ?? "NA"}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={400}
                          variant="h6"
                          color="grey"
                        >
                          Gender
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={500}
                          variant="h6"
                          color="primary"
                        >
                          {findObjectKeyByValue(data?.gender, GENDER)}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={400}
                          variant="h6"
                          color="grey"
                        >
                          Age
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={500}
                          variant="h6"
                          color="primary"
                        >
                          {calculateAge(data.dob)}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={400}
                          variant="h6"
                          color="grey"
                        >
                          AudioLogist
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={500}
                          variant="h6"
                          color="primary"
                        >
                          {data?.assigned?.name || (
                            <IconButton
                              sx={{ marginTop: "-12px", color: "red" }}
                              onClick={() => {
                                reassign();
                              }}
                            >
                              <ManageAccountsIcon />
                            </IconButton>
                          )}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={400}
                          variant="h6"
                          color="grey"
                        >
                          Address
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={500}
                          variant="h6"
                          color="primary"
                        >
                          {data?.address}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Typography
                      fontWeight={500}
                      color="primary"
                      variant="h4"
                      mb={3}
                      mt={3}
                    >
                      Hearing Details
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={400}
                          variant="h6"
                          color="grey"
                        >
                          Level Of Hearing Loss
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={500}
                          variant="h6"
                          color="primary"
                        >
                          {findObjectKeyByValue(
                            data?.level_of_loss,
                            LEVEL_OF_HEARING_LOSS
                          )}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={400}
                          variant="h6"
                          color="grey"
                        >
                          Type Of Hearing Loss
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={500}
                          variant="h6"
                          color="primary"
                        >
                          {TYPE_OF_HEARING_LOSS_NAMES[data?.type_of_loss]}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={400}
                          variant="h6"
                          color="grey"
                        >
                          Impairment Type
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={500}
                          variant="h6"
                          color="primary"
                        >
                          {findObjectKeyByValue(
                            data?.impairment_type,
                            IMPAIREMENT_TYPE
                          )}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Typography
                      fontWeight={500}
                      color="primary"
                      variant="h4"
                      mb={3}
                      mt={3}
                    >
                      Other Details
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={400}
                          variant="h6"
                          color="grey"
                        >
                          Created By
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          mb={1}
                          fontWeight={500}
                          variant="h6"
                          color="primary"
                        >
                          {data?.created_by?.name}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <DeletePatientButton
                          id={data?._id}
                          name={data?.first_name + " " + data?.last_name}
                        />
                      </Grid>
                    </Grid>
                  </ContentContainer>
                </OuterContainer>
              </Grid>

              <Grid item xs={5.5} sx={{ maxHeight: "100%" }}>
                <OuterContainer>
                  <TitleBox>
                    <Typography variant="h3" fontWeight={600}>
                      Audiogram
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <OpenAudioGramHistoryButton
                        fontSize="inherit"
                        color="primary"
                        patient_id={data?._id}
                      />
                      <Box mr={2} />
                      <IconButton
                        onClick={(e) => {
                          onEdit(e, true);
                        }}
                        sx={{ padding: 0 }}
                      >
                        <DriveFileRenameOutlineOutlined
                          fontSize="inherit"
                          color="primary"
                        />
                      </IconButton>
                    </Box>
                  </TitleBox>
                  <ContentContainer overflow="hidden">
                    <AudioGram
                      loading={false}
                      fields={data}
                      setFields={() => {}}
                      only_view={true}
                    />
                  </ContentContainer>
                </OuterContainer>
              </Grid>
              <Grid item xs={3.25} sx={{ maxHeight: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  <ProfileButton
                    p={0}
                    aria-describedby={id}
                    onClick={handleClick}
                  >
                    <Typography
                      color="primary"
                      textTransform="capitalize"
                      ml={2}
                      fontWeight={600}
                      variant="button"
                    >
                      New Fitting
                    </Typography>
                    <IconButton
                      sx={{ height: "32px", width: "32px" }}
                      disabled
                      color="primary"
                      ml={1}
                      fontWeight={600}
                      variant="button"
                    >
                      <KeyboardArrowDown color="primary" />
                    </IconButton>
                  </ProfileButton>

                  <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    width="100%"
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Box sx={{ width: "25.3vw" }}>
                      <MenuList>
                        <MenuItem>
                          <Button
                            onClick={onNewFitting}
                            variant="outlined"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Typography variant="h2" lineHeight="100%">
                              <HearingAidIcon />
                            </Typography>
                            &nbsp;
                            <Typography
                              fontWeight={500}
                              lineHeight="100%"
                              variant="h3"
                            >
                              In-Person Fitting
                            </Typography>
                          </Button>
                        </MenuItem>
                        <MenuItem>
                          <Button
                            onClick={() => {
                              dispatch(applyForRemoteFitting());
                              onNewFitting();
                            }}
                            variant="outlined"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Typography variant="h2" lineHeight="100%">
                              <SettingsRemoteIcon />
                            </Typography>
                            &nbsp;
                            <Typography
                              fontWeight={500}
                              lineHeight="100%"
                              variant="h3"
                            >
                              Remote Fitting
                            </Typography>
                          </Button>
                        </MenuItem>
                      </MenuList>
                    </Box>
                  </Popover>

                  <OuterContainer p={0} mt={3}>
                    <TitleBox>
                      <Typography variant="h3" fontWeight={600}>
                        History
                      </Typography>
                    </TitleBox>
                    <HistoryView profiles={data.profiles} patientData={data} />
                  </OuterContainer>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </ModuleContainer>
    </>
  );
};
export default PatientDetailedViewCommonUI;
