import { useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { downloadPatientReport, getPatientApi } from "../../apis/patient.api"
import SubmitButton from "../../components/button/SubmitButton"
import fileDownload from "js-file-download"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { openModal } from "../../store/actions/modalAction"
import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Typography } from "@mui/material"
import { CenteredBox } from "../../components/layouts/common/boxes"




const DowanloadReportButton = ({ id, name = "patient-report", disabled }) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()
    const fetchApi = downloadPatientReport

    const [filters, setFilters] = useState({
        id,
        ac: true,
        bc: undefined,
        aided: true

    })





    // do not change 
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const onSubmit = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                fileDownload(response, name + ".pdf")
                setLoading(false)
                setOpen(false)

            },
            (err) => {
                dispatch(callSnackBar("File cant get download"))
                setLoading(false)
            },
            true
        ))
    }
    const downloadReportFun = () => {
        setOpen(true)

    }
    return (
        <>
            {/* <Dialog fullWidth open={open} >
                <DialogTitle>
                    Download Report
                </DialogTitle>

                <DialogContent>
                    <CenteredBox sx={{ flexDirection: "column" }} >
                        <Typography variant="body1" >
                            Select Graph Categories
                        </Typography>

                        <Box sx={{ display: "flex" }} >
                            <FormGroup>
                                <FormControlLabel control={<Checkbox onChange={(e) => setFilters({ ...filters, ac: e.target.checked??null })} checked={filters.ac} />} label="AC" />
                                <FormControlLabel control={<Checkbox onChange={(e) => setFilters({ ...filters, bc: e.target.checked??null })} checked={filters.bc} />} label="BC" />
                                <FormControlLabel control={<Checkbox onChange={(e) => setFilters({ ...filters, aided: e.target.checked??null })} checked={filters.aided} />} label="Aided" />
                            </FormGroup>
                        </Box>
                    </CenteredBox>
                </DialogContent>
                <DialogActions>
                    <SubmitButton title="Cancel" variant="outlined" loading={loading} onClick={() => setOpen(false)} />
                    <SubmitButton title="Download" loading={loading} onClick={onSubmit} />
                </DialogActions>
            </Dialog> */}
            <SubmitButton disabled={disabled || loading} title="Download Report" onClick={onSubmit} loading={loading} ></SubmitButton>
        </>
    )
}
export default DowanloadReportButton