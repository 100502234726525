import { memo, useEffect, useMemo, useState } from "react";

import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { getUserByIdApi } from "../../apis/user.api";
import PatientDetailedViewCommonUI from "./PatientDetailedViewCommonUI";
import { getPatientByIdApi } from "../../apis/patient.api";
import { useNavigate, useParams } from "react-router-dom";

const PatientDetailedViewCommonController = ({ id, getFun }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const title = "Patient details";
  const modalKey = "patient-modal";
  const getByIdApi = getFun ?? getPatientByIdApi;

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({});

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getByIdApi({ id }),
        async (response) => {
          setData({ ...response });

          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const onEdit = (e, step) => {
    let route = "/patient-details/" + params.id + "/update";
    if (step) {
      route += "/audiogram";
    }
    navigate(route);
  };
  const onNewFitting = () => {
    navigate("/patient-details/" + params.id + "/new-fitting");
  };

  useEffect(() => {
    if (params.id) fetchById(params.id);
  }, [params.id]);

  return (
    <PatientDetailedViewCommonUI
      onNewFitting={onNewFitting}
      onEdit={onEdit}
      modalKey={modalKey}
      title={title}
      loading={loading}
      data={data}
    />
  );
};
export default memo(PatientDetailedViewCommonController);
