import axios from "axios";
import { getHeaders } from "../utils/helper";
import endpoints from "./endpoints";



export const getPatientProfileApi = async params => {
  const callResponse = await axios({
    url: endpoints.patientProfileBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getPatientProfileByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.patientProfileById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const addPatientProfileApi = async data => {
  const callResponse = await axios({
    url: endpoints.patientProfileBase,
    method: "POST",
    headers: {...getHeaders(),"Content-Type": "application/json"},
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updatePatientProfileField = async data => {
  const callResponse = await axios({
    url: endpoints.patientProfileBase,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};