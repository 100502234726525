import { Button } from "@mui/material"
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"
import InsuitAudiometryMain from "./AudioMetryMain"

const AudioMetryButton = ({patient_id})=>{
    const dispatch = useDispatch()
    const onClick = ()=>{
        dispatch(openModal(<InsuitAudiometryMain patient_id={patient_id} />,'xl',true,'audiometry'))
    }
    return <Button onClick={onClick} variant="contained" >
        In-Situ-Audiometry
    </Button>
}
export default AudioMetryButton