import { getAudiometryApi } from "../../apis/audiometry.api";
import { getPatientApi, getPatientByIdApi } from "../../apis/patient.api";
import { SNACK_BAR_VARIETNS, actions } from "../../utils/constants";
import { Calculation } from "../../utils/Fig6Calculation";
import { unMapValues } from "../../utils/helper";
import { calculateIGI } from "../../utils/j10.helper";
import { DEVICES, LISTENING_SIDE } from "../../utils/patient.constants";
import { callApiAction } from "./commonAction";
import { callSnackBar } from "./snackbarAction";

export const changeDeviceSideSelection = (value) => {
  return { type: actions.CHNAGE_DEVICE_SIDE_SELECTION, value };
};

export const changeDeviceSelection = (value) => {
  return { type: actions.CHANGE_DEVICE_SELECTION, value };
};
export const connectDevice = (hardwareData, deviceInfo, disconnectFun) => {
  return {
    type: actions.CONNECT_DEVICE,
    hardwareData,
    deviceInfo,
    disconnectFun,
  };
};
export const applyForRemoteFitting = () => {
  return {
    type: actions.REMOTE_FITTING,
    remote: true,
    deviceInfo: { name: "Remote", id: "remote" },
    disconnectFun: () => {},
  };
};
export const changeRemarks = (value) => {
  return { type: actions.CHNAGE_REMARKS, value };
};

export const changeLoadinfMessages = (loading, messages) => {
  return { type: actions.CHNAGE_LOADING_VALUES, loading, messages };
};
export const changeDeviceCompatiblity = (value) => {
  return { type: actions.CHNAGE_DEVICE_COMPATIBLITY, value };
};
export const onWriteFunctionChange = (value) => {
  return { type: actions.CHANGE_WRITE_FUN, value };
};
export const changeProfileId = (value) => {
  return { type: actions.CHANGE_PROFILE_ID, value };
};

export const resetDevice = () => {
  console.log("call for reset device");
  return { type: actions.RESET_DEVICE };
};

export const read_only = () => {
  return { type: actions.READ_ONLY_CHANGED };
};

export const disconnectDevice = () => {
  return async (dispatch, getState) => {
    dispatch(callSnackBar("Device Disconnected.", SNACK_BAR_VARIETNS.error));
    dispatch({ type: actions.DISCONNECT_DEVICE });
  };
};
export const resetToDefault = () => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.DISCONNECT_DEVICE });
  };
};
export const applyFeedbackCancellation = (val, isWhisleBlock) => {
  console.log("inside action");
  return {
    type: actions.APPLY_FEEDBACKCANCELATION,
    value: val,
    WhisleBlock: isWhisleBlock,
  };
};
export const applyWinNoiseBlock = (val) => {
  return { type: actions.APPLY_WINNOISEBLOCK, val };
};
export const changeByteValue = (byteIndex, val) => {
  return { type: actions.CHANGE_BYTE_VAL, byteIndex, val };
};
export const changeMultipleByteValue = (byteIndex = [], val = []) => {
  return { type: actions.CHANGE_MULTIPLE_BYTE_VAL, byteIndex, val };
};
export const changeDeviceArray = (val = "") => {
  return async (dispatch, getState) => {
    if (val.split(" ").length != 100) {
      dispatch(
        callSnackBar(
          "It must required 100 bit of data.",
          SNACK_BAR_VARIETNS.error
        )
      );
    } else {
      dispatch({ type: actions.CHANGE_BYTE_ARRAY_VAL, val: val.split(" ") });
    }
  };
  return;
};

export const changeMasterGain = (val) => {
  return { type: actions.CHANGE_MASTER_GAIN, val };
};
export const changeCompression = (val) => {
  return { type: actions.CHANGE_COMPRESSION, val };
};
export const changeWindNoiseReduction = (val) => {
  return { type: actions.CHANGE_WIND_NOISE_REDUCTION, val };
};
export const changeNoiseReduction = (val) => {
  return { type: actions.CHANGE_NOISE_REDUCTION, val };
};
export const changeSharpness = (val) => {
  return { type: actions.CHANGE_SHARPNESS, val };
};
export const changeSpeechRedcution = (val) => {
  return { type: actions.CHANGE_SPEECH_REDUCTION, val };
};
export const setAutofitData = (value) => {
  return { type: actions.SET_AUTO_FIT_DATA, value };
};
export const autofitDevice = (id, onSuccess = () => {}, onError = () => {}) => {
  return async (dispatch, getState) => {
    const deviceSide = getState().fitting?.device_side;
    const deviceType = getState().fitting?.device_type;

    dispatch(
      callApiAction(
        async () => await getPatientByIdApi({ id, no_profile: true }),
        async (response) => {
          if (response) {
            const deviceTypePrefix = deviceType == DEVICES.GLASSES ? "b" : "";

            const side = deviceSide == LISTENING_SIDE.LEFT ? "l" : "r";
            let linearGainForBand = [];
            const linearGainForBand0 = Calculation(
              response[deviceTypePrefix + side + "f1"]
            ); // calculating For audiogram freq 125
            linearGainForBand.push(linearGainForBand0);
            const linearGainForBand1 = Calculation(
              response[deviceTypePrefix + side + "f2"]
            ); // calculating For audiogram freq 250
            linearGainForBand.push(linearGainForBand1);
            const linearGainForBand2 = Calculation(
              response[deviceTypePrefix + side + "f3"]
            ); // calculating For audiogram freq 500
            linearGainForBand.push(linearGainForBand2);
            const linearGainForBand3 = Calculation(
              response[deviceTypePrefix + side + "f4"]
            ); // calculating For audiogram freq 1000
            linearGainForBand.push(linearGainForBand3);
            const linearGainForBand4 = Calculation(
              response[deviceTypePrefix + side + "f4"]
            ); // calculating For audiogram freq 1000
            linearGainForBand.push(linearGainForBand4);
            const linearGainForBand5 = Calculation(
              response[deviceTypePrefix + side + "f5"]
            ); // calculating For audiogram freq 2000
            linearGainForBand.push(linearGainForBand5);
            const linearGainForBand6 = Calculation(
              response[deviceTypePrefix + side + "f6"]
            ); // calculating For audiogram freq 4000
            linearGainForBand.push(linearGainForBand6);
            const linearGainForBand7 = Calculation(
              response[deviceTypePrefix + side + "f6"]
            ); // calculating For audiogram freq 4000
            linearGainForBand.push(linearGainForBand7);

            for (let i = 0; i <= 7; i++) {
              dispatch(
                changeByteValue(
                  20 + i,
                  Math.floor(linearGainForBand[i].ER * 10)
                )
              );
              dispatch(
                changeByteValue(28 + i, Math.floor(linearGainForBand[i].Eknee))
              );
              dispatch(
                changeByteValue(
                  36 + i,
                  Math.floor(linearGainForBand[i].exp_end_knee)
                )
              );
              dispatch(
                changeByteValue(44 + i, Math.floor(linearGainForBand[i].Cknee))
              );
              dispatch(
                changeByteValue(
                  52 + i,
                  Math.floor(linearGainForBand[i].CR * 10)
                )
              );
              dispatch(
                changeByteValue(60 + i, Math.floor(linearGainForBand[i].MPO))
              );
            }

            console.log("this is call for auto fit");

            // dispatch(
            //   setAutofitData([
            //     linearGainForBand0,
            //     linearGainForBand1,
            //     linearGainForBand2,
            //     linearGainForBand3,
            //     linearGainForBand4,
            //     linearGainForBand5,
            //     linearGainForBand6,
            //     linearGainForBand7,
            //   ])
            // );

            onSuccess();
          } else {
            onError("No Audiogram found");
          }
        },
        (err) => {
          onError(err);
        }
      )
    );
  };
};

export const insituAutofitDevice = (
  id,
  onSuccess = () => {},
  onError = () => {}
) => {
  return async (dispatch, getState) => {
    const deviceSide = getState().fitting?.device_side;

    dispatch(
      callApiAction(
        async () => await getAudiometryApi({ patient_uid: id }),
        async (response) => {
          if (response) {
            const deviceTypePrefix = "insitu_";

            const side = deviceSide == LISTENING_SIDE.LEFT ? "l" : "r";

            const linearGainForBand0 = calculateIGI(
              response[deviceTypePrefix + side + "f1"]
            ); // calculating For audiogram freq 125
            const linearGainForBand1 = calculateIGI(
              response[deviceTypePrefix + side + "f2"]
            ); // calculating For audiogram freq 250
            const linearGainForBand2 = calculateIGI(
              response[deviceTypePrefix + side + "f3"]
            ); // calculating For audiogram freq 500
            const linearGainForBand3 = calculateIGI(
              response[deviceTypePrefix + side + "f4"]
            ); // calculating For audiogram freq 1000
            const linearGainForBand4 = calculateIGI(
              response[deviceTypePrefix + side + "f4"]
            ); // calculating For audiogram freq 1000
            const linearGainForBand5 = calculateIGI(
              response[deviceTypePrefix + side + "f5"]
            ); // calculating For audiogram freq 2000
            const linearGainForBand6 = calculateIGI(
              response[deviceTypePrefix + side + "f6"]
            ); // calculating For audiogram freq 4000
            const linearGainForBand7 = calculateIGI(
              response[deviceTypePrefix + side + "f6"]
            ); // calculating For audiogram freq 4000

            dispatch(
              setAutofitData([
                linearGainForBand0,
                linearGainForBand1,
                linearGainForBand2,
                linearGainForBand3,
                linearGainForBand4,
                linearGainForBand5,
                linearGainForBand6,
                linearGainForBand7,
              ])
            );

            onSuccess();
          } else {
            onError("No Audiogram found");
          }
        },
        (err) => {
          onError(err);
        }
      )
    );
  };
};

export const readDataFromDevice = (data) => {
  return async (dispatch, getState) => {
    dispatch(
      callSnackBar("Device data read and set.", SNACK_BAR_VARIETNS.suceess)
    );
    dispatch({ type: actions.READ_FROM_DEVICE, data });
  };
};

//reading data from id
export const changeFittingData = (data, read_only) => {
  return { type: actions.CHANGE_FITTING_DATA, data, read_only };
};

export const Mark_value = (index, value) => {
  return { type: actions.CHANGE_MARK_VALUE, index, value };
};
