import { PersonAddOutlined, Search } from "@mui/icons-material";
import { Autocomplete, Button, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SubmitButton from "../../components/button/SubmitButton";
import {
  StyledSearchBar,
  UserSearchBarNormal,
} from "../../components/inputs/SearchBar";

import { LEVEL_OF_HEARING_LOSS } from "../../utils/patient.constants";
import DataTable from "../../components/tables/DataTable";
import { titleCase, toTitleCase } from "../../utils/helper";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop";
import AsynSearchBar from "../../components/inputs/AsynSearchBar";
import { CenteredBox } from "../../components/layouts/common/boxes";
import ModuleContainer from "../../components/layouts/common/ModuleContainer";
import Audiometrycreateui from "../inSituAudiometry/Audiometrycreateui";
import { USER_ROLES } from "../../utils/constants";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const HeaderComponent = memo(({ filters, setFilters, onCreateBtnClick }) => {
  const user = useSelector((state) => state.user);

  return (
    <Box sx={{ display: "flex", width: "70%" }}>
      <Box sx={{ display: "flex", flex: 1 }} mr={3}>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            overflow: "hidden",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box sx={{ width: "250px", maxWidth: "100%" }}>
              {user.data.role === USER_ROLES.ADMIN && (
                <Autocomplete
                  fullWidth
                  disableClearable
                  id="combo-box-demo"
                  onChange={(e, newVal) => {
                    setFilters({
                      ...filters,
                      level_of_hearing_loss: newVal ? newVal._id : null,
                    });
                  }}
                  options={[
                    { label: "All", _id: null },
                    ...Object.keys(LEVEL_OF_HEARING_LOSS).map((key) => ({
                      label: titleCase(key),
                      _id: LEVEL_OF_HEARING_LOSS[key],
                    })),
                  ]}
                  sx={{
                    width: "100%",
                    display: "flex",
                    "*": { display: "flex", justifyContent: "center" },
                  }}
                  renderInput={(params) => (
                    <StyledSearchBar
                      placeholder="Select Hearing Loss Level"
                      {...params}
                      size="small"
                    />
                  )}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "flex", flex: 1 }}>
        <AsynSearchBar
          fullWidth
          title="Search  Patient  "
          size="small"
          InputProps={{
            startAdornment: <Search sx={{ mr: 2 }} />,
          }}
          placeholder={"Search By Patient / Created By "}
          defaultValue={filters.search}
          onChange={(changedVal) => {
            setFilters({ ...filters, search: changedVal });
          }}
        />
      </Box>

      {/*  */}

      {/* <Box sx={{ display: "flex", flex: 1 }} ml={3}>
      <AsynSearchBar
        fullWidth
        title="Search  Created By  "
        size="small"
        InputProps={{
          startAdornment: <Search sx={{ mr: 2 }} />,
        }}
        placeholder={"Search Created By "}
        defaultValue={filters.search}
        onChange={(changedVal) => {
          setFilters({ ...filters, search: changedVal });
        }}
      />
    </Box> */}

      {/*  */}

      {/*  */}

      <Box sx={{ display: "flex" }} ml={3}>
        <Button
          onClick={onCreateBtnClick}
          variant="outlined"
          pb={0}
          sx={{ pb: 0 }}
          size="small"
        >
          <Typography variant="h4" mr={2} lineHeight="100%" fontWeight={500}>
            <PersonAddOutlined />
          </Typography>
          <Typography variant="h4" lineHeight="100%" fontWeight={500}>
            New Patient
          </Typography>
        </Button>
      </Box>
    </Box>
  );
});

const ListUi = ({
  title,
  filters,
  setFilters,
  list,
  roles,
  loading,
  onCreateBtnClick,
  columns,
}) => {
  const user = useSelector((state) => state.user);

  return (
    <ModuleContainer
      headrComponentProps={{ filters, setFilters, onCreateBtnClick }}
      HeaderComponent={HeaderComponent}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          overflow: "hidden",
          flexDirection: "column",
        }}
      >
        <DataTable
          customRows={true}
          columns={columns}
          rows={list.result ? list.result : []}
          count={list.total ?? 0}
          filters={filters}
          setFilters={setFilters}
          loading={loading}
        />
      </Box>
    </ModuleContainer>
  );
};
export default ListUi;
