import { memo,  useMemo, useState } from "react"
import useValidate from "../../../store/hooks/useValidator"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import {  changeUserParentApi } from "../../../apis/user.api"
import { closeModal } from "../../../store/actions/modalAction"

import { USER_ROLES } from "../../../utils/constants"

import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants"
import AsyncDropDown from "../../../components/inputs/AsyncDropDown"
import CustomInput from "../../../components/inputs/CustomInput"
import { getUserApi } from "../../../apis/user.api"
import { Box, Typography } from "@mui/material"

const ChangeParentFirmController = ({ callBack = () => { }, id }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    
    const modalKey = 'change-form'
    const updateApi = changeUserParentApi
    

    const [loading, setLoading] = useState(false)
 
    const [fields, setFields] = useState({
        err: '',
        id,
        parent_id: null,
        

    })






   


    const updateFunction = async () => {
        
            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi(fields),
                    async (response) => {

                        await callBack(response, fields)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                        dispatch(callSnackBar("Parent updated successfully",SNACK_BAR_VARIETNS.suceess))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                        dispatch(callSnackBar(err,SNACK_BAR_VARIETNS.error))
                    }
                )
            )
        
    }



    const onSubmit = async (e) => {

        e.preventDefault()

        updateFunction()

    }





    return <CustomDialog
        id={modalKey}
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`Change Parent`}
        closeText="Close"
        confirmText={`Change`}
    >
<AsyncDropDown
                        InputComponent={(props) => <CustomInput label placeholder="Select Parent Firm/Company"  {...props} />}
                        lazyFun={async (props) => {
                            return await getUserApi({ ...props, role: USER_ROLES.AUDIO_LOGIST,user_not_allowed:id })
                        }}
                        OptionComponent={({ option, ...rest }) => {
                            return <Box sx={{ width: "100%", display: "flex", alignItems: "center" }} {...rest}>
                                <Typography ml={3} variant="h5">{option.name}</Typography>
                            </Box>
                        }}
                        onChange={async (changedVal) => { setFields({ ...fields, err: '', parent_id: changedVal?._id }) }}
                        titleKey={'name'}
                        valueKey={"_id"}


                    />
    </CustomDialog>
}
export default memo(ChangeParentFirmController)